import React, { useEffect, useState } from 'react';
import { Box, Typography, styled, RadioGroup, FormControlLabel, FormControl, FormLabel, Paper, Grid } from '@mui/material';

import { useNavigate, useParams } from 'react-router-dom';
import Colors from '../../../../theme/colors/Color';
import { OutlineButton, CustomRadioBtn, Heading26Bold, InputTextField, Combobox, FilledButton } from '../../../../utils/component';
import { getData, postData, updateData } from '../../../../services/APIService';
import { useDispatch } from 'react-redux';
import { showSnackbar } from '../../../../redux/features/snackbar/snackbarSlice';

const CustomPaper = styled(Paper)(() => ({
  padding: '28px 9px',
}));

const FieldBox = styled(Box)(() => ({
  height: '10vh',
  marginBottom: '2.5px',
}));

const vendorTypeOptions = [
  { value: 'Warehouse', label: 'Warehouse' },
  { value: 'Logistics', label: 'Logistics' },
];

const AddVendor = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const userData = JSON.parse(localStorage.getItem('userDetails'));
  const [isUpdateForm, setIsUpdateForm] = useState(false);
  const [vendorDetails, setVendorDetails] = useState({
    user_code: null,
    type: 'VENDOR',
    sub_type: '',
    name: '',
    gst_no: '',
    address: {
      address_line1: '',
      address_line2: '',
      city: '',
      state: '',
      postal_code: '',
      country: '',
    },
    status: '',
    email: '',
    password: '',
    mobile_number: '',
    is_verified: true,
  });
  const [errors, setErrors] = useState({
    sub_type: '',
    name: '',
    mobile_number: '',
    address: '',
    city: '',
    email: '',
    user_code: '',
    status: '',
    state: '',
    country: '',
    postal_code: '',
  });
  const validateMobileNumber = (mobileNumber) => {
    const mobileNumberRegex = /^\d{10}$/; // Regular expression for exactly 10 digits
    return mobileNumberRegex.test(mobileNumber);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email validation regex
    return emailRegex.test(email);
  };
  const handleMobileNumberChange = (e) => {
    const { value } = e.target;

    // Allow only digits and restrict to a maximum of 10 digits
    if (value === '' || (/^\d+$/.test(value) && value.length <= 10)) {
      setVendorDetails((prev) => ({ ...prev, mobile_number: value }));

      // Validate the mobile number and set errors accordingly
      if (!validateMobileNumber(value)) {
        setErrors((prevErrors) => ({ ...prevErrors, mobile_number: 'Mobile number must be exactly 10 digits' }));
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, mobile_number: '' }));
      }
    }
  };

  const handleEmailChange = (e) => {
    const { value } = e.target;
    setVendorDetails((prev) => ({ ...prev, email: value }));

    if (!validateEmail(value)) {
      setErrors((prevErrors) => ({ ...prevErrors, email: 'Please enter a valid email address' }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, email: '' }));
    }
  };
  const fetchUserById = async (id) => {
    try {
      const res = await getData(`/users/${id}`, {}, userData.token);
      setVendorDetails(res.data.data);
    } catch (error) {
      dispatch(showSnackbar({ message: error.message, severity: 'error' }));
    }
  };
  useEffect(() => {
    if (id) {
      fetchUserById(id);
      setIsUpdateForm(true);
    }
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!vendorDetails.sub_type) {
      setErrors((prevErrors) => ({ ...prevErrors, sub_type: 'Please select at least 1 vendor type' }));
      return;
    }
    if (!vendorDetails.name) {
      setErrors((prevErrors) => ({ ...prevErrors, name: 'Vendor Name is required' }));
      return;
    }
    if (!vendorDetails.address?.address_line1) {
      setErrors((prevErrors) => ({ ...prevErrors, address: 'Address 1 is required' }));
      return;
    }
    if (!vendorDetails.address?.city) {
      setErrors((prevErrors) => ({ ...prevErrors, city: 'City is required' }));
      return;
    }
    if (!vendorDetails.address?.postal_code) {
      setErrors((prevErrors) => ({ ...prevErrors, postal_code: 'Pin Code is required' }));
      return;
    }
    if (!vendorDetails.user_code) {
      setErrors((prevErrors) => ({ ...prevErrors, user_code: 'Vendor Code is required' }));
      return;
    }
    if (!vendorDetails.gst_no) {
      setErrors((prevErrors) => ({ ...prevErrors, gst_no: 'GST Number is required' }));
      return;
    }
    const gstRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}[Z]{1}[0-9A-Z]{1}$/;
    if (!gstRegex.test(vendorDetails.gst_no)) {
      setErrors((prevError) => ({ ...prevError, gst_no: 'Please enter a valid GST Number' }));
    }
    if (!vendorDetails.address?.state) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        state: 'Please enter the state',
      }));
      return;
    }
    if (!vendorDetails.address?.country) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        country: 'Please enter the country',
      }));
      return;
    }
    if (!vendorDetails.status) {
      setErrors((prevErrors) => ({ ...prevErrors, status: 'Please select at least one status option' }));
      return;
    }
    if (!validateEmail(vendorDetails.email)) {
      setErrors((prevErrors) => ({ ...prevErrors, email: 'Please enter a valid email address' }));
      return;
    }
    if (!vendorDetails.password) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password: 'Please enter the password',
      }));
      return;
    }

    // Check if mobile number and email are valid
    if (!validateMobileNumber(vendorDetails.mobile_number)) {
      setErrors((prevErrors) => ({ ...prevErrors, mobile_number: 'Mobile number must be exactly 10 digits' }));
      return;
    }

    try {
      const res = await postData('/auth/register/vendor', vendorDetails, {}, userData.token);
      if (res.data.status) {
        dispatch(showSnackbar({ message: 'New Vendor Register successfully', severity: 'success' }));
        navigate('/vendor');
      }
    } catch (error) {
      dispatch(showSnackbar({ message: error.message, severity: 'error' }));
      if (error.response && error.response.data) dispatch(showSnackbar({ message: error.response.data.message, severity: 'error' }));
      else dispatch(showSnackbar({ message: 'Something went wrong...!', severity: 'error' }));
    }
  };

  const handleUpdate = async () => {
    try {
      const res = await updateData(`/users/edit/${id}`, vendorDetails, {}, userData.token);
      if (res.data.status) {
        dispatch(showSnackbar({ message: 'Vendor Updated successfully', severity: 'success' }));
        navigate('/vendor');
      }
    } catch (error) {
      dispatch(showSnackbar({ message: error.message, severity: 'error' }));
    }
  };

  return (
    <Box>
      <Heading26Bold text={isUpdateForm ? 'Edit New Vendor' : 'Add New Vendor'} />
      <Grid container spacing={3} marginTop={'5px'}>
        <Grid item xs={12} lgm={8}>
          <CustomPaper elevation={5}>
            <Grid container gap={{ xs: 2, md: 0 }}>
              <Grid item xs={12} md={6} paddingRight={{ xs: 0, md: '5px' }}>
                <FieldBox>
                  <Combobox
                    label={'Vendor Type'}
                    optionsLabel={'label'}
                    options={vendorTypeOptions}
                    isDisable={false}
                    isRequire={false}
                    value={vendorDetails.sub_type}
                    onChangeValue={(name) => {
                      setVendorDetails((prev) => ({ ...prev, sub_type: name?.label }));
                      setErrors((prev) => ({ ...prev, sub_type: '' })); // Clear error on change
                    }}
                  />
                  {errors.sub_type && (
                    <Typography color="error" variant="caption">
                      {errors.sub_type}
                    </Typography>
                  )}
                </FieldBox>
                <FieldBox>
                  <InputTextField
                    label={'Vendor Name(Name Of The Firm)'}
                    name={'vendor-name'}
                    placeholder={'Enter Name'}
                    isDisable={false}
                    isRequire={true}
                    value={vendorDetails.name}
                    onChange={(e) => {
                      const newName = e.target.value;
                      setVendorDetails((prev) => ({ ...prev, name: newName }));
                      if (newName) {
                        setErrors((prevErrors) => ({ ...prevErrors, name: undefined }));
                      }
                    }}
                  />
                  {errors.name && (
                    <Typography color="error" variant="caption">
                      {errors.name}
                    </Typography>
                  )}
                </FieldBox>
                <FieldBox>
                  <InputTextField
                    label={'Address 1'}
                    name={'address_line1'}
                    placeholder={'Gandhi road'}
                    value={vendorDetails.address?.address_line1}
                    onChange={(e) => {
                      const newAddress = e.target.value;
                      setVendorDetails((prev) => ({
                        ...prev,
                        address: { ...prev.address, address_line1: newAddress },
                      }));
                      if (newAddress) {
                        setErrors((prevErrors) => ({ ...prevErrors, address: undefined }));
                      }
                    }}
                  />
                  {errors.address && (
                    <Typography color="error" variant="caption">
                      {errors.address}
                    </Typography>
                  )}
                </FieldBox>
                <FieldBox>
                  <InputTextField
                    label={'City'}
                    name={'city'}
                    placeholder={'Pune'}
                    value={vendorDetails.address?.city}
                    onChange={(e) => {
                      const newCity = e.target.value;
                      setVendorDetails((prev) => ({
                        ...prev,
                        address: { ...prev.address, city: newCity },
                      }));

                      if (newCity) {
                        setErrors((prevErrors) => ({ ...prevErrors, city: undefined }));
                      }
                    }}
                  />
                  {errors.city && (
                    <Typography color="error" variant="caption">
                      {errors.city}
                    </Typography>
                  )}
                </FieldBox>
                <FieldBox>
                  <InputTextField
                    label={'Pin Code'}
                    name={'postal_code'}
                    placeholder={'412305'}
                    type="number"
                    max={6}
                    value={vendorDetails.address?.postal_code}
                    onChange={(e) => {
                      const newPostalCode = e.target.value;
                      if (!isNaN(newPostalCode) && newPostalCode.length <= 6) {
                        setVendorDetails((prev) => ({
                          ...prev,
                          address: { ...prev.address, postal_code: newPostalCode },
                        }));
                      }

                      // Remove error message if postal code is valid
                      if (newPostalCode) {
                        setErrors((prevErrors) => ({ ...prevErrors, postal_code: undefined }));
                      }
                    }}
                  />
                  {errors.postal_code && (
                    <Typography color="error" variant="caption">
                      {errors.postal_code}
                    </Typography>
                  )}
                </FieldBox>
              </Grid>
              <Grid item xs={12} md={6} paddingLeft={{ xs: 0, md: '5px' }}>
                <FieldBox>
                  <InputTextField
                    label={'Vendor Code'}
                    name={'vendor_code'}
                    value={vendorDetails.user_code}
                    onChange={(e) => {
                      const newVendorCode = e.target.value;
                      setVendorDetails((prev) => ({
                        ...prev,
                        user_code: newVendorCode,
                      }));

                      // Remove error message if vendor code is valid
                      if (newVendorCode) {
                        setErrors((prevErrors) => ({ ...prevErrors, user_code: undefined }));
                      }
                    }}
                  />
                  {errors.user_code && (
                    <Typography color="error" variant="caption">
                      {errors.user_code}
                    </Typography>
                  )}
                </FieldBox>
                <FieldBox>
                  <InputTextField
                    label={'GST.No.'}
                    name={'gst_no'}
                    placeholder={'27XXXXXXXXX1Z5'}
                    value={vendorDetails.gst_no}
                    onChange={(e) => {
                      const newGst = e.target.value;
                      setVendorDetails((prev) => ({ ...prev, gst_no: e.target.value }));
                      if (newGst) {
                        setErrors((prevErrors) => ({ ...prevErrors, gst_no: undefined }));
                      }
                    }}
                  />
                  {errors.gst_no && (
                    <Typography color="error" variant="caption">
                      {errors.gst_no}
                    </Typography>
                  )}
                </FieldBox>
                <FieldBox>
                  <InputTextField
                    label={'Address 2'}
                    name={'address_line2'}
                    placeholder={'Baner'}
                    value={vendorDetails.address?.address_line2}
                    onChange={(e) =>
                      setVendorDetails((prev) => ({
                        ...prev,
                        address: { ...prev.address, address_line2: e.target.value },
                      }))
                    }
                  />
                </FieldBox>
                <FieldBox>
                  <InputTextField
                    label={'State'}
                    name={'state'}
                    placeholder={'Maharashtra'}
                    value={vendorDetails.address?.state}
                    onChange={(e) => {
                      const newState = e.target.value;
                      setVendorDetails((prev) => ({
                        ...prev,
                        address: { ...prev.address, state: newState },
                      }));

                      // Clear the error message when State is updated
                      if (newState) {
                        setErrors((prevErrors) => ({ ...prevErrors, state: undefined }));
                      }
                    }}
                  />
                  {errors.state && (
                    <Typography color="error" variant="caption">
                      {errors.state}
                    </Typography>
                  )}
                </FieldBox>
                <FieldBox>
                  <InputTextField
                    label={'Country'}
                    name={'country'}
                    placeholder={'India'}
                    value={vendorDetails.address?.country}
                    onChange={(e) => {
                      const newCountry = e.target.value;
                      setVendorDetails((prev) => ({
                        ...prev,
                        address: { ...prev.address, country: newCountry },
                      }));

                      if (newCountry) {
                        setErrors((prevErrors) => ({ ...prevErrors, country: undefined }));
                      }
                    }}
                  />
                  {errors.country && (
                    <Typography color="error" variant="caption">
                      {errors.country}
                    </Typography>
                  )}
                </FieldBox>
              </Grid>
            </Grid>
          </CustomPaper>
        </Grid>
        <Grid item xs={12} lgm={4}>
          <CustomPaper elevation={5}>
            <Box height={'51.8vh'}>
              <FieldBox color={Colors.text_413e3e}>
                <FormControl>
                  <FormLabel>
                    <Typography sx={{ fontSize: '16px', fontFamily: 'poppins-regular', color: Colors.text_413e3e, marginTop: '-5px' }}>
                      Status
                    </Typography>
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={vendorDetails.status}
                    onChange={(e) => {
                      const newStatus = e.target.value;
                      setVendorDetails((prev) => ({ ...prev, status: newStatus }));

                      if (newStatus) {
                        setErrors((prevErrors) => ({ ...prevErrors, status: undefined }));
                      }
                    }}
                  >
                    <FormControlLabel
                      value="Active"
                      control={<CustomRadioBtn />}
                      label="Active"
                      sx={{
                        '& .MuiFormControlLabel-label': {
                          fontFamily: 'poppins-regular',
                        },
                      }}
                    />
                    <FormControlLabel
                      value="Inactive"
                      control={<CustomRadioBtn />}
                      label="Inactive"
                      sx={{
                        '& .MuiFormControlLabel-label': {
                          fontFamily: 'poppins-regular',
                        },
                      }}
                    />
                  </RadioGroup>
                  {errors.status && (
                    <Typography color="error" variant="caption">
                      {errors.status}
                    </Typography>
                  )}
                </FormControl>
              </FieldBox>
              <FieldBox>
                <InputTextField
                  label={'Email ID (Username)'}
                  name={'email'}
                  placeholder={'example@gmail.com'}
                  value={vendorDetails.email}
                  onChange={handleEmailChange}
                />
                {errors.email && <Typography color="error">{errors.email}</Typography>}
              </FieldBox>
              <FieldBox>
                <InputTextField
                  label={'Password'}
                  name={'password'}
                  placeholder={'*********'}
                  type="password"
                  value={vendorDetails.password}
                  onChange={(e) => {
                    const newPassword = e.target.value;
                    setVendorDetails((prev) => ({
                      ...prev,
                      password: newPassword,
                    }));

                    if (newPassword) {
                      setErrors((prevErrors) => ({ ...prevErrors, password: undefined }));
                    }
                  }}
                />
                {errors.password && (
                  <Typography color="error" variant="caption">
                    {errors.password}
                  </Typography>
                )}
              </FieldBox>
              <FieldBox>
                <InputTextField
                  label={'Mobile No.'}
                  name={'mobile_number'}
                  placeholder={'9876789754'}
                  // type="number"
                  value={vendorDetails.mobile_number}
                  onChange={(e) => {
                    const { value } = e.target;
                    if (!isNaN(value) && value.length <= 10) {
                      handleMobileNumberChange(e);
                    }
                  }}
                />
                {errors.mobile_number && <Typography color="error">{errors.mobile_number}</Typography>}
              </FieldBox>
            </Box>
          </CustomPaper>
        </Grid>
      </Grid>
      <Box
        width={{ xs: '65vw', sm: '30vw' }}
        sx={{
          margin: '0px auto',
          marginTop: '30px',
          display: 'flex',
          gap: '25px',
        }}
      >
        <OutlineButton text={'Cancel'} onClick={() => navigate('/vendor')} />
        {id ? (
          <FilledButton text={'Update'} onClick={handleUpdate} sx={{ color: 'blue', borderColor: 'blue' }} />
        ) : (
          <FilledButton text={'Submit'} onClick={handleSubmit} sx={{ color: 'blue', borderColor: 'blue' }} />
        )}
      </Box>
    </Box>
  );
};

export default AddVendor;
