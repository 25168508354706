import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getData } from '../../../../services/APIService';
import Pagination from '../../../../utils/component/pagination/Pagination';
import { Box, InputAdornment, styled, TextField, Typography } from '@mui/material';
import Colors from '../../../../theme/colors/Color';
import { Magnifier } from '../../../../theme/Images';
const headerCellStyle = {
  border: '1px solid #ddd',
  color: '#fff',
  backgroundColor: '#0093D3',
  fontFamily: 'poppins-regular',
  textTransform: 'capitalize',
};
const headerCellStyle2 = {
  border: '1px solid #ddd',
  fontFamily: 'poppins-regular',
};
const limit = 50;
let totalPages = 1;
const CustomSearch = styled(TextField)(() => ({
  '& .MuiOutlinedInput-root': {
    '&.MuiInputBase-root': {
      height: '40px',
      borderRadius: '10px',
      width: '31.8vw',
      background: Colors.white,
    },
  },
}));
const formatDate = (dateString) => {
  if (dateString) {
    const date = new Date(dateString);

    const formattedDate = date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    });

    const formattedTime = date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });

    return `${formattedDate}, ${formattedTime}`;
  }
  return '';
};

const FinacialDetails = () => {
  const userData = JSON.parse(localStorage.getItem('userDetails'));
  const [finacialList, setFinacialList] = useState([]);
  const [page, setCurrentPage] = useState(1);
  const [keyword, setKeyword] = useState('');
  const fetchFinancials = async () => {
    await getData(`/tally/tally_financials?page=${page}&limit=${limit}&keyword=${keyword}`, {}, userData.token)
      .then((res) => {
        if (res.data.data && res.data.data?.result) {
          setFinacialList(res.data.data?.result || []);
          totalPages = res.data.data?.totalPages || 1;
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchFinancials();
  }, [page, keyword]);
  const handleSearch = (e) => {
    const keyword = e.target.value.trim();
    setKeyword(keyword);
    setCurrentPage(1);
  };

  return (
    <>
      <Box
        sx={{
          marginTop: '20px',
          height: '10.4vh',
          borderRadius: '10px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: ' center',
          padding: '0px 30px',
          background: Colors.bg_strip,
        }}
      >
        <CustomSearch
          placeholder="Search Tally Financial Details"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img src={Magnifier} alt="Search" style={{ width: '20px', height: '20px' }} />
              </InputAdornment>
            ),
          }}
          onChange={handleSearch}
        />
        {finacialList && finacialList.length > 0 && (
          <Typography>
            Last Updated :{formatDate(finacialList[finacialList.lenght - 1]?.updatedAt ?? finacialList[finacialList.length - 1]?.createdAt)}
          </Typography>
        )}
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650, border: '1px solid #ddd' }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{ backgroundColor: '#0093D3' }}>
              <TableCell sx={headerCellStyle}>Customer code</TableCell>
              <TableCell sx={headerCellStyle}>Customer GST no.</TableCell>
              <TableCell sx={headerCellStyle}>Customer Name</TableCell>
              <TableCell sx={headerCellStyle}>Credit limit</TableCell>
              <TableCell sx={headerCellStyle}>Credit terms</TableCell>
              <TableCell sx={headerCellStyle}>Transaction date</TableCell>
              <TableCell sx={headerCellStyle}>Total amount deposit/credit on that date</TableCell>
              <TableCell sx={headerCellStyle}>Total sales on that date</TableCell>
              <TableCell sx={headerCellStyle}>Total amount returned/debit on that date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {finacialList.map((row, index) => (
              <TableRow key={index}>
                <TableCell sx={headerCellStyle2} component="th" scope="row">
                  {row.customer_code}
                </TableCell>
                <TableCell sx={headerCellStyle2}>{row.cust_gstno}</TableCell>
                <TableCell sx={headerCellStyle2}>{row.cust_name}</TableCell>
                <TableCell sx={headerCellStyle2}>{row.credit_limit}</TableCell>
                <TableCell sx={headerCellStyle2}>{row.credit_terms}</TableCell>
                <TableCell sx={headerCellStyle2}>{formatDate(row.transaction_date)}</TableCell>
                <TableCell sx={headerCellStyle2}>{row.deposit}</TableCell>
                <TableCell sx={headerCellStyle2}>{row.sales}</TableCell>
                <TableCell sx={headerCellStyle2}>{row.total_returns}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination currentPage={page} totalPages={totalPages} onPageChange={(p) => setCurrentPage(p)} />
    </>
  );
};

export default FinacialDetails;
