import React, { useState, useEffect } from 'react';
import { Box, Popover, TextField, Typography, Modal, styled, InputAdornment, Button } from '@mui/material';
import Colors from '../../../theme/colors/Color';
import { Heading26Bold } from '../../../common-components';
import PropTypes from 'prop-types';
import { Delete, Filter, exportIcon, Edit, Magnifier } from '../../../theme/Images';
import { useNavigate } from 'react-router-dom';
// import TableHeader from '../../../utils/table headings/TableHeaders.json';
// import ListDataTable from '../../../common-components/table/ListDataTable';
import OrderFilter from './filters/OrderFilter';
import { CustomDataGrid, Heading16Reg } from '../../../utils/component';
import { getorderStatusBgColor } from '../../../utils/constants/orderStatusColor';
import { postData, postDataExport } from '../../../services/APIService';
import { useDispatch } from 'react-redux';
import { showSnackbar } from '../../../redux/features/snackbar/snackbarSlice';
import Pagination from '../../../utils/component/pagination/Pagination';
import { clearIds } from '../../../redux/features/datagrid/datagridSlice';

const limit = 10;
let totalPages = 1;
const userData = JSON.parse(localStorage.getItem('userDetails'));

const CustomSearch = styled(TextField)(() => ({
  '& .MuiOutlinedInput-root': {
    '&.MuiInputBase-root': {
      height: '40px',
      borderRadius: '10px',
      width: '31.8vw',
      background: Colors.white,
    },
  },
}));
const DeleteModalstyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '20vw',
  bgcolor: 'background.paper',
  boxShadow: 40,
  p: 4,
};
const CustomBtnBox = styled(Box)(() => ({
  // border: '1px solid',
  height: '35px',
  width: '35px',
  borderRadius: '6px',
  padding: '8px 6px 5px',
  background: Colors.white,
  textAlign: 'center',
  cursor: 'pointer',
}));
const initialFilters = {
  fulfilment_type: [],
  credit_term_id: [],
  payment_option_id: [],
  status: [],
  delivery_location: [],
  order_date: [],
  payment_entry_date: [],
  order_acceptance_date: [],
};
const OrderManagement = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [allOrders, setAllOrders] = useState([]);
  const [page, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState(initialFilters);
  const [keyword, setKeyword] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);

  const [deleteWarning, setDeleteWarning] = useState(false);
  // const [modalOpen, setModalOpen] = useState(false);

  const [isFilterActive, setIsFilterActive] = useState(false);

  const handleClickFilter = (event) => {
    setAnchorEl(event.currentTarget);
    setIsFilterActive(true);
  };

  const handleCloseFilter = () => {
    setAnchorEl(null);
    setIsFilterActive(false);
  };
  const handleClearFilter = () => {
    setFilters(initialFilters);
    fetchOrders();
  };
  useEffect(() => {
    fetchOrders();
  }, [page, filters, keyword]);
  useEffect(() => {
    dispatch(clearIds());
  }, []);
  const fetchOrders = async () => {
    try {
      const res = await postData(`/order/list`, { page, limit, filters, keyword }, {}, userData.token);
      if (res && res.data && res.data.status) {
        totalPages = res.data.data.totalPages;
        setAllOrders(res.data.data.orders);
      } else {
        totalPages = 1;
        setAllOrders([]);
      }
    } catch (error) {
      totalPages = 1;
      setAllOrders([]);
      console.log(error);
    }
  };
  const open = Boolean(anchorEl);
  const columnsData = [
    {
      field: 'order_number',
      headerName: 'Order No',
      flex: 0.9,
      renderCell: (params) => {
        const { order_id } = params.row;
        const viewDetails = () => {
          navigate('/order/details', { state: { id: order_id, mode: 'view' } });
        };
        return (
          <Box
            sx={{
              color: Colors.primary,
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            onClick={viewDetails}
          >
            {params.value}
          </Box>
        );
      },
    },
    {
      field: 'order_user',
      headerName: 'Customer Name',
      flex: 0.75,
      valueGetter: (params) => (params ? params.name : ''),
    },
    { field: 'createdAt', headerName: 'Date of Order', flex: 0.7, valueGetter: (p) => new Date(p).toLocaleDateString() },
    {
      field: 'order_location',
      headerName: 'Delivery Location',
      flex: 0.7,
      valueGetter: (params) => (params ? params.city : ''),
    },
    {
      field: 'payment_terms',
      headerName: 'Payment Terms',
      flex: 0.71,
      valueGetter: (params) => (params ? params.payment_terms : ''),
      renderCell: (params) => {
        const { credit_term, payment_option } = params.row;
        return credit_term?.credit_term == 0
          ? payment_option?.payment_option
          : `${credit_term?.credit_term} days ${payment_option?.payment_option}`;
      },
    },
    {
      field: 'fulfilment_type',
      headerName: 'Fulfilment Type',
      flex: 1,
      // valueGetter: (params) => {
      //   if (params) return params.address_line_1 + ', ' + params.address_line_2 + ', ' + params.city + ', ' + params.state;
      // },
    },
    {
      field: 'grant_total',
      headerName: 'Grand Total',
      flex: 0.7,
      // valueGetter: (params) => (params ? params.mill_name : ''),
      // renderCell: (params) => {
      //   return <Box sx={{ color: params.row.mill.is_external ? Colors.error : 'inherit' }}>{params.value}</Box>;
      // },
    },
    {
      field: 'payment_entry_date',
      headerName: 'Payment Entry',
      flex: 0.7,
      valueGetter: (p) => p && new Date(p).toLocaleDateString(),
    },
    {
      field: 'order_acceptance_date',
      headerName: 'Order Acceptance',
      flex: 0.9,
      valueGetter: (p) => p && new Date(p).toLocaleDateString(),
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1.1,
      renderCell: (params) => {
        const status = params.value;
        return (
          <Box sx={{ height: '100%', display: 'flex', alignItems: 'center' }}>
            <Box
              sx={{
                background: getorderStatusBgColor(status),
                height: '25px',
                width: '80px',
                borderRadius: '15px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'white',
                padding: '1px 20px',
              }}
            >
              {status}
            </Box>
          </Box>
        );
      },
    },
    {
      headerName: 'Edit',
      width: 51,
      renderCell: ({ id }) => {
        const handleEdit = () => {
          navigate('/order/details', { state: { id, mode: 'edit' } });
        };
        return (
          <img
            style={{ height: '25px', marginLeft: '10px', marginTop: '10px', cursor: 'pointer' }}
            src={Edit}
            alt="edit"
            onClick={handleEdit}
          />
        );
      },
    },
  ];
  const handleSelectedFilters = (filter) => {
    // const cleanedFilters = Object.fromEntries(
    //   Object.entries(filter).filter(([key, value]) => {
    //     if (key) return Array.isArray(value) ? value.length > 0 : value;
    //   })
    // );
    setFilters(filter);
    setCurrentPage(1);
  };
  const handleSearch = (e) => {
    const keyword = e.target.value.trim();
    setKeyword(keyword);
    setCurrentPage(1);
  };
  const handleDelete = async () => {
    try {
      const res = await postData('/order/delete', { id: selectedRows }, {}, userData.token);
      if (res && res && res.data.status) {
        setDeleteWarning(false);
        fetchOrders();
        dispatch(showSnackbar({ message: 'Order deleted successfully', severity: 'success' }));
        // setModalOpen(false);
      } else dispatch(showSnackbar({ message: 'Something went wrong', severity: 'error' }));
    } catch (error) {
      console.log(error);
      if (error.response && error.response.data) dispatch(showSnackbar({ message: error.response.data.message, severity: 'error' }));
      else dispatch(showSnackbar({ message: 'Something went wrong...!', severity: 'error' }));
    }
    setSelectedRows([]);
  };
  const handleExport = async () => {
    // if (selectedRows.length === 0) {
    //   dispatch(showSnackbar({ message: 'Please select orders to export!', severity: 'warning' }));
    //   return;
    // }
    try {
      const response = await postDataExport(
        '/order/exportList',
        { orderIds: selectedRows },
        {
          headers: {
            Authorization: `Bearer ${userData.token}`,
          },
          responseType: 'blob',
        },
        userData.token
      );

      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'order.xlsx';
      link.click();

      dispatch(showSnackbar({ message: 'Export successful', severity: 'success' }));
    } catch (error) {
      console.error('Error:', error);

      dispatch(showSnackbar({ message: 'Something went wrong', severity: 'error' }));
    }
  };
  return (
    <Box>
      <Heading26Bold text={'Order Management'} />
      <Box
        sx={{
          marginTop: '20px',
          height: '10.4vh',
          borderRadius: '10px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: ' center',
          padding: '0px 30px',
          background: Colors.bg_strip,
        }}
      >
        <CustomSearch
          placeholder="Search Orders"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img src={Magnifier} alt="Search" style={{ width: '20px', height: '20px' }} />
              </InputAdornment>
            ),
          }}
          onChange={handleSearch}
        />
        <Box sx={{ display: 'flex', gap: '20px' }}>
          <Box>
            <CustomBtnBox onClick={handleClickFilter}>
              <img src={Filter} alt="filter" style={{ height: '20px', width: '25px' }} />
              <Typography sx={{ fontSize: '10px' }}>FILTER</Typography>
            </CustomBtnBox>
            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={handleCloseFilter}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <Box>
                <OrderFilter selectedFilters={handleSelectedFilters} filterData={filters} />
                {isFilterActive && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'flex-end',
                      position: 'relative',
                      bottom: 10,
                      right: 0, // Aligns the button to the right
                      width: '100%', // Makes sure the flex container takes full width
                      paddingRight: '30px', // Adjust spacing from the right if needed
                    }}
                  >
                    <Button variant="contained" size="small" onClick={handleClearFilter}>
                      Clear filter
                    </Button>
                  </Box>
                )}
              </Box>
            </Popover>
          </Box>
          <CustomBtnBox onClick={handleExport}>
            <img src={exportIcon} alt="exportIcon" style={{ height: '22px', width: '22px' }} />
            <Typography sx={{ fontSize: '10px' }}>EXPORT</Typography>
          </CustomBtnBox>
          <CustomBtnBox onClick={() => setDeleteWarning(true)}>
            <img src={Delete} alt="delete" style={{ height: '21px', width: '20px' }} />
            <Typography sx={{ fontSize: '10px' }}>DELETE</Typography>
          </CustomBtnBox>
        </Box>
      </Box>
      <Box>
        {/* <ListDataTable list={orderListData} path={`/${props.path}/edit`} tableHead={TableHeader.Order_list} /> */}
        <CustomDataGrid rowIdKey={'order_id'} columnsData={columnsData} rowsData={allOrders} onSelectCheckbox={(r) => setSelectedRows(r)} />
        <Pagination currentPage={page} totalPages={totalPages} onPageChange={(p) => setCurrentPage(p)} />
      </Box>
      <Modal
        open={deleteWarning}
        onClose={() => setDeleteWarning(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={DeleteModalstyle}>
          {selectedRows?.length ? (
            <>
              <Heading16Reg text="Do you want to Delete Users Permanantly?" />
              <Box sx={{ display: 'flex', gap: 2, mt: 1 }}>
                <Button variant="outlined" sx={{ borderRadius: '20px', textTransform: 'none' }} onClick={() => setDeleteWarning(false)}>
                  Cancel
                </Button>
                <Button variant="contained" color="error" sx={{ borderRadius: '20px', textTransform: 'none' }} onClick={handleDelete}>
                  Delete
                </Button>
              </Box>
            </>
          ) : (
            <Heading16Reg text="Please select Order first." />
          )}
        </Box>
      </Modal>
    </Box>
  );
};
OrderManagement.propTypes = {
  selectedFilters: PropTypes.func,
  filterData: PropTypes.object,
};
export default OrderManagement;
