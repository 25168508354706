import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getData } from '../../../../services/APIService';
import { Box, InputAdornment, styled, TextField } from '@mui/material';
import Colors from '../../../../theme/colors/Color';
import Pagination from '../../../../utils/component/pagination/Pagination';
import { Magnifier } from '../../../../theme/Images';

const headerCellStyle = {
  border: '1px solid #ddd',
  color: '#fff',
  backgroundColor: '#0093D3',
  fontFamily: 'poppins-regular',
  textTransform: 'capitalize',
};
const headerCellStyle1 = {
  border: '1px solid #ddd',
  color: 'red',
  fontFamily: 'poppins-regular',
};
const headerCellStyle2 = {
  border: '1px solid #ddd',
  fontFamily: 'poppins-regular',
};

const formatDate = (dateString) => {
  if (dateString) {
    const date = new Date(dateString);

    const formattedDate = date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    });

    const formattedTime = date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });

    return `${formattedDate}, ${formattedTime}`;
  }
  return '';
};

const d = (v, status) => {
  if (status) return v ? 'Yes' : 'No';
};
const limit = 50;
let totalPages = 1;
const CustomSearch = styled(TextField)(() => ({
  '& .MuiOutlinedInput-root': {
    '&.MuiInputBase-root': {
      height: '40px',
      borderRadius: '10px',
      width: '31.8vw',
      background: Colors.white,
    },
  },
}));
export default function Inventory() {
  const userData = JSON.parse(localStorage.getItem('userDetails'));
  const [list, setList] = useState([]);
  const [page, setCurrentPage] = useState(1);
  const [keyword, setKeyword] = useState('');
  const fetchData = async () => {
    await getData(`/tally/inventory?page=${page}&limit=${limit}&keyword=${keyword}`, {}, userData.token)
      .then((res) => {
        if (res.data.data && res.data.data?.result) {
          setList(res.data.data?.result || []);
          totalPages = res.data.data?.totalPages || 1;
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchData();
  }, [page, keyword]);
  const handleSearch = (e) => {
    const keyword = e.target.value.trim();
    setKeyword(keyword);
    setCurrentPage(1);
  };
  return (
    <>
      <Box
        sx={{
          marginTop: '20px',
          height: '10.4vh',
          borderRadius: '10px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: ' center',
          padding: '0px 30px',
          background: Colors.bg_strip,
        }}
      >
        <CustomSearch
          placeholder="Search Inventory"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img src={Magnifier} alt="Search" style={{ width: '20px', height: '20px' }} />
              </InputAdornment>
            ),
          }}
          onChange={handleSearch}
        />
      </Box>
      {list?.map((item) => (
        <TableContainer key={item.product_code} component={Paper} sx={{ mb: 2 }}>
          <Table sx={{ minWidth: 650, border: '1px solid #ddd' }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={!item.product_id ? headerCellStyle1 : null} colSpan={4}>
                  Product Code : {item.product_code}
                </TableCell>
                <TableCell colSpan={8}>
                  Last Updated : {item.data && item.data.length > 0 && formatDate(item.data[item.data.length - 1].createdAt)}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableHead>
              <TableRow sx={{ backgroundColor: '#0093D3' }}>
                <TableCell sx={headerCellStyle}>Date + Time</TableCell>
                <TableCell sx={headerCellStyle}>Opening Stock</TableCell>
                <TableCell sx={headerCellStyle}>Customer Name</TableCell>
                <TableCell sx={headerCellStyle}>Order No</TableCell>
                <TableCell sx={headerCellStyle}>Order Date</TableCell>
                <TableCell sx={headerCellStyle}>Quantity Ordered</TableCell>
                <TableCell sx={headerCellStyle}>Payment Terms</TableCell>
                <TableCell sx={headerCellStyle}>Block Duration</TableCell>
                <TableCell sx={headerCellStyle}>Status</TableCell>
                <TableCell sx={headerCellStyle}>Block Date</TableCell>
                <TableCell sx={headerCellStyle}>Payment Entered</TableCell>
                <TableCell sx={headerCellStyle}>Payment Entry Date</TableCell>
                <TableCell sx={headerCellStyle}>Order Accepted</TableCell>
                <TableCell sx={headerCellStyle}>Order Accept Date</TableCell>
                <TableCell sx={headerCellStyle}>Invoice Generated</TableCell>
                <TableCell sx={headerCellStyle}>Invoice Date</TableCell>
                <TableCell sx={headerCellStyle}>Quantity Modified</TableCell>
                <TableCell sx={headerCellStyle}>Inward Stock</TableCell>
                <TableCell sx={headerCellStyle}>Display Stock</TableCell>
                <TableCell sx={headerCellStyle}>Validation</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {item.data?.map((row) => (
                <TableRow key={row.id} sx={headerCellStyle2}>
                  <TableCell sx={headerCellStyle2}>{formatDate(row.createdAt)}</TableCell>
                  <TableCell sx={headerCellStyle2}>{row.opening_stock}</TableCell>
                  <TableCell sx={headerCellStyle2}>{row.customer?.firm_name}</TableCell>
                  <TableCell sx={headerCellStyle2}>{row.order?.order_number}</TableCell>
                  <TableCell sx={headerCellStyle2}>{formatDate(row.order_date)}</TableCell>
                  <TableCell sx={headerCellStyle2}>{row.order_quantity}</TableCell>
                  <TableCell sx={headerCellStyle2}>{row.payment_terms}</TableCell>
                  <TableCell sx={headerCellStyle2}>
                    {row.block_duration} {row.block_duration && (row.block_duration < 2 ? 'Day' : 'Days')}
                  </TableCell>
                  <TableCell sx={headerCellStyle2}>{row.status}</TableCell>
                  <TableCell sx={headerCellStyle2}>{formatDate(row.block_date)}</TableCell>
                  <TableCell sx={headerCellStyle2}>{d(row.is_payment_entered, row.status)}</TableCell>
                  <TableCell sx={headerCellStyle2}>{formatDate(row.payment_entry_date)}</TableCell>
                  <TableCell sx={headerCellStyle2}>{d(row.is_order_accepted, row.status)}</TableCell>
                  <TableCell sx={headerCellStyle2}>{formatDate(row.order_accept_date)}</TableCell>
                  <TableCell sx={headerCellStyle2}>{d(row.is_invoice_generated, row.status)}</TableCell>
                  <TableCell sx={headerCellStyle2}>{formatDate(row.invoice_date)}</TableCell>
                  <TableCell sx={headerCellStyle2}>{row.quantity_modified}</TableCell>
                  <TableCell sx={headerCellStyle2}>{row.inward_stock}</TableCell>
                  <TableCell sx={headerCellStyle2}>{row.display_stock}</TableCell>
                  <TableCell sx={headerCellStyle2}>{row.validation}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ))}
      <Pagination currentPage={page} totalPages={totalPages} onPageChange={(p) => setCurrentPage(p)} />
      {(!list || list?.length == 0) && <Box>No data found</Box>}
    </>
  );
}
