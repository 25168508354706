import React, { useEffect, useState } from 'react';
import './SideNavBar.css';
import { Link, useLocation } from 'react-router-dom';
import { ChevronDown, ChevronUp } from 'react-feather';

import {
  Logo,
  productManagement,
  enquiryManagement,
  userManagement,
  OrderManagemeht,
  communityIcon,
  permissionIcon,
  masterIcon,
} from '../../../theme/Images';

const SideNavbar = () => {
  const location = useLocation();

  if (location.pathname == '/') {
    localStorage.removeItem('activeDropdown');
    localStorage.removeItem('activeMenuItem');
  }
  // const [sidebarOpen, setSidebarOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState('');
  const [activeMenuItem, setActiveMenuItem] = useState('');
  // const [showItems, setShowItems] = useState(true);

  useEffect(() => {
    const storedDropdown = localStorage.getItem('activeDropdown');
    const storedMenuItem = localStorage.getItem('activeMenuItem');
    if (storedDropdown) setActiveDropdown(storedDropdown);
    if (storedMenuItem) setActiveMenuItem(storedMenuItem);
  }, []);

  useEffect(() => {
    localStorage.setItem('activeDropdown', activeDropdown);
    localStorage.setItem('activeMenuItem', activeMenuItem);
  }, [activeDropdown, activeMenuItem]);

  const toggleDropdown = (menu) => {
    setActiveDropdown((prev) => (prev === menu ? '' : menu));
  };

  // const toggleSidebar = () => {
  //   setSidebarOpen(!sidebarOpen);
  // };

  const menuItems = [
    {
      id: 'user',
      label: 'User Management',
      icon: userManagement,
      submenu: [
        // { label: 'Add/Edit User', to: '/user-management' },
        { label: 'BOS Employee', to: '/employee' },
        { label: 'Customer', to: '/customer' },
        { label: 'Supplier', to: '/supplier' },
        { label: 'Vendor', to: '/vendor' },
      ],
    },
    {
      id: 'enquiry',
      label: 'Enquiry Management',
      icon: enquiryManagement,
      submenu: [{ label: 'Add/Edit Enquiry', to: '/enquiry' }],
    },
    {
      id: 'product',
      label: 'Product Management',
      icon: productManagement,
      submenu: [{ label: 'Add/Edit Product', to: '/product' }],
    },
    {
      id: 'order',
      label: 'Order Management',
      icon: OrderManagemeht,
      submenu: [{ label: 'Add/Edit Order', to: '/order' }],
    },
    {
      id: 'community',
      label: 'Community',
      icon: communityIcon,
      submenu: [{ label: 'Add/Edit Community', to: '/community' }],
    },
    {
      id: 'permission',
      label: 'Permission',
      icon: permissionIcon,
      submenu: [{ label: 'Add/Edit Permission', to: '/permission' }],
    },
    {
      id: 'master',
      label: 'Master',
      icon: masterIcon,
      submenu: [
        { label: 'Dropdown List Master', to: '/master/dropdownlist' },
        { label: 'BOS Master', to: '/master/bos' },
        { label: 'Tally Master', to: '/master/tally' },
      ],
    },
  ];

  return (
    // <div className={`sidenavbar-content ${sidebarOpen ? 'open' : ''}`}>
    <div className="sidenavbar">
      <div className="sidenavbar-header">
        <Link to="/dashboard">
          <img src={Logo} alt="Logo Missing" className="company-logo" />
        </Link>
        <h2 className="title">Administration</h2>
      </div>
      <ul className="menu-list">
        {menuItems.map((menu) => (
          <li key={menu.id} className={`menu-item ${activeDropdown === menu.id ? 'active-main' : ''}`}>
            <div className="menu-item-container" onClick={() => toggleDropdown(menu.id)}>
              <Link
                to={menu.submenu[0]?.to || '#'}
                className={`nav-link ${activeMenuItem === menu.id ? 'active' : ''}`}
                onClick={() => setActiveMenuItem(menu.id)}
                style={{ color: 'black' }}
              >
                <img className="menu-item-icon" src={menu.icon} alt={`${menu.label} Icon`} />
                {menu.label}
              </Link>
              <button className="dropdown-button">{activeDropdown === menu.id ? <ChevronUp /> : <ChevronDown />}</button>
            </div>
            {activeDropdown === menu.id && (
              <ul className="submenu">
                {menu.submenu.map((subItem) => (
                  <Link
                    key={subItem.label}
                    to={subItem.to}
                    className={`nav-link sub-nav-link ${activeMenuItem === subItem.label ? 'active' : ''}`}
                    onClick={() => setActiveMenuItem(subItem.label)}
                  >
                    <li>{subItem.label}</li>
                  </Link>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SideNavbar;
