import { Box, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Colors from '../../../../theme/colors/Color';
import { Heading16Reg } from '../../../../common-components';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSuppliers } from '../../../../redux/features/user management/supplierListSlice';
import { FILTER_CONSTANT } from '../../../../utils/constants/filters';
import { fetchKam } from '../../../../redux/features/user management/kamListSlice';
import MultiValCombobox from '../../../../utils/component/combo-box/MultiValCombobox';
import { postData } from '../../../../services/APIService';

const CustomBox = styled(Box)(() => ({
  padding: '15px',
  borderBottom: '1px solid',
  borderColor: Colors.secondary1,
  cursor: 'pointer',
}));

const BoxContainer = styled(Box)(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '10px',
}));

const BoxItem = styled(Box)(() => ({
  background: Colors.bg_filterItem,
  padding: '3px 15px',
  borderRadius: '20px',
  cursor: 'pointer',
}));

const filterItems = ['Type Of User', 'Name Of User', 'Status'];

const VendorFilter = ({ filterData, selectedFilters }) => {
  const [filters, setFilters] = useState(filterData);
  const [vendorList, setVendorList] = useState([]);
  const dispatch = useDispatch();
  const allSuppliers = useSelector((state) => state.suppliers.data);
  const allKams = useSelector((state) => state.kam.data);
  const userData = JSON.parse(localStorage.getItem('userDetails'));

  useEffect(() => {
    getUserData();
  }, []);
  useEffect(() => {
    setFilters(filterData);
  }, [filterData]);

  useEffect(() => {
    if (!allSuppliers || allSuppliers.length === 0) {
      dispatch(fetchSuppliers());
    }
  }, [dispatch, allSuppliers]);

  useEffect(() => {
    if (!allKams || allKams.length === 0) {
      dispatch(fetchKam());
    }
  }, [dispatch, allKams]);

  const [selectedItem, setSelectedItem] = useState('Type Of User');

  const selectFilter = (item) => {
    setSelectedItem(item);
  };

  const isValueSelected = (category, value) => {
    return filters[category]?.includes(value);
  };

  const getUserData = async () => {
    await postData('users/getAllUser', { type: 'VENDOR', page: 1, limit: 1000 }, {}, userData.token)
      .then((res) => {
        setVendorList(res.data.data.users);
        const uniqueNames = new Set();
        const distinctVendors = [];
        res.data.data.users.forEach((user) => {
          if (!uniqueNames.has(user.name)) {
            uniqueNames.add(user.name);
            distinctVendors.push({ id: user.id, name: user.name });
          }
        });
        setVendorList(distinctVendors);
      })
      .catch((error) => console.log(error));
  };

  const toggleSelect = (category, value) => {
    setFilters((prev) => {
      const isSelected = prev[category].includes(value);
      const updatedFilters = {
        ...prev,
        [category]: isSelected ? prev[category].filter((item) => item !== value) : [...prev[category], value],
      };
      selectedFilters(updatedFilters);
      return updatedFilters;
    });
  };

  return (
    <Box display={'flex'} width={'35vw'} fontFamily={'poppins-regular'}>
      <Box width={'50%'} borderRight={'1px solid'} borderColor={Colors.secondary1}>
        {filterItems.map((item, i) => (
          <CustomBox key={i} sx={{ background: selectedItem === item ? Colors.bg_strip : '' }} onClick={() => selectFilter(item)}>
            <Heading16Reg text={item} />
          </CustomBox>
        ))}
      </Box>
      <Box width={'50%'} padding={2}>
        {selectedItem === 'Type Of User' && (
          <BoxContainer>
            {Object.keys(FILTER_CONSTANT.vendor_type).map((type, index) => (
              <BoxItem
                key={index}
                sx={{
                  background: isValueSelected('sub_type', type) ? Colors.bg_strip : Colors.bg_filterItem,
                }}
                onClick={() => toggleSelect('sub_type', type)}
              >
                {FILTER_CONSTANT.vendor_type[type]}
              </BoxItem>
            ))}
          </BoxContainer>
        )}
        {selectedItem === 'Status' && (
          <BoxContainer>
            {Object.keys(FILTER_CONSTANT.status).map((status, index) => (
              <BoxItem
                key={index}
                sx={{
                  background: isValueSelected('status', status) ? Colors.bg_strip : Colors.bg_filterItem,
                }}
                onClick={() => toggleSelect('status', status)}
              >
                {FILTER_CONSTANT.status[status]}
              </BoxItem>
            ))}
          </BoxContainer>
        )}
        {selectedItem === 'Name Of User' && (
          <MultiValCombobox
            optionsLabel={'name'}
            value={vendorList.filter((c) => filters.user_name.includes(c.name))}
            options={vendorList}
            onChangeValue={(newValue) =>
              setFilters((prev) => {
                const updatedFilters = { ...prev, user_name: newValue.map((item) => item.name) };
                selectedFilters(updatedFilters);
                return updatedFilters;
              })
            }
            bgColor={Colors.bg_filterItem}
            isMultiSelect={true}
            placeholder={'Type/Select Name Of User'}
          />
        )}
      </Box>
    </Box>
  );
};

VendorFilter.propTypes = {
  vendorList: PropTypes.any,
  selectedFilters: PropTypes.func.isRequired,
  filterData: PropTypes.any,
};

export default VendorFilter;
