import React, { useEffect, useState } from 'react';
import {
  Box,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Typography,
  TableHead,
  TableRow,
  Alert,
  // Button,
  // MenuItem,
  // Menu,
} from '@mui/material';
import FormLabel from '@mui/material/FormLabel';
import { Heading26Bold } from '../../../../common-components';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorIcon from '@mui/icons-material/Error';
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { RecordsFailed, RecordsSuccess } from '../../../../theme/Images';
import { useLocation, useNavigate } from 'react-router-dom';
import TopNav from '../../../Navigation/TopNavBar/TopNav';
// import { useNavigate } from 'react-router-dom';
// import { useNavigate } from 'react-router-dom';

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
      }}
      sx={{ textTransform: 'none' }}
      {...props}
    />
  );
}

export default function NavTabs() {
  const [value, setValue] = useState(0);
  const [successfulUploads, setSuccessfulUploads] = useState([]);
  const [failedUploads, setFailedUploads] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const importResponse = location.state?.importResponse;
  const id = location.state?.id;
  useEffect(() => {
    if (importResponse?.data) {
      const data = importResponse.data;

      setSuccessfulUploads(data.successList || []);
      setFailedUploads(data.errorList || []);
      if (data.successList?.length == 0) setValue(1);
    } else {
      const urlPath = id ? `/${id}` : '';
      navigate('/import-products' + urlPath);
    }
  }, [importResponse]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const renderTable = (uploads) => (
    <TableContainer>
      <Table sx={{ minWidth: 650, marginTop: '30px' }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ backgroundColor: '#B3EFF3', fontWeight: 'bold', border: '1px solid black' }}>Sr. No.</TableCell>
            <TableCell sx={{ backgroundColor: '#B3EFF3', fontWeight: 'bold', border: '1px solid black' }}>Record</TableCell>
            <TableCell sx={{ backgroundColor: '#B3EFF3', fontWeight: 'bold', border: '1px solid black' }}>Date & Time</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {uploads.map((upload, index) => (
            <TableRow key={index}>
              <TableCell sx={{ border: '1px solid black', width: '10%', fontWeight: 'bold' }}>{index + 1}.</TableCell>
              <TableCell sx={{ border: '1px solid black', width: '70%' }}>
                <Box>
                  <Typography variant="body1">
                    <span style={{ fontWeight: 'bold' }}>Grade:</span> {upload?.rowData?.grade || ''}
                  </Typography>
                  <Typography variant="body3">
                    <span style={{ fontWeight: 'bold' }}>Size:</span> {upload?.rowData?.size || ''}
                  </Typography>
                  <Typography variant="body2">
                    <span style={{ fontWeight: 'bold' }}>Mill Name:</span> {upload?.rowData?.mill || ''}
                  </Typography>
                  <Typography variant="body4">
                    <span style={{ fontWeight: 'bold' }}>Warehouse location:</span> {upload?.rowData?.locations || ''}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell sx={{ border: '1px solid black', width: '20%' }}>{upload?.datetime || ''}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderTable1 = (uploads) => (
    <Box>
      <TableContainer>
        <Table sx={{ minWidth: 650, marginTop: '30px' }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ backgroundColor: '#B3EFF3', fontWeight: 'bold', border: '1px solid black' }}>Sr. No.</TableCell>
              <TableCell sx={{ backgroundColor: '#B3EFF3', fontWeight: 'bold', border: '1px solid black' }}>Record</TableCell>
              <TableCell sx={{ backgroundColor: '#B3EFF3', fontWeight: 'bold', border: '1px solid black' }}>Reason for failure</TableCell>
              <TableCell sx={{ backgroundColor: '#B3EFF3', fontWeight: 'bold', border: '1px solid black' }}>Date & Time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {uploads.map((upload, index) => (
              <TableRow key={index}>
                <TableCell sx={{ border: '1px solid black', width: '10%', fontWeight: 'bold' }}>{index + 1}.</TableCell>
                <TableCell sx={{ border: '1px solid black', width: '55%' }}>
                  <Box>
                    <Typography variant="body1">
                      <span style={{ fontWeight: 'bold' }}>Grade:</span> {upload?.rowData?.grade || ''}
                    </Typography>
                    <Typography variant="body3">
                      <span style={{ fontWeight: 'bold' }}>Size:</span> {upload?.rowData?.size || ''}
                    </Typography>
                    <Typography variant="body2">
                      <span style={{ fontWeight: 'bold' }}>Mill Name:</span> {upload?.rowData?.mill || ''}
                    </Typography>
                    <Typography variant="body4">
                      <span style={{ fontWeight: 'bold' }}>Warehouse location:</span> {upload?.rowData?.locations || ''}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell sx={{ border: '1px solid black', width: '20%' }}>
                  <ul>
                    {upload?.message?.map((reason, reasonIndex) => (
                      <li key={reasonIndex}>{reason}</li>
                    ))}
                  </ul>
                </TableCell>
                <TableCell sx={{ border: '1px solid black', width: '20%' }}>{upload?.datetime || ''}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );

  return (
    <>
      {id && <TopNav isSupplier={id} />}
      <Box sx={{ width: '90%', margin: '20px auto' }}>
        {successfulUploads?.length > 0 ? (
          <Alert
            sx={{ fontSize: '20px', fontWeight: 'bold' }}
            iconMapping={{
              success: <CheckCircleOutlineIcon fontSize="large" />,
            }}
          >
            Products Imported Successfully!
          </Alert>
        ) : (
          <Alert
            color="error"
            sx={{ fontSize: '20px', fontWeight: 'bold' }}
            iconMapping={{
              success: <ErrorIcon fontSize="large" />,
            }}
          >
            Products Import Failed!
          </Alert>
        )}
        <br />
        <FormLabel>
          <Heading26Bold text={successfulUploads.length == 0 ? 'Failed Records' : 'Imported records'} />
        </FormLabel>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="nav tabs example"
          role="navigation"
          sx={{
            marginTop: '20px',
            borderBottom: 'none',
            '& .MuiTab-root': {
              backgroundColor: 'white',
              borderRadius: '20px',
              color: 'black',
              mx: 1,
              border: 'none',
              '&:focus': {
                outline: 'none',
              },
            },
            '& .Mui-selected': {
              backgroundColor: '#05205E',
              border: 'none',
              outline: 'none',
              color: 'white !important',
              '&:focus': {
                outline: 'none',
              },
            },
            '& .MuiTabs-indicator': {
              height: '0px',
              backgroundColor: 'transparent',
              color: 'white',
            },
          }}
        >
          <LinkTab label="Successful" href="" />
          <LinkTab label="Failed" href="" />
        </Tabs>
        {value === 0 &&
          (successfulUploads.length > 0 ? (
            renderTable(successfulUploads)
          ) : (
            <Box sx={{ textAlign: 'center', mt: 4 }}>
              <Box
                component="img"
                src={RecordsSuccess}
                alt="Image not Found"
                sx={{
                  maxWidth: '100%',
                  width: 'auto',
                  height: 'auto',
                }}
              />
            </Box>
          ))}
        {value === 1 &&
          (failedUploads.length > 0 ? (
            renderTable1(failedUploads)
          ) : (
            <Box sx={{ textAlign: 'center', mt: 4 }}>
              <Box
                component="img"
                src={RecordsFailed}
                alt="Image not Found"
                sx={{
                  maxWidth: '100%',
                  width: 'auto',
                  height: 'auto',
                }}
              />
            </Box>
          ))}
      </Box>
    </>
  );
}
