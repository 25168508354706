import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useDispatch } from 'react-redux';
import { Heading26Bold } from '../../../../common-components';
import { FilledButton, OutlineButton } from '../../../../utils/component';
import Pagination from '../../../../utils/component/pagination/Pagination';
import { showSnackbar } from '../../../../redux/features/snackbar/snackbarSlice';
import { attachmentIcon, Delete, Edit } from '../../../../theme/Images';
import { postData, postFileData, putFileData, updateData } from '../../../../services/APIService';
import Colors from '../../../../theme/colors/Color';
import CloseIcon from '@mui/icons-material/Close';
import { fetchMasterByType } from '../../../../redux/features/masters/mastersSlice';
import { fetchEnquiryReasonsMaster } from '../../../../redux/features/masters/enquiryMasterSlice';
import { clearIds } from '../../../../redux/features/datagrid/datagridSlice';

const FieldBox = styled(Box)(() => ({
  height: '10vh',
  marginBottom: '2px',
  width: '100%',
}));
const headerCellStyle2 = {
  border: '1px solid #0093D3',
  fontFamily: 'poppins-regular',
};
const CustomBtnBox = styled(Box)(() => ({
  height: '35px',
  width: '35px',
  borderRadius: '6px',
  padding: '10px',
  background: '#70FACB4D',
  textAlign: 'center',
  cursor: 'pointer',
  marginBottom: 20,
}));
const DropdownFieldMaster = () => {
  let totalPages = 1;
  const limit = 10;
  const dropdownLists = [
    { name: 'User Department', apiRoute: 'mstUserDepartment', columnList: [{ heading: 'Name', field: 'name' }], idKey: 'id' },
    { name: 'User Category', apiRoute: 'mstUserCategory', columnList: [{ heading: 'Name', field: 'name' }], idKey: 'id' },
    { name: 'Type Of Firm', apiRoute: 'mstTypeOfFirm', columnList: [{ heading: 'Name', field: 'name' }], idKey: 'id' },
    { name: 'OEM', apiRoute: 'mstUserOEM', columnList: [{ heading: 'Name', field: 'name' }], idKey: 'id' },
    { name: 'Preferred Supplier', apiRoute: 'mstPreferredSupplier', columnList: [{ heading: 'Name', field: 'name' }], idKey: 'id' },
    { name: 'Market Segment', apiRoute: 'mstMarketSegment', columnList: [{ heading: 'Name', field: 'name' }], idKey: 'id' },
    { name: 'Enquiry Reasons', apiRoute: 'mstEnquiryReasons', columnList: [{ heading: 'Reason', field: 'reason' }], idKey: 'id' },
    {
      name: 'Slider Images',
      apiRoute: 'mstHomeScreen',
      type: 'Slider Images',
      columnList: [{ heading: 'Image', field: 'attachment' }],
      idKey: 'id',
    },
    {
      name: 'Most Trending Products',
      apiRoute: 'mstHomeScreen',
      type: 'Trading Products',
      columnList: [
        { heading: 'Name', field: 'name' },
        { heading: 'Sub heading', field: 'subheading' },
        { heading: 'Rating', field: 'rating' },
        { heading: 'Image', field: 'attachment' },
      ],
    },
    {
      name: 'New Arrivals',
      apiRoute: 'mstHomeScreen',
      type: 'New Arrival',
      columnList: [
        { heading: 'Name', field: 'name' },
        { heading: 'Sub heading', field: 'subheading' },
        { heading: 'Rating', field: 'rating' },
        { heading: 'Image', field: 'attachment' },
      ],
    },
  ];
  const initialMasterDetail = {
    name: '',
    type: '',
    attachment: '',
    subheading: '',
    rating: 0,
    id: null,
  };
  const [selectedMaster, setSelectedMaster] = useState(dropdownLists[0]);
  const [currentPage, setCurrentPage] = useState(1);
  const [masterDataList, setMasterDataList] = useState([]);
  const [masterDetails, setMasterDetails] = useState(initialMasterDetail);
  const [selectedIds, setSelectedIds] = useState([]);
  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem('userDetails'));
  const fileInputRef = useRef(null);
  const [attachFileName, setAttachFileName] = useState([]);
  const [isAttachmentDisabled, setIsAttachmentDisabled] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const imageTypeMasters = ['Slider Images', 'Most Trending Products', 'New Arrivals'];
  const handleSelectMaster = (master) => {
    setSelectedMaster(master);
    setIsAttachmentDisabled(!imageTypeMasters.includes(master.name));
    setAttachFileName([]);
    setSelectedIds([]);
    setMasterDetails(initialMasterDetail);
  };
  useEffect(() => {
    fetchMasterList();
  }, [currentPage, selectedMaster]);
  useEffect(() => {
    dispatch(clearIds());
  }, []);
  const fetchMasterList = async () => {
    try {
      const filters = selectedMaster.type ? { type: selectedMaster.type } : {};
      const urlPath = `/${selectedMaster.apiRoute}/getAll`;
      const res = await postData(urlPath, { page: currentPage, limit, filters: filters }, {}, userData.token);
      if (res?.data?.data) {
        totalPages = res.data.data.totalPages;
        setMasterDataList(res.data.data.list);
      } else {
        dispatch(showSnackbar({ message: res?.data?.message, severity: 'error' }));
        setMasterDataList([]);
      }
    } catch (error) {
      console.log('error: ', error);
      const errorMessage = error.response?.data?.message ? error.response?.data?.message : error.message;
      dispatch(showSnackbar({ message: errorMessage, severity: 'error' }));
      setMasterDataList([]);
    }
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleTextChange = (e) => {
    setMasterDetails((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    if (!validateMasterDetails()) return;
    try {
      const isImageMaster = imageTypeMasters.includes(selectedMaster.name);
      let payload;
      if (isImageMaster) {
        payload = new FormData();
        payload.append('name', masterDetails.name);
        payload.append('type', selectedMaster.type || '');
        payload.append('attachment', masterDetails.attachment);
        payload.append('file', attachFileName);
        payload.append('subheading', masterDetails.subheading || '');
        payload.append('rating', masterDetails.rating);
      } else {
        if (selectedMaster.name == 'Enquiry Reasons') masterDetails.reason = masterDetails.name;
        payload = masterDetails;
      }
      const urlPath = `/${selectedMaster.apiRoute}/update/${masterDetails.id}`;
      const res = isImageMaster
        ? await putFileData(urlPath, payload, {}, userData.token)
        : await updateData(urlPath, payload, {}, userData.token);
      if (res?.data?.status) {
        dispatch(showSnackbar({ message: 'Master value Update successfully', severity: 'success' }));
        await fetchMasterList();
        setMasterDetails(initialMasterDetail);
        if (!isImageMaster) {
          selectedMaster.name === 'Enquiry Reasons'
            ? dispatch(fetchEnquiryReasonsMaster())
            : dispatch(fetchMasterByType(selectedMaster.apiRoute));
        }
        setAttachFileName([]);
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
      } else {
        setMasterDetails([]);
        setAttachFileName([]);
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
        dispatch(showSnackbar({ message: res.response.data.message, severity: 'error' }));
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message ? error.response?.data?.message : error.message;
      dispatch(showSnackbar({ message: errorMessage, severity: 'error' }));
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateMasterDetails()) return;
    try {
      const isImageMaster = imageTypeMasters.includes(selectedMaster.name);
      let payload;
      if (isImageMaster) {
        payload = new FormData();
        payload.append('name', masterDetails.name);
        payload.append('type', selectedMaster.type || '');
        payload.append('subheading', masterDetails.subheading || '');
        payload.append('rating', masterDetails.rating || 0);
        // payload.append('attachment', attachFileName);
        payload.append('file', attachFileName);
      } else {
        if (selectedMaster.name == 'Enquiry Reasons') masterDetails.reason = masterDetails.name;
        payload = masterDetails;
      }
      const urlPath = `/${selectedMaster.apiRoute}/add`;
      const res = isImageMaster
        ? await postFileData(urlPath, payload, {}, userData.token)
        : await postData(urlPath, payload, {}, userData.token);
      if (res?.data?.status) {
        dispatch(showSnackbar({ message: 'Master value added successfully', severity: 'success' }));
        await fetchMasterList();
        setMasterDetails(initialMasterDetail);
        if (!isImageMaster) {
          dispatch(fetchMasterByType(selectedMaster.apiRoute));
        }
        setAttachFileName([]);
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
      } else {
        dispatch(showSnackbar({ message: res.response?.data?.message, severity: 'error' }));
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message ? error.response?.data?.message : error.message;
      dispatch(showSnackbar({ message: errorMessage, severity: 'error' }));
    }
  };
  const validateMasterDetails = () => {
    const isImageMaster = imageTypeMasters.includes(selectedMaster.name);
    if (selectedMaster.name === 'New Arrivals' && masterDataList.length == 5) {
      dispatch(showSnackbar({ message: 'You can only add up to 5 items for "New Arrivals".', severity: 'warning' }));
      return false;
    }

    if (!masterDetails.name && selectedMaster.name !== 'Slider Images') {
      dispatch(
        showSnackbar({
          message: `Please Enter ${selectedMaster.name === 'New Arrivals' || selectedMaster.name === 'Most Trending Products' ? 'Heading' : 'Master'} Value`,
          severity: 'warning',
        })
      );
      return false;
    }

    if ((selectedMaster.name === 'New Arrivals' || selectedMaster.name === 'Most Trending Products') && !masterDetails.subheading) {
      dispatch(showSnackbar({ message: 'Please enter sub heading', severity: 'warning' }));
      return false;
    }

    if (selectedMaster.name === 'New Arrivals' || selectedMaster.name === 'Most Trending Products') {
      const rating = parseFloat(masterDetails.rating);
      if (isNaN(rating) || rating < 0 || rating > 5) {
        dispatch(showSnackbar({ message: 'Please enter a valid rating (0 to 5)', severity: 'warning' }));
        return false;
      }
    }

    if (isImageMaster && !attachFileName.name) {
      dispatch(showSnackbar({ message: 'Please select file', severity: 'warning' }));
      return false;
    }

    return true;
  };
  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const allIds = masterDataList.map((row) => row.id);

      setSelectedIds(allIds);
    } else {
      setSelectedIds([]);
    }
  };
  const handleSelectRow = (id) => {
    setSelectedIds((prev) => (prev.includes(id) ? prev.filter((selectedId) => selectedId !== id) : [...prev, id]));
  };
  const getNestedFieldValue = (obj, path) => {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  };
  const editRow = async (row) => {
    if (selectedMaster.name == 'Enquiry Reasons') row.name = row.reason;
    setMasterDetails((prev) => ({ ...prev, ...row }));
  };
  const handleAttachFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      if (!file.type.startsWith('image/')) {
        dispatch(showSnackbar({ message: 'Please select a valid image file', severity: 'warning' }));
        event.target.value = '';
      } else {
        setAttachFileName(file);
        setMasterDetails((prev) => ({ ...prev, attachment: '' }));
        // setMasterDetails((prev) => ({ ...prev, attachment: file }));
        event.target.value = '';
      }
    }
  };
  const handleCancel = () => {
    setAttachFileName([]);
    setMasterDetails(initialMasterDetail);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };
  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setSelectedImage(null);
    setIsPopupOpen(false);
  };
  const handleBulkDelete = async () => {
    if (setSelectedIds.length === 0) {
      dispatch(showSnackbar({ message: 'No items selected for deletion', severity: 'error' }));
      return;
    }

    if (window.confirm('Are you sure you want to delete selected items?')) {
      try {
        const urlPath = `/${selectedMaster.apiRoute}/bulkDelete`;
        await postData(urlPath, { ids: selectedIds }, {}, userData.token);
        dispatch(showSnackbar({ message: 'Selected items deleted successfully', severity: 'success' }));
        const isImageMaster = imageTypeMasters.includes(selectedMaster.name);
        setTimeout(async () => {
          await fetchMasterList();
          setSelectedIds([]);
          if (!isImageMaster) {
            dispatch(fetchMasterByType(selectedMaster.apiRoute));
          }
        }, 600);
      } catch (error) {
        dispatch(showSnackbar({ message: error?.response?.data?.message || error.message, severity: 'error' }));
      }
    }
  };

  return (
    <>
      <Heading26Bold text={'Dropdown List Master'} />
      <form>
        <Box sx={{ display: 'flex', alignItems: 'center', mt: 4 }}>
          <FormControl sx={{ m: 1, width: 1100 }}>
            <FieldBox sx={{ display: 'flex', alignItems: 'center' }}>
              {/* <Typography sx={{ mr: 2, fontWeight: 'bold' }}>Select Master</Typography> */}
              <InputLabel id="demo-multiple-name-label" sx={{ top: 6 }}>
                Select Master
              </InputLabel>
              <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                sx={{ width: '38%' }}
                fullWidth
                value={selectedMaster.name}
                label={'Select Master'}
                disabled={masterDetails.id ? true : false}
              >
                {dropdownLists.map((item, i) => (
                  <MenuItem value={item.name} key={i} onClick={() => handleSelectMaster(item)}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FieldBox>
            <FieldBox sx={{ mb: 0, display: 'flex' }}>
              <TextField
                label={
                  <>
                    {selectedMaster.name === 'New Arrivals' || selectedMaster.name === 'Most Trending Products'
                      ? 'Heading'
                      : 'Master Value'}{' '}
                    <span style={{ color: 'red' }}>*</span>
                  </>
                }
                sx={{ width: selectedMaster.name === 'New Arrivals' || selectedMaster.name === 'Most Trending Products' ? '30%' : '38%' }}
                name={'name'}
                value={masterDetails.name || ''}
                helperText={''}
                placeholder={
                  selectedMaster.name === 'New Arrivals' || selectedMaster.name === 'Most Trending Products'
                    ? 'Enter Heading'
                    : 'Enter Master Value'
                }
                onChange={handleTextChange}
                fullWidth
                margin="normal"
                disabled={selectedMaster.name === 'Slider Images'}
              />
              {selectedMaster.name === 'New Arrivals' || selectedMaster.name === 'Most Trending Products' ? (
                <>
                  <TextField
                    label={
                      <>
                        Sub Heading <span style={{ color: 'red' }}>*</span>
                      </>
                    }
                    sx={{ width: '30%', marginLeft: '10px' }}
                    name={'subheading'}
                    value={masterDetails.subheading || ''}
                    helperText={''}
                    placeholder={'Enter Sub Heading'}
                    onChange={handleTextChange}
                    fullWidth
                    margin="normal"
                  />
                  <TextField
                    label={
                      <>
                        Rating <span style={{ color: 'red' }}>*</span>
                      </>
                    }
                    sx={{ width: '20%', marginLeft: '10px' }}
                    name={'rating'}
                    value={masterDetails.rating}
                    helperText={''}
                    placeholder={'Enter Rating'}
                    onChange={handleTextChange}
                    fullWidth
                    margin="normal"
                    inputProps={{ type: 'number', min: 0, max: 5 }}
                  />
                </>
              ) : (
                ''
              )}
              {!isAttachmentDisabled && (
                <>
                  <input
                    type="file"
                    ref={fileInputRef}
                    accept="image/*" // Accept only image files
                    onChange={(event) => {
                      handleAttachFileChange(event);
                    }}
                    disabled={isAttachmentDisabled}
                    style={{
                      display: 'none',
                    }}
                  />
                  <Box
                    sx={{
                      height: '56px',
                      width: selectedMaster.name === 'New Arrivals' || selectedMaster.name === 'Most Trending Products' ? '38%' : '40%',
                      display: 'flex',
                      ml: 2,
                      mt: 2,
                      alignItems: 'center',
                      gap: '20px',
                      border: '0.5px solid',
                      borderColor: Colors.placeholder,
                      borderRadius: '5px',
                      padding: '0px 10px',
                      background: Colors.select_bg,
                      cursor: isAttachmentDisabled ? 'not-allowed' : 'pointer',
                      opacity: isAttachmentDisabled ? 0.5 : 1,
                      '&:hover': {
                        border: isAttachmentDisabled ? '0.5px solid' : '1px solid',
                      },
                    }}
                    onClick={() => {
                      if (!isAttachmentDisabled) {
                        fileInputRef.current.click();
                      }
                    }}
                  >
                    <img src={attachmentIcon} alt="attach" />
                    <Typography sx={{ fontFamily: 'poppins-regular', color: Colors.text_413e3e }}>
                      {masterDetails.attachment ? masterDetails.attachment.split('/').pop() : attachFileName?.name || 'Attachment'}
                    </Typography>
                  </Box>
                </>
              )}
            </FieldBox>
          </FormControl>
        </Box>
        <Box
          width={{ xs: '65vw', sm: '30vw' }}
          sx={{
            margin: '0px 0px 20px',
            marginTop: '30px',
            display: 'flex',
            alignItems: 'left',
            gap: '25px',
          }}
        >
          {masterDetails.id ? (
            <FilledButton type="submit" text={'Update'} onClick={handleUpdate} />
          ) : (
            <FilledButton type="submit" text={'Submit'} onClick={handleSubmit} />
          )}
          <OutlineButton text={'Cancel'} onClick={handleCancel} />
        </Box>
      </form>
      {/* <CustomDataGrid rowIdKey={'id'} columnsData={columnsData} rowsData={masterDataList} onSelectCheckbox={(r) => setSelectedIds(r)} /> */}
      <Box sx={{ display: 'flex', gap: '20px', justifyContent: 'flex-end' }}>
        <CustomBtnBox onClick={handleBulkDelete}>
          <img src={Delete} alt="delete" style={{ height: '21px', width: '20px' }} />
          <Typography sx={{ fontSize: '10px' }}>DELETE</Typography>
        </CustomBtnBox>
      </Box>
      <Box sx={{ mt: 0 }}>
        <TableContainer component={Paper}>
          <Table aria-label="simple table" sx={{ minWidth: 650, border: '1px solid #0093D3' }}>
            <TableHead>
              <TableRow sx={{ backgroundColor: '#0093D3' }} key="rowHeading">
                <TableCell padding="checkbox" key="selectAll">
                  <Checkbox
                    key="checkboxAll"
                    sx={{ color: '#fff' }}
                    indeterminate={selectedIds.length > 0 && selectedIds.length < masterDataList.length}
                    checked={masterDataList.length > 0 && selectedIds.length === masterDataList.length}
                    onChange={handleSelectAll}
                  />
                </TableCell>
                {selectedMaster.columnList.map((column, key) => {
                  const uniqueKey = column.field || column.heading;
                  return (
                    <TableCell
                      key={uniqueKey + key}
                      sx={{ border: '1px solid #ffffff', fontWeight: 600, textTransform: 'capitalize', color: '#ffffff' }}
                    >
                      {column.heading}
                    </TableCell>
                  );
                })}
                <TableCell key="editHeading" sx={{ border: '1px solid #0093D3', fontWeight: 600, color: '#ffffff' }}>
                  Edit
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {masterDataList.map((row) => (
                <TableRow key={row[selectedMaster.idKey]} sx={{ backgroundColor: row?.is_external ? '#F4C7C9' : 'none' }}>
                  <TableCell padding="checkbox" sx={{ border: '1px solid #0093D3' }} key={`selectAll-${row[selectedMaster.idKey]}`}>
                    <Checkbox
                      key={`checkAll-${row[selectedMaster.idKey]}`}
                      checked={selectedIds.includes(row[selectedMaster.idKey])}
                      onChange={() => handleSelectRow(row[selectedMaster.idKey])}
                    />
                  </TableCell>
                  {selectedMaster.columnList.map((column) => (
                    <TableCell key={column.field} sx={headerCellStyle2}>
                      {column.field === 'attachment' && row[column.field] ? (
                        <Typography
                          sx={{
                            color: 'blue',
                            textDecoration: 'underline',
                            cursor: 'pointer',
                          }}
                          onClick={() => handleImageClick(row[column.field])}
                        >
                          {row[column.field].split('/').pop()}
                        </Typography>
                      ) : (
                        getNestedFieldValue(row, column.field) || ''
                      )}
                    </TableCell>
                  ))}
                  <TableCell sx={{ border: '1px solid #0093D3' }}>
                    <img
                      key={`edit-${row[selectedMaster.idKey]}`}
                      style={{ height: '25px', marginLeft: '10px', marginTop: '5px', cursor: 'pointer' }}
                      src={Edit}
                      alt="edit"
                      onClick={() => editRow(row)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
      </Box>
      {/* Popup for displaying the image */}
      <Dialog open={isPopupOpen} onClose={handleClosePopup} maxWidth="sm" fullWidth>
        <DialogTitle>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6">Image Preview</Typography>
            <IconButton onClick={handleClosePopup}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          {selectedImage && (
            <Box sx={{ textAlign: 'center' }}>
              <img src={selectedImage} alt="Selected" style={{ maxWidth: '100%', maxHeight: '450px' }} />
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DropdownFieldMaster;
