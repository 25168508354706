import { Box, CircularProgress, FormControl, FormControlLabel, Grid, Modal, Paper, RadioGroup, Typography, styled } from '@mui/material';
// import { FormControl, FormControlLabel, FormLabel, RadioGroup } from '@mui/material';
import React, { useEffect, useState } from 'react';
// import FloatingLabelSelect from '../../../../common-components/select/FloatingLabelSelect'
// import FloatingLabelBox from '../../../../common-components/inputbox/FloatingLabelBox'
// import { FilledBtn, OutlineBtn } from '../../../../common-components'
import Colors from '../../../../theme/colors/Color';
import { Combobox, CustomRadioBtn, FilledButton, InputTextField, OutlineButton } from '../../../../utils/component';
// import { CustomRadioBtn } from '../../../../utils/component';
import { getData, postData, updateData } from '../../../../services/APIService';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { showSnackbar } from '../../../../redux/features/snackbar/snackbarSlice';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

const CustomPaper = styled(Paper)(() => ({
  padding: '25px 9px 0px',
}));

const FieldBox = styled(Box)(() => ({
  // height: '11.5vh',
  height: '10vh',
  marginBottom: '2.5px',
  // border: '1px solid blue',
}));

const categoryList = [
  { value: 'Trader', label: 'Trader' },
  { value: 'Manufacturer', label: 'Manufacturer' },
  { value: 'OEM', label: 'OEM' },
];

const firmTypeList = [
  { value: 'PR', label: 'PR' },
  { value: 'PP', label: 'PP' },
  { value: 'PVL', label: 'PVL' },
  { value: 'PUL', label: 'PUL' },
];

const marketSegment = [
  { value: 'Market Segment 1', label: 'Market Segment 1' },
  { value: 'Market Segment 2', label: 'Market Segment 2' },
];

const preferredSup = [
  { value: 'Preferred Supplier 1', label: 'Preferred Supplier 1' },
  { value: 'Preferred Supplier 2', label: 'Preferred Supplier 2' },
];

const oemList = [
  { value: 'OEM 1', label: 'OEM 1' },
  { value: 'OEM 2', label: 'OEM 2' },
];
const Modalstyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '25vw',
  bgcolor: 'background.paper',
  boxShadow: 40,
  p: 5,
};
const SGeneralDetails = () => {
  const [countryList, setCountryList] = useState([]);
  const [gstNo, setGstNo] = useState(null);
  const [isVerifyGst, setIsVerifyGst] = useState(false);
  const [showSubBtn, setShowSubBtn] = useState(true);
  const [isOtherCountry, setIsOtherCountry] = useState(true);
  const userData = JSON.parse(localStorage.getItem('userDetails'));
  const firmData = JSON.parse(localStorage.getItem('firmData'));
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const dispatch = useDispatch();
  const [kamUsers, setKamUsers] = useState([]);
  const [selectedkam, setSelectedkam] = useState('');
  const [status, setStatus] = useState('');
  const [formData, setFormData] = useState({
    gst_no: '',
    firm_name: '',
    registeredAs: 'SUPPLIER',
    country: '',
    registered_email: '',
    director_name: '',
    registered_mob_no: '',
    registered_address: '',
    firm_pan_number: '',
    estimated_turnover: '',
    firm_type: null,
    firm_category: null,
    website: '',
    payment_terms: '',
    kam_id: '',
    market_segment: '',
    preferred_suppliers: '',
    oem: '',
    // status: 'Active',
  });
  const [viewMode, setViewMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  // const viewMode = state?.mode == 'view';
  const editMode = state?.mode ? state?.mode == 'edit' : firmData?.mode == 'edit';

  useEffect(() => {
    setViewMode(state?.mode == 'view' || firmData?.mode == 'view');
    if (state?.firmId || firmData?.firmId) {
      const firmId = state?.firmId || firmData?.firmId;
      fetchFirmData(firmId);
    }
    fetchIntialData();
  }, []);
  useEffect(() => {
    if (formData.country && formData.registered_email && formData.kam_id) {
      setShowSubBtn(false);
    }
  }, [formData]);

  const fetchFirmData = async (id) => {
    const mode = state?.mode || firmData?.mode;
    try {
      const res = await getData(`/firm/${id}`, {}, userData.token);
      if (res && res.data && res.data.status) {
        setSelectedkam(res?.data?.data?.kam?.name);
        setFormData(res.data.data);
        setGstNo(res.data.data.gst_no);
        if (res.data.data.gst_no) setIsVerifyGst(true);
        const firmData = JSON.stringify({
          firmId: res.data.data.id,
          gstNo: res.data.data.gst_no,
          type: res.data.data.registeredAs,
          status: res.data.data.status,
          mode: mode,
        });
        setStatus(res.data.data?.status || 'ACTIVE');
        localStorage.setItem('firmData', firmData);
      } else dispatch(showSnackbar({ message: 'Something went wrong', severity: 'error' }));
    } catch (error) {
      console.log(error);
      if (error.response && error.response.data) dispatch(showSnackbar({ message: error.response.data.message, severity: 'error' }));
      else dispatch(showSnackbar({ message: 'Something went wrong...44444!', severity: 'error' }));
    }
  };

  const fetchIntialData = async () => {
    try {
      const res = await getData('/address/getcountry');
      if (res && res.data && res.data.status) {
        setCountryList(res.data.data);
      } else dispatch(showSnackbar({ message: 'Something went wrong', severity: 'error' }));

      const res2 = await getData('/users/getKams?role=KAM', {}, userData.token);
      if (res2 && res2.data && res2.data.status) setKamUsers(res2.data.data);
      else dispatch(showSnackbar({ message: 'Something went wrong', severity: 'error' }));
    } catch (error) {
      console.log(error);
      if (error.response && error.response.data) dispatch(showSnackbar({ message: error.response.data.message, severity: 'error' }));
      else dispatch(showSnackbar({ message: 'Something went wrong...!', severity: 'error' }));
    }
  };

  const verifyGst = async () => {
    if (!isVerifyGst)
      try {
        const res = await postData('/gst/verify', { gst_no: gstNo });
        if (res && res.data && res.data.status) {
          const data = res.data.data;
          setFormData((prev) => ({
            ...prev,
            gst_no: gstNo,
            firm_name: data.firm_name,
            registered_email: data.registered_email,
            director_name: data.director_name,
            registered_mob_no: data.registered_mob_number,
            registered_address: data.registered_address,
          }));
          setIsVerifyGst(true);
        } else dispatch(showSnackbar({ message: 'Something went wrong', severity: 'error' }));
      } catch (error) {
        console.log(error);
        if (error.response && error.response.data) dispatch(showSnackbar({ message: error.response.data.message, severity: 'error' }));
        else dispatch(showSnackbar({ message: 'Something went wrong...!', severity: 'error' }));
      }
  };

  const handleTextChange = (e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const selectedKamUser = (kam) => {
    if (kam) {
      setSelectedkam(kam.name);
      setFormData((prev) => ({ ...prev, kam_id: kam?.user_id }));
    }
  };

  const handleSubmit = async () => {
    try {
      const res = await postData('/firm/add', formData, {}, userData.token);
      if (res && res.data && res.data.status) {
        const firmData = JSON.stringify({
          firmId: res.data.data.id,
          gstNo: res.data.data.gst_no,
          type: res.data.data.registeredAs,
          status: res.data.data.status,
          mode: 'edit',
        });
        localStorage.setItem('firmData', firmData);
        dispatch(showSnackbar({ message: 'Supplier updated successfully', severity: 'success' }));
        navigate('/supplier/add/login-details');
      } else dispatch(showSnackbar({ message: res.message, severity: 'error' }));
    } catch (error) {
      if (error.response && error.response.data) dispatch(showSnackbar({ message: error.response.data.message, severity: 'error' }));
      else dispatch(showSnackbar({ message: 'Something went wrong...!', severity: 'error' }));
    }
  };
  const handleUpdate = async () => {
    try {
      const res = await updateData(`/firm/${state?.firmId || firmData?.firmId}`, formData, {}, userData.token);
      if (res && res.data && res.data.status) {
        dispatch(showSnackbar({ message: 'Supplier updated successfully', severity: 'success' }));
        navigate('/supplier');
        navigate(state?.mode == 'edit' ? '/supplier' : '/supplier/add/login-details');
      } else dispatch(showSnackbar({ message: '"Something went wrong"', severity: 'error' }));
    } catch (error) {
      console.log(error);
      if (error.response && error.response.data) dispatch(showSnackbar({ message: error.response.data.message, severity: 'error' }));
      else dispatch(showSnackbar({ message: 'Something went wrong...!', severity: 'error' }));
    }
  };
  const updateStatus = async () => {
    const updatedStatus = status == 'Active' ? 'Inactive' : 'Active';
    try {
      setLoading(true);
      const res = await postData(
        `/firm/updateStatus/${firmData?.firmId}`,
        { status: updatedStatus, type: firmData?.type },
        {},
        userData.token
      );
      if (res && res.data && res.data.status) {
        if (res.data.data?.activeUsers == 0) {
          firmData.status = updatedStatus;
          setStatus(updatedStatus);
        }
        const message = res.data.data?.activeUsers != 0 ? 'First logout all logins users from login details' : 'Status update successfully';
        dispatch(showSnackbar({ message: message, severity: res.data.data?.activeUsers != 0 ? 'warning' : 'success' }));
      } else dispatch(showSnackbar({ message: 'Error in updating status', severity: 'error' }));
      setLoading(false);
      setOpen(false);
    } catch (error) {
      console.log(error);
      if (error.response && error.response.data) dispatch(showSnackbar({ message: error.response.data.message, severity: 'error' }));
      else dispatch(showSnackbar({ message: 'Something went wrong...!', severity: 'error' }));
      setLoading(false);
      setOpen(false);
    }
  };
  return (
    <>
      <FieldBox color={Colors.text_413e3e} sx={{ position: 'absolute', right: 10, top: 1 }}>
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={status}
            onChange={() => setOpen(true)}
          >
            <FormControlLabel
              value="Active"
              control={<CustomRadioBtn />}
              label="Active"
              sx={{
                '& .MuiFormControlLabel-label': {
                  fontFamily: 'poppins-regular',
                },
              }}
            />
            <FormControlLabel
              value={'Inactive'}
              control={<CustomRadioBtn />}
              label="Inactive"
              sx={{
                '& .MuiFormControlLabel-label': {
                  fontFamily: 'poppins-regular',
                },
              }}
            />
          </RadioGroup>
        </FormControl>
      </FieldBox>
      <Grid container spacing={3}>
        <Grid item xs={12} lgm={8}>
          <CustomPaper elevation={5}>
            <Grid container>
              <Grid item xs={12} md={6} paddingRight={{ xs: 0, md: '5px' }}>
                <FieldBox>
                  <Combobox
                    label={'Country'}
                    optionsLabel={'country_name'}
                    options={countryList}
                    isDisable={viewMode || editMode || isVerifyGst}
                    isRequire={true}
                    value={formData.country}
                    onChangeValue={(country) => {
                      if (country?.country_name.toLowerCase() == 'india') setIsOtherCountry(false);
                      setFormData((prev) => ({ ...prev, country: country?.country_name }));
                    }}
                  />
                </FieldBox>
                <FieldBox>
                  <Combobox
                    label={'Category'}
                    optionsLabel={'label'}
                    options={categoryList}
                    isDisable={viewMode}
                    isRequire={false}
                    value={formData.firm_category}
                    onChangeValue={(item) => setFormData((prev) => ({ ...prev, firm_category: item?.label || null }))}
                  />
                </FieldBox>
                <FieldBox>
                  <InputTextField
                    label={'PAN'}
                    name={'firm_pan_number'}
                    value={formData.firm_pan_number}
                    placeholder={'DQMHP0042G'}
                    isRequire={false}
                    isDisable={viewMode}
                    onChange={handleTextChange}
                  />
                </FieldBox>
                <FieldBox>
                  <InputTextField
                    label={'Name of Firm'}
                    name={'firmName'}
                    value={formData.firm_name}
                    placeholder={''}
                    isRequire={false}
                    isDisable={true}
                    onChange={handleTextChange}
                  />
                </FieldBox>
                <FieldBox>
                  <InputTextField
                    label={'Register Mobile No'}
                    name={'mobNumber'}
                    value={formData.registered_mob_no}
                    placeholder={''}
                    isRequire={false}
                    isDisable={true}
                    onChange={handleTextChange}
                  />
                </FieldBox>
                <FieldBox>
                  <InputTextField
                    label={'Registered Address'}
                    name={'address'}
                    value={formData.registered_address}
                    placeholder={''}
                    isRequire={false}
                    isDisable={true}
                    onChange={handleTextChange}
                  />
                </FieldBox>
              </Grid>
              <Grid item xs={12} md={6} paddingLeft={{ xs: 0, md: '5px' }}>
                <FieldBox display={'flex'} gap={'10px'}>
                  <InputTextField
                    label={'GST No'}
                    name={'gst'}
                    value={gstNo}
                    isRequire={!isOtherCountry}
                    isDisable={viewMode || editMode || isOtherCountry || isVerifyGst}
                    onChange={(e) => setGstNo(e.target.value)}
                  />
                  <Typography
                    sx={{
                      color: viewMode || isVerifyGst || isOtherCountry ? Colors.placeholder : Colors.primary,
                      fontSize: '14px',
                      fontFamily: 'poppins-regular',
                      textDecoration: 'underline',
                      textDecorationColor: 'initial',
                      cursor: 'pointer',
                    }}
                    onClick={verifyGst}
                  >
                    Verify GST No.
                  </Typography>
                </FieldBox>
                <FieldBox>
                  <Combobox
                    label={'Type Of Firm'}
                    optionsLabel={'label'}
                    options={firmTypeList}
                    isDisable={viewMode}
                    isRequire={false}
                    value={formData.firm_type}
                    onChangeValue={(item) => setFormData((prev) => ({ ...prev, firm_type: item?.label || null }))}
                  />
                </FieldBox>
                <FieldBox>
                  <InputTextField
                    label={'Estimated Turnover'}
                    name={'estimated_turnover'}
                    value={formData.estimated_turnover}
                    placeholder={'200cr'}
                    isRequire={false}
                    isDisable={viewMode}
                    onChange={handleTextChange}
                  />
                </FieldBox>
                <FieldBox>
                  <InputTextField
                    label={'Name of The Supplier'}
                    name={'director_name'}
                    value={formData.director_name}
                    placeholder={''}
                    isRequire={false}
                    isDisable={true}
                    onChange={handleTextChange}
                  />
                </FieldBox>
                <FieldBox>
                  <InputTextField
                    label={'Registered Email Address'}
                    name={'email'}
                    value={formData.registered_email}
                    placeholder={'xyz@gmail.com'}
                    isRequire={false}
                    isDisable={true}
                    onChange={handleTextChange}
                  />
                </FieldBox>
                <FieldBox>
                  <InputTextField
                    label={'WebSite'}
                    name={'website'}
                    value={formData.website}
                    placeholder={'www.xyz.com'}
                    isRequire={false}
                    isDisable={viewMode}
                    onChange={handleTextChange}
                  />
                </FieldBox>
              </Grid>
            </Grid>
          </CustomPaper>
        </Grid>
        <Grid item xs={12} lgm={4}>
          <CustomPaper elevation={5}>
            <FieldBox>
              <InputTextField
                label={'Supplier Id'}
                name={'code'}
                value={formData.code}
                isRequire={false}
                isDisable={viewMode || editMode}
                onChange={handleTextChange}
              />
            </FieldBox>
            <Box sx={{ display: 'flex' }}>
              <FieldBox>
                <InputTextField
                  label={'Credit Terms'}
                  name={'payment_terms'}
                  value={formData.payment_terms}
                  placeholder={'0'}
                  isRequire={false}
                  isDisable={true}
                  onChange={handleTextChange}
                />
              </FieldBox>
              <FieldBox sx={{ ml: 2 }}>
                <InputTextField
                  label={'Credit Limit'}
                  name={'Credit Limit'}
                  value={formData.credit_limit}
                  placeholder={'Advance'}
                  isRequire={false}
                  isDisable={true}
                  onChange={handleTextChange}
                />
              </FieldBox>
            </Box>
            <FieldBox>
              <Combobox
                label={'KAM'}
                optionsLabel={'name'}
                options={kamUsers}
                isDisable={viewMode}
                isRequire={true}
                value={selectedkam}
                onChangeValue={selectedKamUser}
              />
            </FieldBox>
            <FieldBox>
              <Combobox
                label={'Market Segment'}
                optionsLabel={'label'}
                options={marketSegment}
                isDisable={viewMode}
                isRequire={false}
                value={formData.market_segment}
                onChangeValue={(item) => setFormData((prev) => ({ ...prev, market_segment: item?.label }))}
              />
            </FieldBox>
            <FieldBox>
              <Combobox
                label={'Preferred Suppliers'}
                optionsLabel={'label'}
                options={preferredSup}
                isDisable={viewMode}
                isRequire={false}
                value={formData.preferred_suppliers}
                onChangeValue={(item) => setFormData((prev) => ({ ...prev, preferred_suppliers: item?.label }))}
              />
            </FieldBox>
            <FieldBox>
              <Combobox
                label={'OEM'}
                optionsLabel={'label'}
                options={oemList}
                isDisable={viewMode}
                isRequire={false}
                value={formData.oem}
                onChangeValue={(item) => setFormData((prev) => ({ ...prev, oem: item?.label }))}
              />
            </FieldBox>
          </CustomPaper>
        </Grid>
      </Grid>
      <Box
        sx={{
          width: '100%',
          marginTop: '30px',
          marginBottom: '20px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box sx={{ display: 'flex', gap: '25px' }}>
          <OutlineButton text={'Cancel'} onClick={() => navigate('/supplier')} />
          {!viewMode ? (
            editMode ? (
              <FilledButton text={'Update'} onClick={handleUpdate} />
            ) : (
              <FilledButton text={'Submit'} onClick={handleSubmit} isdisable={showSubBtn} />
            )
          ) : null}
        </Box>
      </Box>
      <Modal open={open} onClose={() => setOpen(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={Modalstyle}>
          <CloseRoundedIcon sx={{ float: 'right' }} onClick={() => setOpen(false)} />
          <Box width={'100%'}>
            <Typography mt={5} style={{ textAlign: 'justify', fontFamily: 'poppins-regular' }}>
              You are about to change the status of the Supplier. You are suggested to check the status of all related user logins after
              this action. <br />
              Do you want to proceed?
            </Typography>
            <Box
              width={'18.8vw'}
              sx={{
                margin: 'auto',
                marginTop: '35px',
                marginBottom: '10px',
                display: 'flex',
                gap: '25px',
              }}
            >
              <FilledButton text={loading ? <CircularProgress size={24} /> : 'Yes'} disabled={loading} onClick={updateStatus} />
              <OutlineButton text={'No'} onClick={() => setOpen(false)} />
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default SGeneralDetails;
