/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FileImport } from '../../../../theme/Images';
import * as XLSX from 'xlsx';
import Papa from 'papaparse';
import { FilledBtn } from '../../../../common-components';
import OutlineBtn from '../../../../common-components/buttons/OutlineBtn';
import { useNavigate, useParams } from 'react-router-dom';
import TopNav from '../../../Navigation/TopNavBar/TopNav';
import { Box, Typography, Grid, Link, Button, CircularProgress } from '@mui/material';
import Colors from '../../../../theme/colors/Color';
import { getFile, postFileData } from '../../../../services/APIService';
import { useDispatch } from 'react-redux';
import { showSnackbar } from '../../../../redux/features/snackbar/snackbarSlice';

const ImportProducts = () => {
  const [errorMsg, setErrorMsg] = useState('');
  const [fileName, setFileName] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [isSupplier, setIsSupplier] = useState(false);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [linkEnabled, setLinkEnabled] = useState(true);
  const userData = JSON.parse(localStorage.getItem('userDetails'));
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleUploadClick = async () => {
    if (!selectedFile) {
      console.error('No file selected');
      return;
    }

    setLinkEnabled(false);
    setLoading(true);

    const data = new FormData();
    data.append('importFile', selectedFile);
    let url = '/product/import';
    let token = userData?.token;
    if (id) {
      token = '';
      url = url + '/' + id;
    }

    try {
      const response = await postFileData(url, data, {}, token);
      if (response.status === 200) {
        setLoading(false);
        id
          ? navigate('/import-result', { state: { importResponse: response.data, id: id } })
          : navigate('/products-record', { state: { importResponse: response.data, id: id } });
      } else {
        console.error('Error on product import', response);
      }
    } catch (error) {
      console.log(error);
      if (error?.response?.status == 404) {
        setErrorMsg('Data not found');
      } else setErrorMsg('Eror on import product');
      console.error('Error on product import', error);
    } finally {
      setLoading(false);
      setLinkEnabled(true);
    }
  };

  useEffect(() => {
    if (id) setIsSupplier(true);
  }, [id, isSupplier]);

  const onDrop = (acceptedFiles, rejectedFiles) => {
    if (acceptedFiles.length > 0) {
      setErrorMsg('');
      setFileName(acceptedFiles[0].name);

      setSelectedFile(acceptedFiles[0]);

      const file = acceptedFiles[0];
      const reader = new FileReader();

      reader.onload = () => {
        if (file.name.endsWith('.xlsx')) {
          const workbook = XLSX.read(reader.result, { type: 'binary' });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const jsonData = XLSX.utils.sheet_to_json(sheet);
          console.log('XLSX Data:', jsonData);
        } else if (file.name.endsWith('.csv')) {
          Papa.parse(file, {
            complete: (result) => {
              console.log('CSV Data:', result.data);
            },
            header: true,
          });
        }
      };

      if (file.name.endsWith('.xlsx')) {
        reader.readAsBinaryString(file);
      } else if (file.name.endsWith('.csv')) {
        reader.readAsText(file);
      }
    }

    if (rejectedFiles.length > 0) {
      setErrorMsg('Only XLSX file allowed.');
      setFileName('');
      setSelectedFile(null);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
    },
    onDrop,
  });

  const handleBackClick = () => {
    navigate(-1);
  };

  const downloadSampleFile = async () => {
    try {
      const params = { responseType: 'blob' };
      if (!id)
        params['headers'] = {
          Authorization: `Bearer ${userData.token}`,
        };
      const response = await getFile(`/product/sampleImport/${id ? id : ''}`, params);
      if (response.status !== 200) {
        dispatch(showSnackbar({ message: 'Error in fetching sample import file', severity: 'error' }));
        return;
      }
      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'sample_template.xlsx';
      link.click();
      dispatch(showSnackbar({ message: 'Template downloaded successfully', severity: 'success' }));
    } catch (error) {
      console.error('Error downloading template:', error);
      dispatch(showSnackbar({ message: 'Error downloading template', severity: 'error' }));
    }
  };

  return (
    <Box>
      {isSupplier && <TopNav isSupplier={isSupplier} />}
      <Box sx={{ padding: 2, maxWidth: 600, margin: 'auto' }}>
        <Typography variant="h4" gutterBottom sx={{ textAlign: 'center', fontWeight: 'bold' }}>
          Import Product
        </Typography>
        <Box sx={{ padding: 2, border: '1px solid #ccc', borderRadius: 2 }}>
          <Box sx={{ marginBottom: 2 }}>
            <Typography variant="body1" sx={{ textAlign: 'right' }}>
              click to&nbsp;
              {linkEnabled ? (
                <Link href="#" underline="hover" sx={{ fontWeight: 'bold' }} onClick={downloadSampleFile}>
                  Download template
                </Link>
              ) : (
                <span style={{ fontWeight: 'bold', color: Colors.placeholder }}>Download template</span>
              )}
            </Typography>
          </Box>
          <Box
            sx={{
              padding: 2,
              border: '2px dashed #ccc',
              borderRadius: 2,
              textAlign: 'center',
              cursor: 'pointer',
            }}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <img src={FileImport} alt="FileImport" style={{ marginRight: 8 }} />
              <Typography variant="body1">{fileName ? <span>{fileName}</span> : <>upload or drop xlsx file here</>}</Typography>
            </Box>
          </Box>
          {errorMsg && (
            <Typography color="error" sx={{ marginTop: 2 }}>
              {errorMsg}
            </Typography>
          )}
        </Box>
        <Grid container spacing={2} sx={{ marginTop: 2, justifyContent: 'center', alignItems: 'center' }}>
          <Grid item>
            {loading ? (
              <Button
                variant="contained"
                sx={{
                  minWidth: 120,
                  position: 'relative',
                  color: Colors.white,
                  background: Colors.primary,
                  textTransform: 'none',
                  padding: '0px 45px',
                  height: '45px',
                  borderRadius: '28px',
                  fontSize: '18px',
                  fontFamily: 'poppins-regular',
                  lineHeight: '24px',
                  '&:hover': {
                    color: Colors.white,
                    background: Colors.primary,
                  },
                }}
              >
                <CircularProgress size={24} sx={{ color: '#70FACB', position: 'absolute', right: '12px' }} />
                <span>Upload in Progress</span>
              </Button>
            ) : (
              <FilledBtn text={'Upload'} isdisable={false} onClick={handleUploadClick} />
            )}
          </Grid>
          <Grid item>{!id && <OutlineBtn text={'Back'} isdisable={false} onClick={handleBackClick} />}</Grid>
        </Grid>
      </Box>
      <Box sx={{ marginBottom: 4 }}>
        <Typography variant="body1" sx={{ mx: 3, fontSize: 18 }}>
          <strong>Instructions for uploading Multiple (Bulk) Products:</strong>
        </Typography>
        <ol style={{ margin: 20 }}>
          <li>
            Click on the "Download template" option to get the template required for uploading multiple products. The template will be
            downloaded and saved to your computer.
          </li>
          <li>
            Open the template and fill in the required product details as per the headers. All fields are mandatory, so ensure values are
            entered for every field. For fields with dropdown values, do not type your own values—select only from the dropdown options.
          </li>
          <li>Save the file in the same format after entering the data.</li>
          <li>Click on "Upload or drop the file here" to select your completed file, or drag and drop it into the designated area.</li>
          <li>Once the file appears in the designated area, click on the "Upload" button.</li>
          <li>The system will validate the upload and display details of successful and failed records.</li>
          <li>
            For any failed records, review the 'Reason for failure,' correct the errors, and re-upload the file by repeating the same steps.
          </li>
        </ol>
      </Box>
    </Box>
  );
};

export default ImportProducts;
