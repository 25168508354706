import React, { useEffect, useState } from 'react';
import { Box, Button, Modal, Popover } from '@mui/material';
import { postData } from '../../../../../services/APIService';
import { useNavigate } from 'react-router-dom';
import { CustomDataGrid, Heading16Reg, Heading26Bold } from '../../../../../utils/component';
import PageHeader from '../../../../Page header/PageHeader';
import { useDispatch } from 'react-redux';
// import { clearFirmId } from '../../../../../redux/features/user management/firmSlice';
import { Edit } from '../../../../../theme/Images';
import Colors from '../../../../../theme/colors/Color';
import Pagination from '../../../../../utils/component/pagination/Pagination';
import { getStatusBgColor } from '../../../../../utils/constants/statusColor';
import { showSnackbar } from '../../../../../redux/features/snackbar/snackbarSlice';
import BosCustomerFilter from '../../filters/BosCustomerFilter';
import { clearIds } from '../../../../../redux/features/datagrid/datagridSlice';

let totalPages = 1;
const limit = 10;

const DeleteModalstyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '20vw',
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 40,
  p: 4,
};

const initialFilters = {
  firm_name: [],
  firm_type: [],
  firm_category: [],
  customer_type: [],
  kam_id: [],
  status: [],
};

const CustomerList = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [customerList, setCustomerList] = useState([]);
  const userData = JSON.parse(localStorage.getItem('userDetails'));
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState(initialFilters);
  const [deleteWarning, setDeleteWarning] = useState(false);
  const [selectedFirms, setSelectedFirms] = useState([]);
  const [keyword, setKeyword] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const columnsData = [
    {
      field: 'code',
      headerName: 'Customer Code',
      flex: 0.8,
      renderCell: (params) => {
        const { id } = params.row;
        const customerCode = params.value;
        const viewDetails = () => {
          navigate('/customer/add', { state: { firmId: id, mode: 'view' } });
        };
        return (
          <Box
            sx={{
              color: Colors.primary,
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            onClick={viewDetails}
          >
            {customerCode}
          </Box>
        );
      },
    },
    {
      field: 'firm_name',
      headerName: 'Firm Name',
      flex: 1.2,
    },
    { field: 'gst_no', headerName: 'GST', flex: 1.4 },
    {
      field: 'firm_type',
      headerName: 'Firm Type',
      flex: 0.47,
    },
    {
      field: 'firm_category',
      headerName: 'Category',
      flex: 1,
    },
    {
      field: 'credit_limit',
      headerName: 'Credit Limit',
      flex: 0.8,
    },
    {
      field: 'isExisting',
      headerName: 'Customer Type',
      flex: 0.9,
      valueGetter: (params) => (params ? 'Existing' : 'New'),
    },
    {
      field: 'kam',
      headerName: 'KAM',
      flex: 1,
      valueGetter: (params) => (params ? params.name : ''),
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.9,
      renderCell: (params) => {
        const status = params.value;
        return (
          <Box sx={{ height: '100%', display: 'flex', alignItems: 'center' }}>
            <Box
              sx={{
                background: getStatusBgColor(status),
                height: '25px',
                width: '80px',
                borderRadius: '15px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'white',
                padding: '1px 20px',
              }}
            >
              {status}
            </Box>
          </Box>
        );
      },
    },
    {
      // field: 'actions',
      headerName: 'Edit',
      width: 70,
      renderCell: (params) => {
        const { id } = params.row;
        const handleEdit = () => {
          navigate('/customer/add', { state: { firmId: id, mode: 'edit' } });
        };
        return (
          <img
            style={{ height: '25px', marginLeft: '10px', marginTop: '10px', cursor: 'pointer' }}
            src={Edit}
            alt="edit"
            onClick={handleEdit}
          />
        );
      },
    },
  ];

  useEffect(() => {
    // dispatch(clearFirmId());
    localStorage.removeItem('firmData');
    fetchCustomers(currentPage);
  }, [currentPage, filters, keyword]);
  useEffect(() => {
    dispatch(clearIds());
  }, []);
  const fetchCustomers = async (page) => {
    await postData('/firm/getAll', { type: 'CUSTOMER', page, limit, filters, keyword }, {}, userData.token)
      .then((res) => {
        // setTotalPages(res.data.data.totalPages);
        totalPages = res.data.data.totalPages;
        setCustomerList(res.data.data.firms);
      })
      .catch((error) => console.log(error));
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleCheckRow = (selectedRow) => {
    setSelectedFirms(selectedRow);
  };

  const handleDeleteFirm = async () => {
    try {
      setDeleteWarning(false);
      const res = await postData('/firm/bulkDelete', { firmIds: selectedFirms }, {}, userData.token);
      fetchCustomers(currentPage);
      dispatch(showSnackbar({ message: res.data.message, severity: 'success' }));
    } catch (error) {
      dispatch(showSnackbar({ message: 'Something went wrong', severity: 'error' }));
    }
    setSelectedFirms([]);
  };

  const onFilterClick = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const onAddClick = () => {
    navigate('/customer/add');
  };
  const onDeleteClick = () => {
    setDeleteWarning(true);
  };
  const open = Boolean(anchorEl);

  const handleSelectedFilters = (filter) => {
    /* const cleanedFilters = Object.fromEntries(
      Object.entries(filter).filter(([key, value]) => {
        if (key) return Array.isArray(value) ? value.length > 0 : value;
      })
    );
    setFilters(cleanedFilters); */
    setFilters(filter);
  };

  const handleClearFilter = () => {
    setFilters(initialFilters);
    fetchCustomers();
  };
  const handleSearch = (e) => {
    const keyword = e?.target.value.trim();
    setKeyword(keyword);
    setCurrentPage(1);
  };
  return (
    <Box>
      <Heading26Bold text="Customers List" />
      <PageHeader
        showFilter={true}
        showAdd={true}
        showDeleter={true}
        filterClick={onFilterClick}
        addClick={onAddClick}
        deleteClick={onDeleteClick}
        handleSearch={handleSearch}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: { marginTop: 5 },
        }}
      >
        <Box>
          <BosCustomerFilter filterData={filters} selectedFilters={handleSelectedFilters} />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'flex-end',
              position: 'relative',
              bottom: 10,
              right: 0,
              width: '100%',
              paddingRight: '30px',
            }}
          >
            <Button variant="contained" size="small" onClick={handleClearFilter}>
              Clear filter
            </Button>
          </Box>
        </Box>
      </Popover>
      <Modal
        open={deleteWarning}
        onClose={() => setDeleteWarning(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={DeleteModalstyle}>
          {selectedFirms.length ? (
            <>
              <Heading16Reg text="Do you want to Delete Customer Permanantly?" />
              <Box sx={{ display: 'flex', gap: 2, mt: 1 }}>
                <Button variant="outlined" sx={{ borderRadius: '20px', textTransform: 'none' }} onClick={() => setDeleteWarning(false)}>
                  Cancel
                </Button>
                <Button variant="contained" color="error" sx={{ borderRadius: '20px', textTransform: 'none' }} onClick={handleDeleteFirm}>
                  Delete
                </Button>
              </Box>
            </>
          ) : (
            <Heading16Reg text="Please select User first." />
          )}
        </Box>
      </Modal>
      <CustomDataGrid rowIdKey={'id'} columnsData={columnsData} rowsData={customerList} onSelectCheckbox={handleCheckRow} />
      <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
    </Box>
  );
};

export default CustomerList;
