import { Box, Button, Modal, Popover, Switch } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { CustomDataGrid, Heading16Reg, Heading26Bold } from '../../../../utils/component';
import PageHeader from '../../../Page header/PageHeader';
// import EmployeeListTable from './EmployeeListTable';
// import TableHeader from '../../../../utils/table headings/TableHeaders.json';
import { getData, postData } from '../../../../services/APIService';
import { useNavigate } from 'react-router-dom';
import BosEmpFilter from '../filters/BosEmpFilter';
// import Colors from '../../../../theme/colors/Color';
import { Edit } from '../../../../theme/Images';
import Pagination from '../../../../utils/component/pagination/Pagination';
import { useDispatch } from 'react-redux';
import { showSnackbar } from '../../../../redux/features/snackbar/snackbarSlice';
import { getStatusBgColor } from '../../../../utils/constants/statusColor';
import { clearIds } from '../../../../redux/features/datagrid/datagridSlice';

let totalPages = 1;
const limit = 10;

const Modalstyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '20vw',
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 40,
  p: 4,
};

const initialFilters = {
  role_id: [],
  department: [],
  customer_name: [],
  status: [],
};

const EmployeeList = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [employeeList, setEmployeeList] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [deleteWarning, setDeleteWarning] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [filters, setFilters] = useState(initialFilters);
  // const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const userData = JSON.parse(localStorage.getItem('userDetails'));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let label = { inputProps: { 'aria-label': 'Logout User' } };
  const columnsData = [
    { field: 'name', headerName: 'Name', flex: 0.7 },
    {
      field: 'role',
      headerName: 'Role',
      flex: 0.7,
      valueGetter: (params) => (params ? params.role_name : ''),
    },
    { field: 'email', headerName: 'Email Id(User Name)', flex: 1.2 },
    {
      field: 'password',
      headerName: 'Password',
      flex: 0.8,
      valueGetter: (params) => (params ? '********' : ''),
    },
    {
      field: 'assignedFirm',
      headerName: 'Customer Name',
      flex: 1.5,
      valueGetter: (params) => {
        if (params && params.length > 0) {
          return params.map((item) => item.firm_name).join(', ');
        }
        return '';
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.8,
      renderCell: (params) => {
        const status = params.value;
        return (
          <Box sx={{ height: '100%', display: 'flex', alignItems: 'center' }}>
            <Box
              sx={{
                background: getStatusBgColor(status),
                height: '25px',
                width: '80px',
                borderRadius: '15px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'white',
                padding: '1px 20px',
              }}
            >
              {status}
            </Box>
          </Box>
        );
      },
    },
    {
      field: 'session',
      headerName: 'Active Login',
      flex: 0.8,
      renderCell: (params) => {
        const hasToken = !!params.row?.session?.token;
        return (
          <Switch
            {...label}
            checked={hasToken}
            sx={{
              '& .MuiSwitch-thumb': {
                backgroundColor: hasToken ? 'green' : 'red',
              },
              '& .MuiSwitch-track': {
                backgroundColor: hasToken ? 'rgba(0, 255, 0, 0.5)' : 'rgba(255, 0, 0, 1)',
                opacity: '0.55 !important',
              },
            }}
            disabled={!hasToken}
            onChange={() => handleLogoutToggle(params)}
          />
        );
      },
    },
    {
      // field: 'actions',
      headerName: 'Edit',
      width: 70,
      renderCell: (params) => {
        const { user_id } = params.row;
        const handleEdit = () => {
          navigate(`/employee/edit/${user_id}`);
        };
        return (
          <img
            style={{ height: '25px', marginLeft: '10px', marginTop: '10px', cursor: 'pointer' }}
            src={Edit}
            alt="edit"
            onClick={handleEdit}
          />
        );
      },
    },
  ];

  useEffect(() => {
    fetchEmployee();
  }, [currentPage, keyword, filters]);
  useEffect(() => {
    dispatch(clearIds());
  }, []);
  const fetchEmployee = async () => {
    try {
      const res = await postData('/users/getAllUser', { type: 'EMPLOYEE', page: currentPage, limit, filters, keyword }, {}, userData.token);
      if (res?.data?.data?.error) {
        setCurrentPage(1);
        totalPages = 1;
      } else totalPages = res?.data?.data?.totalPages || 1;
      setEmployeeList(res?.data?.data?.users || []);
    } catch (error) {
      dispatch(showSnackbar({ message: error.message, severity: 'error' }));
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleCheckRow = (selectedRow) => {
    setSelectedUsers(selectedRow);
  };

  const handleDeleteUser = async () => {
    try {
      setDeleteWarning(false);
      const res = await postData('/users/bulkDelete', { userIds: selectedUsers }, {}, userData.token);
      fetchEmployee(currentPage);
      dispatch(showSnackbar({ message: res.data.message, severity: 'success' }));
    } catch (error) {
      dispatch(showSnackbar({ message: 'Something went wrong', severity: 'error' }));
    }
    setSelectedUsers([]);
  };

  // const handleFilter = async (filters) => {
  //   // try {
  //   //   setDeleteWarning(false);
  //   //   const res = await postData('/users/bulkDelete', { userIds: selectedUsers }, {}, userData.token);
  //   //   fetchEmployee(currentPage);
  //   //   dispatch(showSnackbar({ message: res.data.message, severity: 'success' }));
  //   // } catch (error) {
  //   //   dispatch(showSnackbar({ message: 'Something went wrong', severity: 'error' }));
  //   // }
  //   // setSelectedUsers([]);
  // };

  const onFilterClick = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const onAddClick = () => {
    navigate('/employee/add');
  };

  const handleSearch = (e) => {
    const keyword = e?.target.value.trim();
    setKeyword(keyword);
    setCurrentPage(1);
  };
  const logout = async (user_id) => {
    try {
      const res = await getData(`/auth/logout/${user_id}`, {}, userData.token);
      if (res?.data?.status) {
        dispatch(showSnackbar({ message: 'User logout successfully', severity: 'success' }));
        fetchEmployee({});
      } else {
        dispatch(showSnackbar({ message: res.response.data.message, severity: 'error' }));
      }
    } catch (error) {
      console.log('error: ', error);
      const errorMessage = error.response?.data?.message ? error.response?.data?.message : error.message;
      dispatch(showSnackbar({ message: errorMessage, severity: 'error' }));
    }
  };

  const handleLogoutToggle = (params) => {
    const hasToken = !!params.row?.session?.token;

    if (hasToken) {
      const confirmLogout = window.confirm('Are you sure you want to logout this user from the device?');
      if (confirmLogout) {
        logout(params.row.user_id);
      }
    }
  };

  const handleClearFilter = () => {
    setFilters(initialFilters);
    fetchEmployee();
    setCurrentPage(1);
  };

  const open = Boolean(anchorEl);
  return (
    <Box>
      <Heading26Bold text="BOS Employee" />
      <PageHeader
        showFilter={true}
        showAdd={true}
        showDeleter={true}
        filterClick={onFilterClick}
        addClick={onAddClick}
        deleteClick={() => setDeleteWarning(true)}
        handleSearch={handleSearch}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: { marginTop: 5 },
        }}
      >
        <Box>
          <BosEmpFilter filterData={filters} selectedFilters={(f) => setFilters(f)} />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'flex-end',
              position: 'relative',
              bottom: 10,
              right: 0,
              width: '100%',
              paddingRight: '30px',
            }}
          >
            <Button variant="contained" size="small" onClick={handleClearFilter}>
              Clear filter
            </Button>
          </Box>
        </Box>
      </Popover>
      <Modal
        open={deleteWarning}
        onClose={() => setDeleteWarning(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={Modalstyle}>
          {selectedUsers.length ? (
            <>
              <Heading16Reg text="Do you want to Delete Users Permanantly?" />
              <Box sx={{ display: 'flex', gap: 2, mt: 1 }}>
                <Button variant="outlined" sx={{ borderRadius: '20px', textTransform: 'none' }} onClick={() => setDeleteWarning(false)}>
                  Cancel
                </Button>
                <Button variant="contained" color="error" sx={{ borderRadius: '20px', textTransform: 'none' }} onClick={handleDeleteUser}>
                  Delete
                </Button>
              </Box>
            </>
          ) : (
            <Heading16Reg text="Please select User first." />
          )}
        </Box>
      </Modal>
      <CustomDataGrid rowIdKey={'user_id'} columnsData={columnsData} rowsData={employeeList} onSelectCheckbox={handleCheckRow} />
      <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
    </Box>
  );
};

export default EmployeeList;
