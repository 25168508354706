import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Modal,
  RadioGroup,
  Typography,
  styled,
} from '@mui/material';
import { CustomRadio, FilledBtn, Heading16Reg, Heading26Bold, OutlineBtn } from '../../../../common-components';
import Colors from '../../../../theme/colors/Color';
// import SkyBlueSelect from '../../../../common-components/select/SkyBlueSelect'
// import SkyBlueInput from '../../../../common-components/inputbox/SkyBlueInput'
import FloatingLabelSelect from '../../../../common-components/select/FloatingLabelSelect';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';
import { NumList } from '../../../../utils/constants/dropdownList';
import { Add, successIcon, confirmationIcon } from '../../../../theme/Images';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useDispatch, useSelector } from 'react-redux';
import { getData, postData } from '../../../../services/APIService.js';
import FloatingLabelBox from '../../../../common-components/inputbox/FloatingLabelBox.jsx';
import Combobox from '../../../../utils/component/combo-box/Combobox.jsx';
import { showSnackbar } from '../../../../redux/features/snackbar/snackbarSlice.js';
import { InputTextField } from '../../../../utils/component/index.js';

const Heading16semi = styled(Typography)(() => ({
  fontSize: '16px',
  fontFamily: 'poppins-semibold',
  lineHeight: '24px',
}));

const ManageItemBox = styled(Box)(() => ({
  height: '70px',
}));

const SizeInputBox = {
  height: '30px',
  width: '100px',
  background: Colors.select_bg,
  border: '0.5px solid',
  borderColor: Colors.placeholder,
  marginTop: '4px',
};

const Modalstyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '35vw',
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 40,
  p: 2,
};

/* const selectList = [
  { value: 'Pune', label: 'Pune' },
  { value: 'Nashik', label: 'Nashik' },
  { value: 'Mumbai', label: 'Mumbai' },
]; */

const enquiryType = [{ label: 'Product Enquiry' }, { label: 'General' }];

const AddEnquiryForm = () => {
  const [selected, setSelected] = useState({});
  const [isNumQty, setIsNumQty] = useState(false);
  const [open, setOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const [selectedShape, setSelectedShape] = useState('Round');
  const [subCategorylist, setSubCategorylist] = useState([]);
  const [sizelist, setSizelist] = useState([]);
  const [firmlist, setFirmList] = useState([]);
  const [customerlist, setCustomerList] = useState([]);
  const [addresslist, setAddressList] = useState([]);
  const [selectedFirm, setSelectedFirm] = useState({});
  const [selectedCustomer, setSelectedCustomer] = useState({});

  const [kamlist, setKamList] = useState([]);
  // const [locationlist, setLocationList] = useState([])
  const [plsMentionList, setPlsMentionList] = useState([]);
  const [convertedQty, setConvertedQty] = useState(null);
  const [addEnquiryRes, setAddEnquiryRes] = useState([]);
  const [qtyCalculation, setQtyCalculation] = useState({
    number: '',
    convertedVal: '',
    shape: '',
    diameter: '',
    side: '',
    side1: '',
    side2: '',
    length: '',
  });
  const [formData, setFormData] = useState({});
  const [isDisabled, setIsDisabled] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const PM_Masters = useSelector((state) => state.PM_Masters);
  useEffect(() => {
    const isProductEnquiry = formData.enquiry_type === 'Product Enquiry';
    const isGeneralEnquiry = formData.enquiry_type === 'General';

    if (
      formData.firm_id &&
      formData.user_id &&
      formData.enquiry_type &&
      ((isProductEnquiry &&
        formData.pc_id &&
        formData.spc_id &&
        formData.grade_id &&
        formData.size_id &&
        formData.pr_id &&
        (formData.quantity || formData.selected_shape_size)) ||
        (isGeneralEnquiry && formData.enquiry_details && formData.enquiry_details !== ''))
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [formData]);

  const userData = JSON.parse(localStorage.getItem('userDetails'));

  const currentDate = new Date().toLocaleDateString('en-GB', {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  });

  useEffect(() => {
    featchAllFirms();
    featchKams();
  }, []);

  const featchAllFirms = async () => {
    await getData('/firm/all', {}, userData.token)
      .then((res) => {
        setFirmList(res.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const featchKams = async () => {
    await getData('/users/getKams', { role: 'KAM' }, userData.token)
      .then((res) => {
        setKamList(res.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // const selectedvalue = (item) => {
  //   console.log('selected item', item)
  //   // if (item.grade_id) {
  //   //   setFormData({ ...formData, grade_name: item.grade_name })
  //   //   setPlsMentionList()
  //   // } else if (item.size_id) {
  //   //   setFormData({ ...formData, size_name: item.size_name })
  //   // } else if (item.pr_id) {
  //   //   setFormData({ ...formData, pr_name: item.pr_name })
  //   // }
  // }

  const selectGrade = (item) => {
    setSelected((prev) => ({ ...prev, grade_name: item?.grade_name, other_grade_name: null }));
    setFormData((prev) => ({ ...prev, grade_id: item?.grade_id }));
    if (item?.grade_name === 'Other') {
      setPlsMentionList([...plsMentionList, 'otherGrade']);
    } else {
      const updateList = plsMentionList.filter((item) => item !== 'otherGrade');
      setPlsMentionList(updateList);
    }
  };

  const selectSize = (item) => {
    setSelected((prev) => ({ ...prev, size_name: item?.size_name, other_size_name: null }));
    setFormData((prev) => ({ ...prev, size_id: item?.size_id }));
    if (item?.size_name === 'Other') {
      setPlsMentionList([...plsMentionList, 'otherSize']);
    } else {
      const updateList = plsMentionList.filter((item) => item !== 'otherSize');
      setPlsMentionList(updateList);
    }
  };

  const selectProcessRoute = (item) => {
    setSelected((prev) => ({ ...prev, pr_name: item?.pr_name, other_pr_name: null }));
    setFormData((prev) => ({ ...prev, pr_id: item?.pr_id }));
    if (item?.pr_name === 'Other') {
      setPlsMentionList([...plsMentionList, 'otherProcessRoute']);
    } else {
      const updateList = plsMentionList.filter((item) => item !== 'otherProcessRoute');
      setPlsMentionList(updateList);
    }
  };

  const handleTextChange = (e) => {
    if (e.target.name === 'otherGrade') {
      setFormData((prev) => ({ ...prev, grade_id: e.target.value }));
      setSelected((prev) => ({ ...prev, other_grade_name: e.target.value }));
    } else if (e.target.name === 'otherSize') {
      setFormData((prev) => ({ ...prev, size_id: e.target.value }));
      setSelected((prev) => ({ ...prev, other_size_name: e.target.value }));
    } else if (e.target.name === 'otherProcessRoute') {
      setFormData((prev) => ({ ...prev, pr_id: e.target.value }));
      setSelected((prev) => ({ ...prev, other_pr_name: e.target.value }));
    }
  };

  const handleCategory = async (category) => {
    setSelected((prev) => ({ ...prev, pc_name: category?.category_name, spc_name: null, size_name: null, other_size_name: null }));
    setFormData((prev) => ({ ...prev, pc_id: category?.pc_id, spc_id: null, size_id: null }));
    if (category?.pc_id) {
      const res = await getData('/mstSubProductCategories/getByCategoryId', { product_category_id: category?.pc_id }, userData.token);
      setSubCategorylist(res.data.data);
    }
    setPlsMentionList((i) => i.filter((a) => a !== 'otherSize'));
  };

  const handleKam = async (e) => {
    setSelected((prev) => ({ ...prev, kam_name: e?.name }));
    setFormData((prev) => ({ ...prev, kam_id: e?.user_id }));
  };

  const handleSubCategory = async (category) => {
    setSelected((prev) => ({ ...prev, spc_name: category?.sub_category_name, size_name: null, other_size_name: null }));
    setFormData((prev) => ({ ...prev, spc_id: category?.spc_id, size_id: null }));
    const res = await getData('/mstSizes/getAllmstSizesv1', { spc_id: category?.spc_id }, userData.token);
    if (res.data.status) setSizelist(res.data.data);
    setPlsMentionList((i) => i.filter((a) => a !== 'otherSize'));
  };

  const handalFirmSelect = async (firm) => {
    if (firm?.id) {
      setFormData((prev) => ({ ...prev, firm_id: firm.id, user_id: null }));
      setSelected((prev) => ({ ...prev, delivery_location: null }));
      setSelectedFirm(firm);
      setSelectedCustomer({});
      await getData(`/users/byfirm/${firm.id}`, {}, userData.token)
        .then((res) => {
          setCustomerList(res.data.data);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const handleCustomerSelect = async (customer) => {
    if (customer?.user_id) {
      setAddressList([]);
      setSelectedCustomer(customer);
      setFormData((prev) => ({ ...prev, user_id: customer.user_id }));
      setSelected((prev) => ({ ...prev, delivery_location: null }));
      try {
        const res = await getData(`/address/getAddressByUserId/${customer.user_id}`, {}, userData.token);
        let d = res.data.data;
        if (d) {
          const addr = d.map((d) => ({
            id: d.address_id,
            label: d.address_line_1 + ', ' + d.address_line_2 + ', ' + d.city + ', ' + d.state,
          }));
          setAddressList(addr);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleShapeSelect = (e) => {
    setSelectedShape(e.target.value);
    setQtyCalculation((prev) => ({ ...prev, shape: e.target.value }));
    if (e.target.value === 'Round') {
      setQtyCalculation((prev) => ({ ...prev, side: '', side1: '', side2: '' }));
    } else if (e.target.value === 'Square') {
      setQtyCalculation((prev) => ({ ...prev, diameter: '', side1: '', side2: '' }));
    } else if (e.target.value === 'Rectangle') {
      setQtyCalculation((prev) => ({ ...prev, side: '', diameter: '' }));
    }
  };

  const handleAddressSelect = (e) => {
    setSelected((prev) => ({ ...prev, delivery_location: e }));
    setFormData((prev) => ({ ...prev, delivery_location: e?.id }));
  };

  const calculate = () => {
    const val = 0.00000000616;
    let convertedVal;
    if (qtyCalculation.shape === 'Round') {
      const value = 0.00000000616;
      convertedVal = value * qtyCalculation.diameter * qtyCalculation.diameter * qtyCalculation.length * qtyCalculation.number;
      setConvertedQty(convertedVal);
    } else if (qtyCalculation.shape === 'Square') {
      convertedVal = val * qtyCalculation.side * qtyCalculation.side * qtyCalculation.length * qtyCalculation.number;
      setConvertedQty(convertedVal);
    } else if (qtyCalculation.shape === 'Rectangle') {
      convertedVal = val * qtyCalculation.side1 * qtyCalculation.side2 * qtyCalculation.length * qtyCalculation.number;
      setConvertedQty(convertedVal);
    }
    setQtyCalculation((prev) => ({ ...prev, convertedVal: convertedVal }));
    setFormData((prev) => ({ ...prev, selected_shape_size: { ...qtyCalculation } }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setAddEnquiryRes({});
    setIsError(false);
    // console.log('Form Data ', formData);
    try {
      const res = await postData(`/enquiry/add`, formData, {}, userData.token);
      if (res && res.data && res.data.status) {
        dispatch(showSnackbar({ message: 'Product added successfully', severity: 'success' }));
        setAddEnquiryRes(res.data.data);
        setOpen(true);
      } else dispatch(showSnackbar({ message: '"Something went wrong"', severity: 'error' }));
    } catch (error) {
      console.log(error);
      if (error.response && error.response.data) dispatch(showSnackbar({ message: error.response.data.message, severity: 'error' }));
      else dispatch(showSnackbar({ message: 'Something went wrong...!', severity: 'error' }));
    }

    // try {
    //   console.log(postData);
    //   setAddEnquiryRes({});
    //   // await postData('/enquiry/add', formData, {}, userData.token)
    //   //   .then((res) => {
    //   //     setAddEnquiryRes(res.data.data);
    //   //   })f
    //   //   .catch((error) => {
    //   //     console.error(error);
    //   //   });
    //   setIsError(false);
    // } catch (error) {
    //   setIsError(true);
    // }
    // setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
    navigate(-1);
  };

  return (
    <Box>
      <Heading26Bold text={'Enquiry Form'} />
      <Box display={'flex'} justifyContent={'space-between'} alignItems={{ xs: 'start', lgm: 'center' }} mt={'40px'}>
        <Box sx={{ width: '60%', display: { xs: 'bloack', lgm: 'flex' }, gap: '30px' }}>
          <Box sx={{ background: '#D4FEEF', width: { xs: '100%', lgm: '50%' } }}>
            <Combobox
              label={'Firm Name'}
              optionsLabel={'firm_name'}
              options={firmlist}
              isDisable={false}
              isRequire={false}
              value={selectedFirm.firm_name}
              onChangeValue={handalFirmSelect}
            />
          </Box>
          <Box sx={{ background: '#D4FEEF', width: { xs: '100%', lgm: '50%' }, marginTop: { xs: '30px', lgm: '0px' } }}>
            <Combobox
              label={'Customer Name'}
              optionsLabel={'name'}
              options={customerlist}
              isDisable={!formData.firm_id}
              isRequire={false}
              value={selectedCustomer.name}
              onChangeValue={handleCustomerSelect}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            height: '40px',
            borderRadius: '10px',
            background: '#D4FEEF',
            padding: '5px 15px',
            marginTop: '-10px',
          }}
        >
          <img src={Add} alt="filter" style={{ height: '20px', width: '20px' }} />
          <Typography sx={{ fontFamily: 'poppins-regular', fontSize: '14px' }}>Add Customer</Typography>
        </Box>
      </Box>
      <Box display={'flex'} gap={'5px'} mt={'30px'}>
        <Heading26Bold text={'Enquiry No:'} />
        <Typography></Typography>
      </Box>
      <Typography sx={{ fontFamily: 'poppins-regular', fontSize: '14px', marginTop: '5px' }}>Date OF Enquiry: {currentDate}</Typography>
      <Grid container sx={{ marginTop: '4px', height: { xs: '180px', sm: '90px' }, background: Colors.bg_form_section, padding: '12px' }}>
        <Grid item xs={12} sm={6} lgm={4} display={'flex'}>
          <Heading16semi sx={{ marginRight: '5px' }}>Name Of Firm:</Heading16semi>
          <Heading16Reg text={selectedFirm.firm_name || ''} />
        </Grid>
        <Grid item xs={12} sm={6} lgm={4} display={'flex'}>
          <Heading16semi sx={{ marginRight: '5px' }}>Email:</Heading16semi>
          <Heading16Reg text={selectedCustomer.email || ''} />
        </Grid>
        <Grid item xs={12} sm={6} lgm={4} display={'flex'}>
          <Heading16semi sx={{ marginRight: '5px' }}>GST No:</Heading16semi>
          <Heading16Reg text={selectedFirm.gst_no || ''} />
        </Grid>
        <Grid item xs={12} sm={6} lgm={4} display={'flex'}>
          <Heading16semi sx={{ marginRight: '5px' }}>Customer Name:</Heading16semi>
          <Heading16Reg text={selectedCustomer.name || ''} />
        </Grid>
        <Grid item xs={12} sm={6} lgm={4} display={'flex'}>
          <Heading16semi sx={{ marginRight: '5px' }}>Contact No:</Heading16semi>
          <Heading16Reg text={selectedCustomer.mobile_number || ''} />
        </Grid>
      </Grid>
      <Grid container mt={'30px'}>
        <Grid item xs={12} lgm={6} paddingRight={{ xs: 0, md: '5px' }}>
          <ManageItemBox>
            <Combobox
              label={'Type Of Enquiry'}
              optionsLabel={'label'}
              options={enquiryType}
              isDisable={false}
              isRequire={false}
              value={formData.enquiry_type}
              onChangeValue={(eType) => setFormData((prev) => ({ ...prev, enquiry_type: eType?.label }))}
            />
          </ManageItemBox>
        </Grid>
        <Grid item xs={12} lgm={6} paddingLeft={{ xs: 0, md: '5px' }}>
          <ManageItemBox>
            <Combobox
              label={'KAM'}
              optionsLabel={'name'}
              options={kamlist}
              isDisable={false}
              isRequire={false}
              value={selected.kam_name}
              // onChangeValue={(kam) => setFormData((prev) => ({ ...prev, kam_name: kam.name }))}
              onChangeValue={handleKam}
            />
          </ManageItemBox>
        </Grid>
      </Grid>

      {formData.enquiry_type !== 'General' && (
        <Grid container mt={'20px'}>
          <Grid item xs={12} lgm={6} paddingRight={{ xs: 0, md: '5px' }}>
            <ManageItemBox>
              <Combobox
                label={'Product Category'}
                optionsLabel={'category_name'}
                options={PM_Masters.productCategories}
                isDisable={false}
                isRequire={true}
                value={selected.pc_name}
                onChangeValue={handleCategory}
              />
            </ManageItemBox>
          </Grid>
          <Grid item xs={12} lgm={6} paddingLeft={{ xs: 0, md: '5px' }}>
            <ManageItemBox>
              <Combobox
                label={'Sub Product Category'}
                optionsLabel={'sub_category_name'}
                options={subCategorylist}
                isDisable={!formData.pc_id}
                isRequire={true}
                value={selected.spc_name}
                onChangeValue={handleSubCategory}
              />
            </ManageItemBox>
          </Grid>
        </Grid>
      )}
      {formData.enquiry_type == 'General' && (
        <Grid item mt={'20px'} xs={12} lgm={12} paddingLeft={{ xs: 0, md: '5px' }}>
          <InputTextField
            label={'Enquiry Details'}
            name={'enquiry_details'}
            value={formData.enquiry_details}
            helperText={''}
            placeholder={'Write Your Enquiry Details Here'}
            isDisable={false}
            bgColor={Colors.select_bg}
            onChange={(e) => setFormData((prev) => ({ ...prev, enquiry_details: e.target.value }))}
          />
        </Grid>
      )}
      {formData.enquiry_type !== 'General' && (
        <Box sx={{ background: Colors.bg_form_section, marginTop: '10px', padding: '10px' }}>
          <Typography sx={{ fontFamily: 'poppins-regular', fontSize: '20px', fontWeight: 600 }}>Product Details</Typography>
          <Grid container marginTop={'15px'}>
            <Grid item xs={12} lgm={6} paddingRight={{ xs: 0, md: '5px' }}>
              <ManageItemBox>
                <Combobox
                  label={'Grade'}
                  optionsLabel={'grade_name'}
                  options={[{ grade_name: 'Other', id: 'other' }, ...PM_Masters.grades]}
                  isDisable={false}
                  isRequire={true}
                  value={selected.grade_name}
                  onChangeValue={selectGrade}
                  bgColor={Colors.select_bg}
                />
              </ManageItemBox>
              <ManageItemBox>
                <Combobox
                  label={'Size'}
                  optionsLabel={'size_name'}
                  options={[{ size_name: 'Other', id: 'other' }, ...sizelist]}
                  isDisable={!formData.spc_id}
                  isRequire={true}
                  value={selected.size_name}
                  onChangeValue={selectSize}
                  bgColor={Colors.select_bg}
                />
              </ManageItemBox>
              <ManageItemBox>
                <Combobox
                  label={'Process Route'}
                  optionsLabel={'pr_name'}
                  options={[{ pr_name: 'Other', id: 'other' }, ...PM_Masters.processRoutes]}
                  isDisable={false}
                  isRequire={true}
                  value={selected.pr_name}
                  onChangeValue={selectProcessRoute}
                  bgColor={Colors.select_bg}
                />
              </ManageItemBox>
              <ManageItemBox sx={{ display: 'flex', gap: '5px' }}>
                <Box width={'50%'}>
                  <FloatingLabelSelect
                    label={'Quantity'}
                    placeholder={''}
                    options={[
                      { value: 'Metric Ton', label: 'Metric Ton' },
                      { value: 'Number', label: 'Number' },
                    ]}
                    isDisable={false}
                    bgColor={Colors.select_bg}
                    isRequire={true}
                    selected={(selectVal) => {
                      if (selectVal.label === 'Number') {
                        setIsNumQty(true);
                        setFormData((prev) => ({ ...prev, quantity: null }));
                      } else {
                        setIsNumQty(false);
                        setQtyCalculation((prev) => ({ ...prev, number: '' }));
                      }
                    }}
                  />
                </Box>
                <Box width={'50%'}>
                  {isNumQty ? (
                    <FloatingLabelBox
                      label={''}
                      name={''}
                      value={qtyCalculation.number}
                      helperText={''}
                      placeholder={'Enter Number'}
                      isDisable={false}
                      bgColor={Colors.select_bg}
                      type={'number'}
                      onChange={(e) => setQtyCalculation((prev) => ({ ...prev, number: e.target.value }))}
                    />
                  ) : (
                    <FloatingLabelSelect
                      label={''}
                      placeholder={''}
                      options={NumList}
                      isDisable={false}
                      bgColor={Colors.select_bg}
                      selected={(qty) => setFormData((prev) => ({ ...prev, quantity: qty.label }))}
                    />
                  )}
                </Box>
              </ManageItemBox>
            </Grid>
            <Grid item xs={12} lgm={6} paddingLeft={{ xs: 0, md: '5px' }}>
              <ManageItemBox>
                <FloatingLabelBox
                  label={'Please Mention'}
                  name={'otherGrade'}
                  value={selected.other_grade_name}
                  helperText={''}
                  placeholder={'Grade'}
                  bgColor={Colors.select_bg}
                  isDisable={!plsMentionList.includes('otherGrade')}
                  onChange={handleTextChange}
                />
              </ManageItemBox>
              <ManageItemBox>
                <FloatingLabelBox
                  label={'Please Mention'}
                  name={'otherSize'}
                  value={selected.other_size_name}
                  helperText={''}
                  placeholder={'Size'}
                  bgColor={Colors.select_bg}
                  isDisable={!plsMentionList.includes('otherSize')}
                  onChange={handleTextChange}
                />
              </ManageItemBox>
              <ManageItemBox>
                <FloatingLabelBox
                  label={'Please Mention'}
                  name={'otherProcessRoute'}
                  value={selected.other_pr_name}
                  helperText={''}
                  placeholder={'Supply Condition'}
                  bgColor={Colors.select_bg}
                  isDisable={!plsMentionList.includes('otherProcessRoute')}
                  onChange={handleTextChange}
                />
              </ManageItemBox>
            </Grid>
          </Grid>
          {isNumQty && qtyCalculation.number && (
            <Box>
              <Typography sx={{ fontFamily: 'poppins-regular', fontSize: '12px', color: Colors.error }}>
                Converted value: {convertedQty || '__'} MT
              </Typography>
              <Accordion sx={{ background: Colors.bg_form_section, boxShadow: 'none', marginTop: '10px' }} defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2-content" id="panel2-header">
                  <Typography sx={{ fontFamily: 'poppins-regular', fontSize: '14px', marginLeft: '-15px' }}>
                    For converting Numbers to metric tons please provide below details
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box sx={{ background: '#90d1e5', marginLeft: '-15px', padding: '12px' }}>
                    <Grid container>
                      <Grid item xs={12} lgm={4}>
                        <FormControl>
                          <FormLabel>
                            <Typography sx={{ fontFamily: 'poppins-regular', fontSize: '16px', color: Colors.text_black }}>
                              Select Shape
                            </Typography>
                          </FormLabel>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            onChange={handleShapeSelect}
                          >
                            <FormControlLabel
                              value="Round"
                              control={<CustomRadio />}
                              label="Round"
                              sx={{
                                '& .MuiFormControlLabel-label': {
                                  fontFamily: 'poppins-regular',
                                },
                              }}
                            />
                            <FormControlLabel
                              value="Square"
                              control={<CustomRadio />}
                              label="Square"
                              sx={{
                                '& .MuiFormControlLabel-label': {
                                  fontFamily: 'poppins-regular',
                                },
                              }}
                            />
                            <FormControlLabel
                              value="Rectangle"
                              control={<CustomRadio />}
                              label="Rectangle"
                              sx={{
                                '& .MuiFormControlLabel-label': {
                                  fontFamily: 'poppins-regular',
                                },
                              }}
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} lgm={4} display={'flex'} alignItems={'center'} gap={'20px'}>
                        {selectedShape === 'Round' && (
                          <Box>
                            <Typography sx={{ fontFamily: 'poppins-regular', fontSize: '12px' }}>Diameter (mm)</Typography>
                            <input
                              type="number"
                              style={SizeInputBox}
                              onChange={(e) => setQtyCalculation((prev) => ({ ...prev, diameter: e.target.value }))}
                            />
                          </Box>
                        )}
                        {selectedShape === 'Square' && (
                          <Box>
                            <Typography sx={{ fontFamily: 'poppins-regular', fontSize: '12px' }}>Side (mm)</Typography>
                            <input
                              type="number"
                              style={SizeInputBox}
                              onChange={(e) => setQtyCalculation({ ...qtyCalculation, side: e.target.value })}
                            />
                          </Box>
                        )}
                        {selectedShape === 'Rectangle' && (
                          <>
                            <Box>
                              <Typography sx={{ fontFamily: 'poppins-regular', fontSize: '12px' }}>Side 1 (mm)</Typography>
                              <input
                                style={SizeInputBox}
                                type="number"
                                onChange={(e) => setQtyCalculation({ ...qtyCalculation, side1: e.target.value })}
                              />
                            </Box>
                            <Box>
                              <Typography sx={{ fontFamily: 'poppins-regular', fontSize: '12px' }}>Side 2 (mm)</Typography>
                              <input
                                style={SizeInputBox}
                                type="number"
                                onChange={(e) => setQtyCalculation({ ...qtyCalculation, side2: e.target.value })}
                              />
                            </Box>
                          </>
                        )}
                        <Box>
                          <Typography sx={{ fontFamily: 'poppins-regular', fontSize: '12px' }}>Length (mm)</Typography>
                          <input
                            style={SizeInputBox}
                            type="number"
                            onChange={(e) => setQtyCalculation((prev) => ({ ...prev, length: e.target.value }))}
                          />
                        </Box>
                        <Typography
                          sx={{
                            fontFamily: 'poppins-regular',
                            fontSize: '12px',
                            textDecoration: 'underline',
                            color: Colors.primary,
                            cursor: 'pointer',
                            marginLeft: '10px',
                            marginTop: '15px',
                          }}
                          onClick={calculate}
                        >
                          Calculate
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>
          )}
          <Box mt={'20px'}>
            <Combobox
              label={'Delivery Location Of Product'}
              optionsLabel={'label'}
              options={addresslist}
              isDisable={!formData.user_id}
              isRequire={false}
              value={selected.delivery_location?.label}
              onChangeValue={handleAddressSelect}
            />
          </Box>
        </Box>
      )}
      <Box
        sx={{
          marginTop: '30px',
          display: 'flex',
          gap: '25px',
          justifyContent: 'center',
        }}
      >
        <FilledBtn isdisable={isDisabled} text={'Submit'} onClick={handleSubmit} />
        <OutlineBtn text={'Cancel'} onClick={() => navigate(-1)} />
      </Box>
      <Modal open={open} onClose={handleModalClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={Modalstyle}>
          <CloseRoundedIcon sx={{ float: 'right', cursor: 'pointer' }} onClick={handleModalClose} />
          <Box
            sx={{
              marginTop: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {isError ? (
              <>
                <img style={{ height: '70px' }} src={confirmationIcon} alt="confirmationIcon" />
                <Typography sx={{ color: Colors.error, marginTop: '5px', fontFamily: 'poppins-regular' }}>ERROR</Typography>
                <Box width={'65%'} textAlign={'center'} mt={'20px'} mb={'25px'}>
                  <Typography sx={{ fontFamily: 'poppins-regular', fontSize: '16px' }}>
                    Enquiry 16/fy2023-24 for this product already exists. You can add a new enquiry or manage the status of this existing
                    enquiry.
                  </Typography>
                </Box>
              </>
            ) : (
              <>
                <img style={{ height: '70px' }} src={successIcon} alt="successIcon" />
                <Typography sx={{ color: Colors.success, marginTop: '5px', fontFamily: 'poppins-regular' }}>Success</Typography>
                <Box width={'60%'} textAlign={'center'} mt={'20px'} mb={'15px'}>
                  <Typography sx={{ fontFamily: 'poppins-regular', fontSize: '16px', color: Colors.text_black }}>
                    Your enquiry is submitted successfully.
                  </Typography>
                  <Typography sx={{ fontFamily: 'poppins-regular', marginTop: 1, fontSize: '12px', color: Colors.text_413e3e }}>
                    Date of enquiry : {addEnquiryRes.enquiry_date}
                  </Typography>
                  <Typography sx={{ fontFamily: 'poppins-regular', fontSize: '12px', color: Colors.text_413e3e }}>
                    Time: {addEnquiryRes.enquiry_time}
                  </Typography>
                  <Typography sx={{ fontFamily: 'poppins-regular', marginTop: 2, fontSize: '16px', color: Colors.text_black }}>
                    Enquiry no: {addEnquiryRes.enquiry_number}
                  </Typography>
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default AddEnquiryForm;
