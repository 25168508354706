import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Grid, Typography, Box, Divider, Paper } from '@mui/material';
import 'react-datepicker/dist/react-datepicker.css';
import './Dashboard.css';
// import Select from 'react-select'
import { Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton } from '@mui/material';
import { ProgressCircle } from '../../theme/Images';
import SouthEastOutlinedIcon from '@mui/icons-material/SouthEastOutlined';
import CallMadeOutlinedIcon from '@mui/icons-material/CallMadeOutlined';
import Colors from '../../theme/colors/Color';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Heading24Semi } from '../../common-components';
import { Edit } from '../../../src/theme/Images';
import { getData } from '../../services/APIService';
const session = sessionStorage.getItem('session');

const Dashboard = () => {
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem('userDetails'));

  useEffect(() => {
    if (session !== 'isverify') checkSession(userData?.user?.user_id);
  }, []);
  const checkSession = async (id) => {
    try {
      const res = await getData(`/sessions/session/${id}`);
      let check;
      if (res.data.status && res.data.data?.token) {
        check = res.data.data?.token === userData?.token;
        if (!check) {
          navigate('/login');
        } else {
          sessionStorage.setItem('session', 'isverify');
        }
      } else {
        navigate('/login');
      }
    } catch (error) {
      console.log(error);
    }
  };
  const orders = [
    {
      companyName: 'Company name',
      orderDate: '02 February 2:30 PM',
      orderNo: 'Order No.',
      details: 'Order details',
      quantity: '100 pcs.',
      deliveryDate: '18 Feb 2024',
    },
    {
      companyName: 'Company name',
      orderDate: '02 February 2:30 PM',
      orderNo: 'Order No.',
      details: 'Order details',
      quantity: '200 pcs.',
      deliveryDate: '18 Feb 2024',
    },
    {
      companyName: 'Company name',
      orderDate: '02 February 2:30 PM',
      orderNo: 'Order No.',
      details: 'Order details',
      quantity: '200 pcs.',
      deliveryDate: '18 Feb 2024',
    },
  ];

  const enquiries = [
    {
      id: 1,
      enquiryNo: '16/FY2023-24',
      date: '15/02/2024',
      nature: 'CRR',
      kam: 'Adam Smith',
      firm: 'Mahindra',
      category: 'As Cast',
      grade: 'AISI 1018',
      size: 'AISI 1018',
      conditions: 'As Cast',
      quantity: 10,
      status: 'Open',
      statusColor: '#F4C7C9',
    },
    {
      id: 2,
      enquiryNo: '16/FY2023-24',
      date: '15/02/2024',
      nature: 'CRR-NBD',
      kam: 'Adam Smith',
      firm: 'Mahindra',
      category: 'As Cast',
      grade: 'AISI 1018',
      size: 'AISI 1018',
      conditions: 'As Cast',
      quantity: 10,
      status: 'Open',
      statusColor: '#FFFFFF',
    },
    {
      id: 3,
      enquiryNo: '16/FY2023-24',
      date: '15/02/2024',
      nature: 'NBD',
      kam: 'Adam Smith',
      firm: 'Mahindra',
      category: 'As Cast',
      grade: 'AISI 1018',
      size: 'AISI 1018',
      conditions: 'As Cast',
      quantity: 10,
      status: 'In Progress',
      statusColor: '#F3F4C7',
    },
    {
      id: 4,
      enquiryNo: '16/FY2023-24',
      date: '15/02/2024',
      nature: 'CRR',
      kam: 'Adam Smith',
      firm: 'Mahindra',
      category: 'As Cast',
      grade: 'AISI 1018',
      size: 'AISI 1018',
      conditions: 'As Cast',
      quantity: 10,
      status: 'Open',
      statusColor: '#FFFFFF',
    },
  ];

  const statusStyles = {
    Open: {
      whiteSpace: 'nowrap',
      backgroundColor: Colors.primary,
      color: '#fff',
      borderRadius: '50px',
      padding: '0.25rem 0.50rem',
      textAlign: 'center',
      cursor: 'pointer',
    },
    'In Progress': {
      backgroundColor: '#E8AB43',
      color: '#000',
      borderRadius: '50px',
      padding: '0.25rem 0.50rem',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      cursor: 'pointer',
    },
    Closed: {
      whiteSpace: 'nowrap',
      backgroundColor: '#D32F2F',
      color: '#fff',
      borderRadius: '50px',
      padding: '0.25rem 0.50rem',
      textAlign: 'center',
      cursor: 'pointer',
    },
  };
  return (
    <Box>
      <Typography variant="h5" component="h1" sx={{ flexGrow: 1, typography: { xs: 'h6', sm: 'h5' } }}>
        <Heading24Semi text={'Users'} />
      </Typography>
      <Grid container spacing={3} justifyContent="space-evenly" item sm={12} marginTop="0">
        {['Total users', 'New users last month', 'Active users', 'Inactive users'].map((item, index) => (
          <Grid key={index} item maxHeight={'100%'}>
            <Box className="gridItem" justifyContent="space-between" sx={{ height: '85%' }}>
              <Typography variant="body1">{item}</Typography>
              <Box className="contentWrapper">
                {index === 0 ? (
                  <>
                    <img src={ProgressCircle} alt="User Icon" className="circle-img" />
                    <Divider />
                    <Typography variant="h2" className="heading" marginTop="12px">
                      500
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography variant="h2" className="heading">
                      {item === 'Active users' ? 390 : item === 'Inactive users' ? 60 : '50'}
                    </Typography>
                    <Divider />
                  </>
                )}
                {index !== 0 && (
                  <Typography marginTop={5}>
                    <span className="icon">{index === 2 ? <CallMadeOutlinedIcon /> : <SouthEastOutlinedIcon />}</span>
                    2% vs last 30 days
                  </Typography>
                )}
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>

      <Box sx={{ marginTop: '50px' }}>
        <TableContainer component={Paper} elevation={5} sx={{ border: '1px solid', borderColor: Colors.form_lines, borderRadius: '15px' }}>
          <Grid item sx={{ marginBottom: '-70px', marginTop: '20px', padding: '10px' }}>
            <Typography variant="h5" component="h1" sx={{ flexGrow: 1, typography: { xs: 'h6', sm: 'h6' } }}>
              <Heading24Semi text={'Enquiries'} />
            </Typography>
          </Grid>
          <Grid padding={2} container spacing={2} sx={{ justifyContent: 'right' }}>
            <Grid item>
              <Box
                sx={{
                  backgroundColor: '#E8AB43',
                  padding: '0.2rem 0.8rem',
                  borderRadius: '50px',
                  color: Colors.white,
                }}
              >
                <Typography>
                  In Progress <span style={{ marginLeft: '10px' }}>10</span>
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <Box sx={{ backgroundColor: Colors.primary, color: '#fff', padding: '0.2rem 1.5rem ', borderRadius: '50px' }}>
                <Typography>
                  Open <span style={{ marginLeft: '15px' }}>8</span>
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <Box sx={{ backgroundColor: Colors.error, color: '#fff', padding: '0.2rem 1rem', borderRadius: '50px' }}>
                <Typography>
                  Closed <span style={{ marginLeft: '15px' }}>23</span>
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Table>
            <TableHead sx={{ backgroundColor: Colors.secondary1 }}>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox sx={{ color: Colors.white, fontWeight: 'bold' }} />
                </TableCell>
                <TableCell sx={{ color: Colors.white, fontWeight: 'bold' }}>Enquiry No</TableCell>
                <TableCell sx={{ color: Colors.white, fontWeight: 'bold' }}>Date of Enquiry</TableCell>
                <TableCell sx={{ color: Colors.white, fontWeight: 'bold' }}>Nature of Enquiry</TableCell>
                <TableCell sx={{ color: Colors.white, fontWeight: 'bold' }}>KAM</TableCell>
                <TableCell sx={{ color: Colors.white, fontWeight: 'bold' }}>Firm Name</TableCell>
                <TableCell sx={{ color: Colors.white, fontWeight: 'bold' }}>Product Category</TableCell>
                <TableCell sx={{ color: Colors.white, fontWeight: 'bold' }}>Grade</TableCell>
                <TableCell sx={{ color: Colors.white, fontWeight: 'bold' }}>Size</TableCell>
                <TableCell sx={{ color: Colors.white, fontWeight: 'bold' }}>Supply Conditions</TableCell>
                <TableCell sx={{ color: Colors.white, fontWeight: 'bold', textAlign: 'left', padding: '0px' }}>
                  Quantity (metricTon)
                </TableCell>
                <TableCell sx={{ color: Colors.white, fontWeight: 'bold' }}>Status</TableCell>
                <TableCell sx={{ color: Colors.white, fontWeight: 'bold' }}>Edit</TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ whiteSpace: 'nowrap' }}>
              {enquiries.map((enquiry) => (
                <TableRow
                  key={enquiry.id}
                  style={{ backgroundColor: enquiry.statusColor, borderBottom: '2px solid', borderColor: Colors.secondary1 }}
                >
                  <TableCell padding="checkbox">
                    <Checkbox />
                  </TableCell>
                  <TableCell>
                    <Link to="/enquiry-details">{enquiry.enquiryNo}</Link>
                  </TableCell>
                  <TableCell>{enquiry.date}</TableCell>
                  <TableCell>{enquiry.nature}</TableCell>
                  <TableCell>{enquiry.kam}</TableCell>
                  <TableCell>{enquiry.firm}</TableCell>
                  <TableCell>{enquiry.category}</TableCell>
                  <TableCell style={{ color: '#199348' }}>{enquiry.grade}</TableCell>
                  <TableCell style={{ color: '' }}>{enquiry.size}</TableCell>
                  <TableCell style={{ color: '#199348' }}>{enquiry.conditions}</TableCell>
                  <TableCell>{enquiry.quantity}</TableCell>
                  <TableCell sx={{ padding: '0px' }}>
                    <Box sx={{ ...statusStyles[enquiry.status] }}>
                      <Typography variant="body2">{enquiry.status}</Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <IconButton component={Link} to="/edit-enquiry">
                      <img src={Edit} alt="" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Box sx={{ textAlign: 'right', marginTop: '1rem', padding: '1rem' }}>
            <Typography variant="body2" sx={{ textDecoration: 'underline', cursor: 'pointer' }}>
              View All
            </Typography>
          </Box>
        </TableContainer>
      </Box>

      <Box sx={{ padding: 2, border: '1px solid', borderColor: Colors.form_lines, marginTop: '50px', borderRadius: '15px' }}>
        <Typography variant="h6" sx={{ marginLeft: 2, fontWeight: 'bold' }}>
          <Heading24Semi text={'Orders raised in last 24 hours'} />
        </Typography>
        <Grid container spacing={2} sx={{ marginTop: '5px' }}>
          {orders.map((order, index) => (
            <Grid item xs={12} key={index}>
              <Paper elevation={3} sx={{ padding: 1.5, backgroundColor: '#70FACB4D', borderRadius: '15px' }}>
                <Box>
                  <Typography variant="body1" sx={{ fontWeight: 'bold', display: 'flex', justifyContent: 'space-between' }}>
                    <span>• {order.companyName}</span>
                    <span style={{ fontWeight: 'normal' }}>{order.orderDate}</span>
                  </Typography>
                  <Typography variant="body1" sx={{ marginTop: 1 }}>
                    {order.orderNo}
                  </Typography>
                  <Typography variant="body2" sx={{ marginTop: 1 }}>
                    {order.details}
                  </Typography>
                  <Typography variant="body2" sx={{ marginTop: 1 }}>
                    Product Quantity: {order.quantity}
                  </Typography>
                  <Typography variant="body2" sx={{ marginTop: 1, display: 'flex', justifyContent: 'flex-end' }}>
                    Delivery Date: {order.deliveryDate}
                  </Typography>
                </Box>
              </Paper>
            </Grid>
          ))}
        </Grid>
        <Box sx={{ textAlign: 'right', marginTop: 2 }}>
          <Typography variant="body2" sx={{ color: 'primary.main', cursor: 'pointer', textDecoration: 'underline' }}>
            View All
          </Typography>
        </Box>
      </Box>
      <Grid
        container
        spacing={1}
        sx={{ marginTop: '50px', padding: '1rem', backgroundColor: '#0093D3', color: '#fff', borderRadius: '15px' }}
      >
        <Grid item xs={12}>
          <Typography variant="h5" align="left" sx={{ fontWeight: 'bold' }}>
            Complaints raised in last 24 hours
          </Typography>
        </Grid>
        {[1, 2, 3].map((item) => (
          <Grid item xs={12} md={4} key={item}>
            <Paper elevation={3} sx={{ padding: '1rem', backgroundColor: '#fff', color: '#000', borderRadius: 2 }}>
              <Typography variant="subtitle1" fontWeight="bold">
                Complain No. 12345
              </Typography>
              <Typography variant="body2">Date: 25 Feb 2025 2:50PM</Typography>
              <Typography variant="body2">Time: 2:50 PM</Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '0.5rem' }}>
                <Typography variant="body2" sx={{ flexGrow: 1 }}>
                  Case Status: Pending
                </Typography>
                <FiberManualRecordIcon sx={{ fontSize: '1rem', color: 'red' }} />
              </Box>
            </Paper>
          </Grid>
        ))}
        <Grid item xs={12} sx={{ textAlign: 'right' }}>
          <Typography variant="body1" sx={{ cursor: 'pointer', textDecoration: 'underline' }}>
            View All
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
