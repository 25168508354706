import React, { useEffect, useState } from 'react';
import {
  Box,
  Chip,
  OutlinedInput,
  Select,
  Typography,
  styled,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Paper,
  Grid,
  InputLabel,
  MenuItem,
  Checkbox,
} from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { useNavigate, useParams } from 'react-router-dom';
import Colors from '../../../../theme/colors/Color';
import { getData, postData, updateData } from '../../../../services/APIService';
import { FilledButton, OutlineButton, CustomRadioBtn, Heading26Bold, InputTextField, Combobox } from '../../../../utils/component';
import { useDispatch, useSelector } from 'react-redux';
import { showSnackbar } from '../../../../redux/features/snackbar/snackbarSlice';

const CustomPaper = styled(Paper)(() => ({
  padding: '28px 9px',
}));

const FieldBox = styled(Box)(() => ({
  height: '10vh',
  marginBottom: '2.5px',
}));

// const deparmentOptions = [
//   { value: 'Purchase', label: 'Purchase' },
//   { value: 'Sales', label: 'Sales' },
//   { value: 'Marketing', label: 'Marketing' },
// ];

const reportingManager = [
  { value: 10, label: 'Amol Kapadi' },
  { value: 20, label: 'Rutik Rassal' },
  { value: 30, label: 'Akshay Autade' },
];

const AddUser = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const Masters = useSelector((state) => state.masters);
  const deparmentOptions = Masters?.data?.mstUserDepartment || [];
  const [showSubBtn, setShowSubBtn] = useState(true);
  const [employeeDetails, setEmployeeDetails] = useState({
    user_code: null,
    name: '',
    type: 'EMPLOYEE',
    mobile_number: '',
    email: '',
    status: '',
    department: null,
    reporting_manager: '',
    password: '',
    role_id: null,
    customerAccess: [],
    customerAccessRemove: [],
    assignedFirm: [],
    is_verified: true,
  });
  const [accessCustomerList, setAccessCustomerList] = useState([]);
  const [accessCustomerIds, setAccessCustomerIds] = useState([]);
  const [isUpdateForm, setIsUpdateForm] = useState(false);
  const [roleName, setRoleName] = useState('');
  const [roleOptions, setRoleOptions] = useState([]);
  const [customersOptions, setCustomersOptions] = useState([]);
  const { id } = useParams();
  const userData = JSON.parse(localStorage.getItem('userDetails'));

  useEffect(() => {
    if (id) {
      fetchUserById(id);
      setIsUpdateForm(true);
    }
    getAllInitialData();
  }, []);

  useEffect(() => {
    if (employeeDetails.email && employeeDetails.password && employeeDetails.role_id) {
      setShowSubBtn(false);
    }
  }, [employeeDetails]);

  const fetchUserById = async (id) => {
    try {
      const res = await getData(`/users/${id}`, {}, userData.token);
      const data = res.data.data;
      data['customerAccess'] = data?.customerAccess || [];
      data['customerAccessRemove'] = data?.customerAccessRemove || [];
      setEmployeeDetails(data);
      setRoleName(res.data.data?.role?.role_name);
      const assignFirm = res.data?.data?.assignedFirm || [];
      const assignFirmIds = assignFirm?.map((item) => item.id);
      setAccessCustomerList(assignFirm);
      setAccessCustomerIds(assignFirmIds);
      // dispatch(showSnackbar({ message: 'Employee Details fetch successfully', severity: 'success' }));
    } catch (error) {
      dispatch(showSnackbar({ message: error.message, severity: 'error' }));
    }
  };

  const getAllInitialData = async () => {
    await getData('role')
      .then((res) => setRoleOptions(res.data.data))
      .catch((error) => console.log(error));

    await postData('/firm/getAll', { type: 'CUSTOMER', page: 1, limit: 100 }, {}, userData.token)
      .then((res) => setCustomersOptions(res.data.data.firms))
      .catch((error) => console.log(error));
  };

  const removeCustomer = (item) => {
    const updateList = accessCustomerList.filter((customer) => customer.id !== item.id);
    setAccessCustomerList(updateList);
    setAccessCustomerIds((prev) => prev.filter((id) => id !== item.id));
    const isNewCustomerAccess = employeeDetails.customerAccess.includes(item.id);
    setEmployeeDetails((prevDetails) => ({
      ...prevDetails,
      customerAccess: isNewCustomerAccess
        ? [...prevDetails.customerAccess.filter((id) => id !== item.id)]
        : [...prevDetails.customerAccess],
      customerAccessRemove: isNewCustomerAccess ? [...prevDetails.customerAccessRemove] : [...prevDetails.customerAccessRemove, item.id],
    }));
  };

  const handleTextChange = (e) => {
    setEmployeeDetails((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleRoleSelect = (role) => {
    setEmployeeDetails((prev) => ({ ...prev, role_id: parseInt(role?.role_id) }));
    setRoleName(role?.role_name);
  };

  const handleSelectCustomer = (item) => {
    const isCurrentlySelected = accessCustomerIds.includes(item.id);
    const isAlreadyCustomer = employeeDetails.assignedFirm.some((firm) => firm.id === item.id);

    setAccessCustomerIds((prev) => (isCurrentlySelected ? prev.filter((id) => id !== item.id) : [...prev, item.id]));
    setAccessCustomerList((prev) => (isCurrentlySelected ? prev.filter((firm) => firm.firm_name !== item.firm_name) : [...prev, item]));
    setEmployeeDetails((prevDetails) => ({
      ...prevDetails,
      customerAccess: isCurrentlySelected
        ? prevDetails.customerAccess.filter((id) => id !== item.id)
        : isAlreadyCustomer
          ? prevDetails.customerAccess
          : [...prevDetails.customerAccess, item.id],
      customerAccessRemove: isCurrentlySelected
        ? isAlreadyCustomer
          ? [...prevDetails.customerAccessRemove, item.id]
          : prevDetails.customerAccessRemove
        : prevDetails.customerAccessRemove.filter((id) => id !== item.id),
    }));
  };

  const validateEmail = (email) => /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
  const validateMobile = (mobile) => /^[0-9]{10}$/.test(mobile);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const validateEmail = (email) => /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
    if (!validateEmail(employeeDetails.email)) dispatch(showSnackbar({ message: 'Please Enter valid Email', severity: 'warning' }));
    else if (!validateMobile(employeeDetails.mobile_number))
      dispatch(showSnackbar({ message: 'Please Enter valid Mobile Number', severity: 'warning' }));
    else
      try {
        if (employeeDetails.email && employeeDetails.password) {
          const res = await postData('/auth/register/employee', employeeDetails, {}, userData.token);
          if (res.data.status) {
            dispatch(showSnackbar({ message: 'New Employee Register successfully', severity: 'success' }));
            navigate('/employee');
          }
        } else {
          dispatch(showSnackbar({ message: 'Please Enter Emali & Password', severity: 'warning' }));
        }
      } catch (error) {
        if (error.response && error.response.data) dispatch(showSnackbar({ message: error.response.data.message, severity: 'error' }));
        else dispatch(showSnackbar({ message: error.message, severity: 'error' }));
      }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    if (!validateEmail(employeeDetails.email)) dispatch(showSnackbar({ message: 'Please Enter valid Email', severity: 'warning' }));
    else if (!validateMobile(employeeDetails.mobile_number))
      dispatch(showSnackbar({ message: 'Please Enter valid Mobile Number', severity: 'warning' }));
    else
      try {
        let updateUserData;
        if (employeeDetails?.password?.length > 15) {
          // eslint-disable-next-line no-unused-vars
          const { password, ...userData } = employeeDetails;
          updateUserData = userData;
        } else {
          updateUserData = employeeDetails;
        }
        const res = await updateData(`/users/edit/${id}`, updateUserData, {}, userData.token);
        if (res.data.status) {
          dispatch(showSnackbar({ message: 'Employee details update successfully', severity: 'success' }));
          navigate('/employee');
        }
      } catch (error) {
        if (error.response && error.response.data) dispatch(showSnackbar({ message: error.response.data.message, severity: 'error' }));
        else dispatch(showSnackbar({ message: error.message, severity: 'error' }));
      }
  };

  return (
    <Box>
      <Heading26Bold text={isUpdateForm ? 'Edit BOS Employee' : 'Add New BOS Employee'} />
      <Grid container spacing={3} marginTop={'5px'}>
        <Grid item xs={12} lgm={8}>
          <CustomPaper elevation={5}>
            <Grid container gap={{ xs: 2, md: 0 }}>
              <Grid item xs={12} md={6} paddingRight={{ xs: 0, md: '5px' }}>
                <FieldBox>
                  <InputTextField
                    label={'Employee ID'}
                    name={'user_code'}
                    value={employeeDetails.user_code || ''}
                    isRequire={false}
                    isDisable={!!id}
                    onChange={handleTextChange}
                  />
                </FieldBox>
                <FieldBox>
                  <InputTextField
                    label={'Employee Name'}
                    name={'name'}
                    value={employeeDetails.name || ''}
                    helperText={''}
                    placeholder={'Enter Employee Name'}
                    isRequire={false}
                    onChange={handleTextChange}
                  />
                </FieldBox>
                <FieldBox>
                  <InputTextField
                    label={'Mobile No'}
                    name={'mobile_number'}
                    value={employeeDetails.mobile_number || ''}
                    placeholder={'9874563210'}
                    onChange={(e) => {
                      const { value } = e.target;
                      if (!isNaN(value) && value.length <= 10) {
                        handleTextChange(e);
                      }
                    }}
                  />
                </FieldBox>
                <FieldBox>
                  <InputTextField
                    label={'Email ID'}
                    name={'email'}
                    value={employeeDetails.email || ''}
                    placeholder={'Enter email'}
                    isRequire={true}
                    type={'email'}
                    onChange={handleTextChange}
                  />
                </FieldBox>
              </Grid>
              <Grid item xs={12} md={6} paddingLeft={{ xs: 0, md: '5px' }}>
                <FieldBox color={Colors.text_413e3e}>
                  <FormControl>
                    <FormLabel>
                      <Typography sx={{ fontSize: '16px', fontFamily: 'poppins-regular', color: Colors.text_413e3e, marginTop: '-5px' }}>
                        Employee Status
                      </Typography>
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={employeeDetails.status}
                      onChange={(e) => setEmployeeDetails((prev) => ({ ...prev, status: e.target.value }))}
                    >
                      <FormControlLabel
                        value="Active"
                        control={<CustomRadioBtn />}
                        label="Active"
                        sx={{
                          '& .MuiFormControlLabel-label': {
                            fontFamily: 'poppins-regular',
                          },
                        }}
                      />
                      <FormControlLabel
                        value={'Inactive'}
                        control={<CustomRadioBtn />}
                        label="Inactive"
                        sx={{
                          '& .MuiFormControlLabel-label': {
                            fontFamily: 'poppins-regular',
                          },
                        }}
                      />
                    </RadioGroup>
                  </FormControl>
                </FieldBox>
                <FieldBox>
                  <Combobox
                    label={'Department'}
                    optionsLabel={'name'}
                    options={deparmentOptions}
                    isDisable={false}
                    isRequire={false}
                    value={employeeDetails.department}
                    onChangeValue={(name) => setEmployeeDetails((prev) => ({ ...prev, department: name?.name }))}
                  />
                </FieldBox>
                <FieldBox>
                  <Combobox
                    label={'Reporting Manager'}
                    optionsLabel={'label'}
                    options={reportingManager}
                    isDisable={false}
                    isRequire={false}
                    value={employeeDetails.reporting_manager}
                    onChangeValue={(name) => setEmployeeDetails((prev) => ({ ...prev, reporting_manager: name?.label }))}
                  />
                </FieldBox>
                <FieldBox>
                  <InputTextField
                    label={'Password'}
                    name={'password'}
                    value={employeeDetails?.password?.length > 25 ? '.............' : employeeDetails.password}
                    type={'password'}
                    helperText={''}
                    placeholder={'********'}
                    isRequire={id ? false : true}
                    onChange={handleTextChange}
                  />
                </FieldBox>
              </Grid>
            </Grid>
          </CustomPaper>
        </Grid>
        <Grid item xs={12} lgm={4}>
          <CustomPaper elevation={5}>
            <Box height={'41.2vh'}>
              <FieldBox>
                <Combobox
                  label={'Role'}
                  optionsLabel={'role_name'}
                  options={roleOptions}
                  isDisable={id ? true : false}
                  isRequire={true}
                  value={roleName}
                  onChangeValue={handleRoleSelect}
                />
              </FieldBox>
              <FieldBox sx={{ display: 'flex', gap: '15px' }}>
                <Box width={{ xs: '90vw', md: '60vw', lgm: '20vw', xl: '25vw' }}>
                  <FormControl fullWidth>
                    <InputLabel
                      id="demo-simple-select-label"
                      sx={{
                        top: '-6px',
                        left: '10px',
                        fontFamily: 'poppins-regular',
                        '&.MuiInputLabel-shrink': {
                          top: '0px',
                          left: '0px',
                          color: Colors.text_413e3e,
                          transform: 'translate(14px, -14px) scale(0.75)',
                        },
                        '& .MuiFormLabel-asterisk': {
                          color: Colors.error,
                        },
                      }}
                    >
                      Access To Customers
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      multiple
                      value={[]}
                      label={'Access to customer'}
                      disabled={roleName !== 'KAM'}
                      IconComponent={ExpandMoreRoundedIcon}
                      sx={{
                        height: '45px',
                        fontFamily: 'poppins-regular',
                        '& .MuiSelect-select': {
                          paddingTop: '12px',
                          paddingBottom: '12px',
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: Colors.text_413e3e,
                          border: '1px solid',
                        },
                        '&.MuiSelect-selectMenu': {
                          color: 'red',
                        },
                      }}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            '& .MuiMenuItem-root': {
                              fontFamily: 'poppins-regular',
                            },
                          },
                        },
                      }}
                    >
                      {customersOptions.map((item, i) => (
                        <MenuItem
                          value={item.firm_name}
                          key={i}
                          onClick={() => handleSelectCustomer(item)}
                          sx={{ color: accessCustomerIds.includes(item.id) ? Colors.placeholder : Colors.text_black }}
                        >
                          <Checkbox
                            checked={accessCustomerIds.includes(item.id)}
                            sx={{
                              padding: 0,
                              marginRight: '8px',
                            }}
                          />
                          {item.firm_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box
                  sx={{
                    height: '36px',
                    width: '36px',
                    background: isUpdateForm || roleName !== 'KAM' ? Colors.placeholder : Colors.primary,
                    borderRadius: '6px',
                    textAlign: 'center',
                    padding: '3px',
                  }}
                >
                  <AddRoundedIcon sx={{ color: Colors.white, height: '20px' }} />
                  <Typography sx={{ fontSize: '10px', color: Colors.white, fontFamily: 'poppins-regular', marginTop: '-4px' }}>
                    Add
                  </Typography>
                </Box>
              </FieldBox>
              <Box>
                <Select
                  multiple
                  fullWidth
                  disabled
                  sx={{ height: '16vh', border: '1px solid ' }}
                  value={accessCustomerList}
                  IconComponent={() => null}
                  input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                  renderValue={(selected) => (
                    <Box
                      sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 1,
                        alignItems: 'start',
                        height: '14vh',
                        overflow: 'auto',
                        padding: '6px',
                        '&::-webkit-scrollbar': {
                          width: '4px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                          backgroundColor: Colors.placeholder,
                          borderRadius: '2px',
                        },
                      }}
                    >
                      {selected.map((value, i) => (
                        <Box
                          key={i * 12}
                          sx={{
                            height: '28px',
                            display: 'flex',
                            alignItems: 'center',
                            background: isUpdateForm ? Colors.transparent : Colors.bg_strip,
                            border: isUpdateForm ? '1px solid grey' : 'none',
                          }}
                        >
                          <Chip
                            label={value?.firm_name || ''}
                            sx={{ background: Colors.transparent, fontFamily: 'poppins-regular', fontSize: '16px' }}
                          />
                          <CloseRoundedIcon
                            sx={{ height: '20px', cursor: 'pointer' }}
                            onClick={() => {
                              removeCustomer(value);
                            }}
                          />
                        </Box>
                      ))}
                    </Box>
                  )}
                />
              </Box>
            </Box>
          </CustomPaper>
        </Grid>
      </Grid>
      <Box
        width={{ xs: '65vw', sm: '30vw' }}
        sx={{
          margin: '0px auto',
          marginTop: '30px',
          display: 'flex',
          gap: '25px',
        }}
      >
        <OutlineButton text={'Cancel'} onClick={() => navigate('/employee')} />
        {id ? (
          <FilledButton text={'Update'} onClick={handleUpdate} />
        ) : (
          <FilledButton text={'Submit'} onClick={handleSubmit} isdisable={showSubBtn} />
        )}
      </Box>
    </Box>
  );
};

export default AddUser;
