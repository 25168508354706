import { Box, Button, FormControlLabel, Modal, Popover, RadioGroup, TextField, Typography, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Heading26Bold } from '../../../../common-components';
import Colors from '../../../../theme/colors/Color';
import { Add, Delete, Filter, exportIcon } from '../../../../theme/Images';
import { useNavigate } from 'react-router-dom';
// import ListDataTable from '../../../../common-components/table/ListDataTable'
import EnquiryFilter from '../filters/EnquiryFilter';
// import { getData } from '../../../../services/APIService';
import { postData, postDataExport } from '../../../../services/APIService';
import { getStatusBgColor } from '../../../../utils/constants/statusColor';
import { Edit } from '../../../../theme/Images';
import { CustomDataGrid, CustomRadioBtn, Heading16Reg, Heading20Semi } from '../../../../utils/component';
import Pagination from '../../../../utils/component/pagination/Pagination';
import { useDispatch } from 'react-redux';
import { showSnackbar } from '../../../../redux/features/snackbar/snackbarSlice';
import { clearIds } from '../../../../redux/features/datagrid/datagridSlice';

const limit = 10;
let totalPages = 1;

const CustomSearch = styled(TextField)(() => ({
  '& .MuiOutlinedInput-root': {
    '&.MuiInputBase-root': {
      height: '40px',
      borderRadius: '10px',
      width: '31.8vw',
      background: Colors.white,
    },
  },
}));

const CustomBtnBox = styled(Box)(() => ({
  // border: '1px solid',
  height: '35px',
  width: '35px',
  borderRadius: '6px',
  padding: '8px 6px 5px',
  background: Colors.white,
  textAlign: 'center',
  cursor: 'pointer',
}));

const DeleteModalstyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '20vw',
  bgcolor: 'background.paper',
  boxShadow: 40,
  p: 4,
};

const initialFilters = {
  firm_id: [],
  firm_type: [],
  firm_category: [],
  enquiry_type: [],
  kam_id: [],
  status: [],
  grade_id: [],
  size_id: [],
  pr_id: [],
  byDate: [],
};

const EnquiryList = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [enquiryData, setEnquiryData] = useState([]);
  const [page, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState(initialFilters);
  const [deleteWarning, setDeleteWarning] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [keyword, setKeyword] = useState('');

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleClickFilter = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const userData = JSON.parse(localStorage.getItem('userDetails'));

  const columnsData = [
    {
      field: 'enquiry_number',
      headerName: 'Enquiry No',
      flex: 0.65,
      renderCell: (params) => {
        const { enquiry_id } = params.row;
        const Code = params.value;
        const viewDetails = () => {
          navigate(`/enquiry/edit/${enquiry_id}`, { state: { eqType: params.row.enquiry_type, isCallFrom: 'view' } });
        };
        return (
          <Box
            sx={{
              color: Colors.primary,
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            onClick={viewDetails}
          >
            {Code}
          </Box>
        );
      },
    },

    { field: 'createdAt', headerName: 'Date of Enquiry', flex: 0.5, valueGetter: (p) => new Date(p).toLocaleDateString() },
    {
      field: 'enquiry_type',
      headerName: 'Type of Enquiry',
      flex: 0.5,
    },
    // { field: 'nature_of_enquiry', headerName: 'Nature of enquiry', flex: 0.5 },
    {
      field: 'kam',
      headerName: 'KAM',
      flex: 0.5,
      renderCell: (params) => {
        return params.row.firm?.kam?.name || '';
      },
    },
    {
      field: 'firm',
      headerName: 'Firm Name',
      flex: 0.6,
      valueGetter: (params) => params.firm_name,
    },
    {
      field: 'productCategory',
      headerName: 'Product Category',
      flex: 0.5,
      valueGetter: (params) => params?.category_name,
    },
    {
      field: 'grade',
      headerName: 'Grade',
      flex: 0.5,
      valueGetter: (params) => params?.grade_name,
      renderCell: (params) => {
        return <Box sx={{ color: params.row.grade?.is_external ? Colors.error : 'inherit' }}>{params.value}</Box>;
      },
    },
    {
      field: 'size',
      headerName: 'Size',
      flex: 0.3,
      valueGetter: (params) => params?.size_name,
      renderCell: (params) => {
        return <Box sx={{ color: params.row.size?.is_external ? Colors.error : 'inherit' }}>{params.value}</Box>;
      },
    },
    // {
    //   field: 'processRoute',
    //   headerName: 'Process route',
    //   flex: 0.5,
    //   valueGetter: (params) => params?.pr_name,
    //   renderCell: (params) => {
    //     return <Box sx={{ color: params.row.processRoute?.is_external ? Colors.error : 'inherit' }}>{params.value}</Box>;
    //   },
    // },
    { field: 'quantity', headerName: 'Quantity (MT)', flex: 0.5 },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.5,
      renderCell: (params) => {
        const status = params.value;
        return (
          <Box sx={{ height: '100%', display: 'flex', alignItems: 'center' }}>
            <Box
              sx={{
                background: getStatusBgColor(status),
                height: '25px',
                width: '95px',
                minWidth: '80px',
                borderRadius: '15px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'white',
                padding: '-1px 20px',
              }}
            >
              {status}
            </Box>
          </Box>
        );
      },
    },
    {
      // field: null,
      headerName: 'Edit',
      width: 70,
      renderCell: (params) => {
        const { enquiry_id } = params.row;
        const handleEdit = () => {
          navigate(`/enquiry/edit/${enquiry_id}`, { state: { eqType: params.row.enquiry_type, isCallFrom: 'edit' } });
        };
        return (
          <img
            style={{ height: '25px', marginLeft: '10px', marginTop: '10px', cursor: 'pointer' }}
            src={Edit}
            alt="edit"
            onClick={handleEdit}
          />
        );
      },
      filterable: false,
    },
  ];

  const highlightCondition = (params) => {
    const dayPassed = new Date() - new Date(params.row.createdAt) > 24 * 60 * 60 * 1000;
    return dayPassed && params.row.status !== 'Close';
  };

  useEffect(() => {
    fetchEnquiries();
  }, [page, filters, keyword]);

  useEffect(() => {
    dispatch(clearIds());
  }, []);

  const fetchEnquiries = async () => {
    try {
      const res = await postData(`/enquiry/getall`, { page, limit, filters, keyword }, {}, userData.token);
      if (res && res.data && res.data.status) {
        totalPages = res.data.data.totalPages;
        setEnquiryData(res.data.data.enquiries);
        // console.log('allEnquiry', res.data.data.enquiries);
      }
    } catch (error) {
      console.log('error fetchin enquiries', error);
    }
  };

  const handleDelete = async () => {
    try {
      const res = await postData('/enquiry/bulk/delete', { enquiryIds: selectedRows }, {}, userData.token);
      if (res && res && res.data.status) {
        setDeleteWarning(false);
        fetchEnquiries();
        dispatch(showSnackbar({ message: 'Enquiry deleted successfully', severity: 'success' }));
        setModalOpen(false);
      } else dispatch(showSnackbar({ message: 'Something went wrong', severity: 'error' }));
    } catch (error) {
      console.log(error);
      if (error.response && error.response.data) dispatch(showSnackbar({ message: error.response.data.message, severity: 'error' }));
      else dispatch(showSnackbar({ message: 'Something went wrong...!', severity: 'error' }));
    }
    setSelectedRows([]);
  };

  const handleModalConfirm = () => {
    setModalOpen(false);
    navigate(`/product/add`);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleCloseFilter = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const handleSelectedFilters = (filter) => {
    const cleanedFilters = Object.fromEntries(
      Object.entries(filter).filter(([key, value]) => {
        if (key) return Array.isArray(value) ? value.length > 0 : value;
      })
    );
    setFilters(cleanedFilters);
    setCurrentPage(1);
  };

  const handleSearch = (e) => {
    const keyword = e.target.value.trim(); // Get trimmed input value
    setKeyword(keyword); // Update the state
    setCurrentPage(1);

    // // Update the filters on each key press
    // setFilters((prev) => {
    //   const updatedFilters = { ...prev, product_code: newProductCode ? [newProductCode] : [] }; // Update product_code filter
    //   selectedFilters(updatedFilters); // Notify parent component with updated filters
    //   return updatedFilters;
    // });
  };
  const handleExport = async () => {
    // if (selectedRows.length === 0) {
    //   dispatch(showSnackbar({ message: 'Please select Enquiry to Export!', severity: 'warning' }));
    //   return; // Exit the function if no products are selected
    // }
    try {
      const response = await postDataExport(
        '/enquiry/export',
        { filters: { enquiry_ids: selectedRows } },
        {
          headers: {
            Authorization: `Bearer ${userData.token}`,
          },
          responseType: 'blob',
        },
        userData.token
      );

      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'enquiry.xlsx';
      link.click();

      dispatch(showSnackbar({ message: 'Export successful', severity: 'success' }));
    } catch (error) {
      console.error('Error:', error);

      dispatch(showSnackbar({ message: 'Something went wrong', severity: 'error' }));
    }
  };

  const handleClearFilter = () => {
    setFilters(initialFilters);
    fetchEnquiries();
  };
  return (
    <Box>
      <Heading26Bold text={'Enquiry Management'} />
      <Box
        sx={{
          marginTop: '20px',
          height: '10.4vh',
          borderRadius: '10px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: ' center',
          padding: '0px 30px',
          background: Colors.bg_strip,
        }}
      >
        <CustomSearch placeholder="Search" onChange={handleSearch} />
        <Box sx={{ display: 'flex', gap: '20px' }}>
          <Box>
            <CustomBtnBox onClick={handleClickFilter}>
              <img src={Filter} alt="filter" style={{ height: '20px', width: '25px' }} />
              <Typography sx={{ fontSize: '10px' }}>FILTER</Typography>
            </CustomBtnBox>
            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={handleCloseFilter}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              PaperProps={{
                style: { marginTop: 5 },
              }}
            >
              <Box>
                <EnquiryFilter selectedFilters={handleSelectedFilters} filterData={filters} />

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                    position: 'relative',
                    bottom: 10,
                    right: 0,
                    width: '100%',
                    paddingRight: '30px',
                  }}
                >
                  <Button variant="contained" size="small" onClick={handleClearFilter}>
                    Clear filter
                  </Button>
                </Box>
              </Box>
            </Popover>
          </Box>
          <CustomBtnBox onClick={handleExport}>
            <img src={exportIcon} alt="add" style={{ height: '22px', width: '22px' }} />
            <Typography sx={{ fontSize: '10px' }}>EXPORT</Typography>
          </CustomBtnBox>
          <CustomBtnBox onClick={() => navigate(`/enquiry/add`)}>
            <img src={Add} alt="add" style={{ height: '22px', width: '22px' }} />
            <Typography sx={{ fontSize: '10px' }}>ADD</Typography>
          </CustomBtnBox>
          <CustomBtnBox onClick={() => setDeleteWarning(true)}>
            <img src={Delete} alt="delete" style={{ height: '21px', width: '20px' }} />
            <Typography sx={{ fontSize: '10px' }}>DELETE</Typography>
          </CustomBtnBox>
        </Box>
      </Box>
      <Box>
        <CustomDataGrid
          rowIdKey={'enquiry_id'}
          columnsData={columnsData}
          rowsData={enquiryData}
          onSelectCheckbox={(r) => setSelectedRows(r)}
          getHighlightCondition={highlightCondition}
        />
        <Pagination currentPage={page} totalPages={totalPages} onPageChange={(p) => setCurrentPage(p)} />
      </Box>
      <Modal
        open={deleteWarning}
        onClose={() => setDeleteWarning(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={DeleteModalstyle}>
          {selectedRows?.length ? (
            <>
              <Heading16Reg text="Do you want to Delete Enquiry Permanantly?" />
              <Box sx={{ display: 'flex', gap: 2, mt: 1 }}>
                <Button variant="outlined" sx={{ borderRadius: '20px', textTransform: 'none' }} onClick={() => setDeleteWarning(false)}>
                  Cancel
                </Button>
                <Button variant="contained" color="error" sx={{ borderRadius: '20px', textTransform: 'none' }} onClick={handleDelete}>
                  Delete
                </Button>
              </Box>
            </>
          ) : (
            <Heading16Reg text="Please select Enquiry first." />
          )}
        </Box>
      </Modal>
      <Modal open={modalOpen} onClose={handleModalClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 290,
            bgcolor: 'background.paper',
            border: 'none',
            boxShadow: 24,
            p: 4,
            borderRadius: '20px',
            textAlign: 'center',
          }}
        >
          <Heading20Semi text={'Add New Product For'} />
          <RadioGroup
            aria-label="supplier"
            name="supplier"
            value={selectedRows}
            onChange={(e) => setSelectedRows(e.target.value)}
            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', mt: 2 }}
          >
            <FormControlLabel value="BOS" label="BOS" control={<CustomRadioBtn />} labelPlacement="end" />
            <FormControlLabel value="Other supplier" control={<CustomRadioBtn />} label="Other supplier" labelPlacement="end" />
          </RadioGroup>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            <Button
              variant="contained"
              onClick={handleModalConfirm}
              sx={{ textTransform: 'none', borderRadius: '20px', bgcolor: Colors.primary, width: '130px' }}
            >
              Confirm
            </Button>
            <Button
              variant="outlined"
              onClick={handleModalClose}
              sx={{ textTransform: 'none', borderRadius: '20px', width: '130px', color: Colors.primary }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default EnquiryList;
