import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getData } from '../../../services/APIService';

export const fetchEnquiryReasonsMaster = createAsyncThunk('getEnquiryReasons', async () => {
  return await getData('mstEnquiryReasons/getList', {}, {});
});

const initialState = {
  reasons: [],
};

export const enquiryReasonsSlice = createSlice({
  name: 'enquiryReasons',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchEnquiryReasonsMaster.fulfilled, (state, action) => {
      state.reasons = action.payload.data.data;
    });
  },
});

export default enquiryReasonsSlice.reducer;
