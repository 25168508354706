import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Box, CircularProgress, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Modal from '@mui/material/Modal';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { FilledButton, OutlineButton } from '../../../utils/component';
import { getData, postData } from '../../../services/APIService';
import { useDispatch } from 'react-redux';
import { showSnackbar } from '../../../redux/features/snackbar/snackbarSlice';

const menuSubmenuList = [
  { name: 'User Management', subMenu: [{ name: 'BOS Employee' }, { name: 'Customer' }, { name: 'Supplier' }, { name: 'Vendor' }] },
  { name: 'Enquiry Management', subMenu: [{ name: 'Add/Edit Enquiry' }] },
  {
    name: 'Product Management',
    subMenu: [{ name: 'Add/Edit Product' }],
  },
  { name: 'Order Management', subMenu: [{ name: 'Add/Edit Order' }] },
  { name: 'Community', subMenu: [{ name: 'Add/Edit Community' }] },
  { name: 'Permission Management', subMenu: [] },
  { name: 'Master', subMenu: [{ name: 'BOS Master' }, { name: 'Dropdown List Master' }, { name: 'Tally Master' }] },
];
const modeList = ['view', 'add', 'edit', 'delete', 'export', 'import'];
function Row(props) {
  const { row, accessPermissionData, handleMenuChange, handleSubMenuChange } = props;
  const [open, setOpen] = React.useState(false);
  const accessPermissions = accessPermissionData.find((item) => item.module_name === row.name);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell style={{ width: '48px', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
          {row.subMenu && row.subMenu.length > 0 && (
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
            </IconButton>
          )}
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          style={{ width: '200px', fontFamily: 'poppins-regular', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}
        >
          {row.name}
        </TableCell>
        {modeList.map((key) => (
          <TableCell key={key} align="center" style={{ width: '120px', padding: 0 }}>
            <Checkbox
              checked={accessPermissions ? accessPermissions[key] : false}
              onChange={() => handleMenuChange(key, row.name)}
              sx={{
                '&.Mui-checked': {
                  color: '#63A47C',
                },
              }}
            />
          </TableCell>
        ))}
      </TableRow>

      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <Table size="small" aria-label="submenu">
                <TableBody>
                  {row.subMenu.map((submenu) => {
                    const subModulePermission = accessPermissionData.find(
                      (module) => module.module_name.toLowerCase() === submenu.name.toLowerCase()
                    );
                    return (
                      <TableRow key={submenu.name}>
                        <TableCell style={{ width: '48px' }} />
                        <TableCell style={{ width: '200px', fontFamily: 'poppins-regular' }}>{submenu.name}</TableCell>
                        {modeList.map((key) => (
                          <TableCell key={key} align="center" style={{ width: '150px', padding: 0 }}>
                            <Checkbox
                              checked={subModulePermission ? subModulePermission[key] : false}
                              onChange={() => handleSubMenuChange(key, submenu.name, row.name)}
                              sx={{
                                '&.Mui-checked': {
                                  color: '#63A47C',
                                },
                              }}
                            />
                          </TableCell>
                        ))}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    name: PropTypes.string.isRequired,
    subMenu: PropTypes.oneOfType([PropTypes.bool, PropTypes.arrayOf(PropTypes.object)]).isRequired,
  }).isRequired,
  accessPermissionData: PropTypes.arrayOf(
    PropTypes.shape({
      module_name: PropTypes.string.isRequired,
      view: PropTypes.bool.isRequired,
      add: PropTypes.bool.isRequired,
      edit: PropTypes.bool.isRequired,
      delete: PropTypes.bool.isRequired,
      export: PropTypes.bool.isRequired,
      import: PropTypes.bool.isRequired,
    })
  ).isRequired,
  handleMenuChange: PropTypes.func.isRequired,
  handleSubMenuChange: PropTypes.func.isRequired,
};

const Modalstyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '25vw',
  bgcolor: 'background.paper',
  boxShadow: 40,
  p: 5,
};
const AccessPermission = () => {
  const [roleList, setRoleList] = useState([]);
  const [selectedRole, setSelectedRole] = useState('');
  const [accessPermissionData, setAccessPermissionData] = useState([]);
  const [loading, setLoading] = useState(false);
  const userData = JSON.parse(localStorage.getItem('userDetails'));
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const handleModalOpen = () => {
    setOpen(true);
  };
  useEffect(() => {
    fetchRoleListData();
  }, []);
  useEffect(() => {
    fetchData();
  }, [selectedRole]);

  const fetchRoleListData = async () => {
    try {
      const res = await getData('/role', {}, userData.token);
      if (res.data.status && res.data.data) {
        setRoleList(res.data.data);
        if (res.data.data?.length > 0) setSelectedRole(res.data.data[0]);
      } else {
        dispatch(showSnackbar({ message: res.response.data.message, severity: 'error' }));
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      dispatch(showSnackbar({ message: errorMessage, severity: 'error' }));
    }
  };
  const fetchData = async () => {
    if (selectedRole && selectedRole?.role_id) {
      try {
        const res = await getData(`/accessManagement/getPermission?role_id=${selectedRole.role_id}`, {}, userData.token);
        if (res.data.status && res.data.data) {
          setAccessPermissionData([...res.data.data]);
        } else {
          dispatch(showSnackbar({ message: res.response.data.message, severity: 'error' }));
        }
      } catch (error) {
        const errorMessage = error.response?.data?.message || error.message;
        dispatch(showSnackbar({ message: errorMessage, severity: 'error' }));
      }
    }
  };
  const handleMenuChange = (key, moduleName) => {
    const menuSubmenuObj = menuSubmenuList.find((menuPermission) => menuPermission.name === moduleName);

    if (!menuSubmenuObj) return;

    const updatedPermissions = accessPermissionData.map((item) => {
      if (item.module_name === moduleName) {
        return { ...item, [key]: !item[key] };
      }

      if (menuSubmenuObj.subMenu.some((submenu) => submenu.name === item.module_name)) {
        return { ...item, [key]: !item[key] };
      }

      return item;
    });

    if (!accessPermissionData.some((item) => item.module_name === moduleName)) {
      const newModulePermission = {
        module_name: moduleName,
        [key]: true,
      };
      updatedPermissions.push(newModulePermission);
      menuSubmenuObj.subMenu.forEach((submenu) => {
        if (!accessPermissionData.some((item) => item.module_name === submenu.name)) {
          const newSubmenuPermission = {
            module_name: submenu.name,
            [key]: true,
          };
          updatedPermissions.push(newSubmenuPermission);
        }
      });
    }

    setAccessPermissionData(updatedPermissions);
  };
  const handleSubMenuChange = (permissionKey, subMenuName, mainMenuName) => {
    const updatedPermissions = accessPermissionData.map((permission) =>
      permission.module_name === subMenuName ? { ...permission, [permissionKey]: !permission[permissionKey] } : permission
    );

    setAccessPermissionData(updatedPermissions);

    const mainMenuObj = menuSubmenuList.find((menu) => menu.name === mainMenuName);
    const mainMenuPermission = accessPermissionData.find((permission) => permission.module_name === mainMenuName);

    const isSubMenuChecked = mainMenuObj.subMenu.some((submenu) => {
      const submenuPermission = updatedPermissions.find((permission) => permission.module_name === submenu.name);
      return submenuPermission && submenuPermission[permissionKey];
    });

    const updatedMainMenuPermission = {
      ...mainMenuPermission,
      [permissionKey]: isSubMenuChecked ? true : false,
    };
    if (!accessPermissionData.some((item) => item.module_name === mainMenuName)) {
      const newModulePermission = {
        module_name: mainMenuName,
        [permissionKey]: true,
      };
      updatedMainMenuPermission.push(newModulePermission);
      mainMenuObj.subMenu.forEach((submenu) => {
        if (!accessPermissionData.some((item) => item.module_name === submenu.name)) {
          const newSubmenuPermission = {
            module_name: submenu.name,
            [permissionKey]: true,
          };
          updatedMainMenuPermission.push(newSubmenuPermission);
        }
      });
    }
    setAccessPermissionData((prevData) => {
      const dataWithoutMainMenu = prevData.filter((item) => item.module_name !== mainMenuName);
      return [...dataWithoutMainMenu, updatedMainMenuPermission];
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await postData(
        '/accessManagement/addPermission',
        { role_id: selectedRole.role_id.toString(), permissions: accessPermissionData },
        {},
        userData.token
      );
      dispatch(showSnackbar({ message: `Access permission updated successfully`, severity: 'success' }));
    } catch (error) {
      dispatch(showSnackbar({ message: error?.response?.data?.message || error.message, severity: 'error' }));
    } finally {
      setLoading(false);
      setOpen(false);
    }
  };
  return (
    <>
      <Box display="flex" alignItems="center" gap={3}>
        <Typography variant="body1">Role:</Typography>
        <Select
          options={roleList}
          placeholder="Select a role"
          getOptionLabel={(e) => e.role_name}
          getOptionValue={(e) => e.role_id}
          value={selectedRole}
          onChange={setSelectedRole}
          styles={{ container: (base) => ({ ...base, minWidth: 400 }) }}
        />
      </Box>
      <Box mt={5}>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow sx={{ backgroundColor: '#0093D3' }}>
                <TableCell style={{ width: '48px' }} />
                <TableCell style={{ width: '200px', color: 'white', fontFamily: 'poppins-regular' }}>Permission</TableCell>
                <TableCell align="center" sx={{ color: 'white', width: '120px', fontFamily: 'poppins-regular' }}>
                  View
                </TableCell>
                <TableCell align="center" sx={{ color: 'white', width: '120px', fontFamily: 'poppins-regular' }}>
                  Add
                </TableCell>
                <TableCell align="center" sx={{ color: 'white', width: '120px', fontFamily: 'poppins-regular' }}>
                  Edit
                </TableCell>
                <TableCell align="center" sx={{ color: 'white', width: '120px', fontFamily: 'poppins-regular' }}>
                  Delete
                </TableCell>
                <TableCell align="center" sx={{ color: 'white', width: '120px', fontFamily: 'poppins-regular' }}>
                  Export
                </TableCell>
                <TableCell align="center" sx={{ color: 'white', width: '120px', fontFamily: 'poppins-regular' }}>
                  Import
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {accessPermissionData.length
                ? menuSubmenuList.map((row) => (
                    <Row
                      key={row.name}
                      row={row}
                      accessPermissionData={accessPermissionData}
                      handleMenuChange={handleMenuChange}
                      handleSubMenuChange={handleSubMenuChange}
                    />
                  ))
                : ''}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box
        width={{ xs: '65vw', sm: '30vw' }}
        sx={{
          margin: '0px auto',
          marginTop: '30px',
          display: 'flex',
          gap: '25px',
        }}
      >
        <FilledButton text={'Save'} onClick={handleModalOpen} />
        <OutlineButton text={'Cancel'} />
      </Box>
      <Modal open={open} onClose={() => setOpen(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={Modalstyle}>
          <CloseRoundedIcon sx={{ float: 'right' }} onClick={() => setOpen(false)} />
          <Box width={'100%'}>
            <Typography mt={5} style={{ textAlign: 'center', fontFamily: 'poppins-regular' }}>
              Are you sure you want to make changes to permissions.
            </Typography>
            <Box
              width={'18.8vw'}
              sx={{
                margin: 'auto',
                marginTop: '35px',
                marginBottom: '10px',
                display: 'flex',
                gap: '25px',
              }}
            >
              <FilledButton text={loading ? <CircularProgress size={24} /> : 'Confirm'} disabled={loading} onClick={handleSubmit} />
              <OutlineButton text={'Cancel'} onClick={() => setOpen(false)} />
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default AccessPermission;
