import { createSlice } from '@reduxjs/toolkit';

export const datagridSlice = createSlice({
  name: 'datagrid',
  initialState: {
    selectedIds: [],
  },
  reducers: {
    setIds: (state, action) => {
      state.selectedIds = action.payload;
    },
    addId: (state, action) => {
      if (!state.selectedIds.includes(action.payload)) {
        state.selectedIds.push(action.payload);
      }
    },
    removeId: (state, action) => {
      state.selectedIds = state.selectedIds.filter((id) => id !== action.payload);
    },
    clearIds: (state) => {
      state.selectedIds = [];
    },
  },
});

export const { setIds, addId, removeId, clearIds } = datagridSlice.actions;
export default datagridSlice.reducer;
