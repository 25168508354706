import { Box, Button, Typography } from '@mui/material';
import React, { useState } from 'react';
import { postData } from '../../services/APIService';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { showSnackbar } from '../../redux/features/snackbar/snackbarSlice.js';
const UserConfirmation = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [isDisabled, setIsDisabled] = useState(false);
  const handleSubmit = async (e, verify) => {
    e.preventDefault();

    const params = {
      user_id: id,
      is_approve: verify,
    };

    try {
      const res = await postData(`/users/approveRejectByFirm/`, params);
      if (res && res.data && res.status) {
        if (res.data.data === true) {
          setIsDisabled(true);
        }
        dispatch(showSnackbar({ message: res.data.message, severity: 'success' }));
      } else {
        dispatch(showSnackbar({ message: res.data.message, severity: 'error' }));
      }
    } catch (error) {
      console.log(error);
      const errorMessage = error.response?.data?.message ? error.response?.data?.message : error.message;
      dispatch(showSnackbar({ message: errorMessage, severity: 'error' }));
    }
  };
  return (
    <Box sx={{ width: '40%', margin: '0 auto', textAlign: 'center', marginTop: '10vh' }}>
      <Typography variant="h3" gutterBottom>
        User Confirmation
      </Typography>
      <Typography variant="body1" gutterBottom>
        Please confirm if you would like to approve or reject this user’s account registration on the BOS platform.
      </Typography>
      <Box display={'flex'} gap={2} justifyContent={'center'} mt={4}>
        <Button variant="contained" color="success" onClick={(e) => handleSubmit(e, true)} disabled={isDisabled}>
          Approved
        </Button>
        <Button variant="contained" color="error" onClick={(e) => handleSubmit(e, false)} disabled={isDisabled}>
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

export default UserConfirmation;
