import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Paper from '@mui/material/Paper';
import { postData, updateData } from '../../../../services/APIService';
import { showSnackbar } from '../../../../redux/features/snackbar/snackbarSlice';
import { Heading20Semi } from '../../../../utils/component';
import { Add, Delete, Edit } from '../../../../theme/Images.js';
// import BorderColorIcon from '@mui/icons-material/BorderColor';

const headerCellStyle2 = {
  border: '1px solid #0093D3',
  fontFamily: 'poppins-regular',
};
const CustomBtnBox = styled(Box)(() => ({
  height: '35px',
  width: '35px',
  borderRadius: '6px',
  padding: '10px',
  background: '#70FACB4D',
  textAlign: 'center',
  cursor: 'pointer',
  marginBottom: 20,
}));

export function GstMaster() {
  const initFormData = {
    gst_id: '',
    gst_type: '',
    gst_percentage: '',
  };
  const GSTTypeList = ['CGST', 'ISGST', 'SGST'];
  const [gstList, setGstList] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [formData, setFormData] = useState(initFormData);
  const userData = JSON.parse(localStorage.getItem('userDetails'));
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const dispatch = useDispatch();

  const fetchPriceMasterData = async () => {
    await postData(`/mstGST/getAll`, {}, {}, userData.token)
      .then((res) => {
        if (res.data.data) {
          setGstList(res.data.data?.list || []);
        }
      })
      .catch((error) => {
        console.error(error);
        dispatch(showSnackbar({ message: error?.response?.data?.message || error.message, severity: 'error' }));
        setGstList([]);
      });
  };

  useEffect(() => {
    fetchPriceMasterData();
  }, []);

  const handleOpen = () => {
    setOpen(true);
    setIsEdit(false);
  };
  const handleClose = () => {
    setFormData(initFormData);
    setOpen(false);
    setIsEdit(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const edit = async (item) => {
    setFormData({
      gst_id: item.gst_id,
      gst_type: item.gst_type,
      gst_percentage: item.gst_percentage,
    });
    handleOpen();
    setIsEdit(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (formData.gst_id) await updateData('/mstGST/update/' + formData.gst_id, formData, {}, userData.token);
      else await postData('/mstGST/add', formData, {}, userData.token);
      dispatch(showSnackbar({ message: `GST ${formData.gst_id ? 'updated' : 'added'} successfully`, severity: 'success' }));
      fetchPriceMasterData();
      handleClose();
    } catch (error) {
      dispatch(showSnackbar({ message: error?.response?.data?.message || error.message, severity: 'error' }));
    } finally {
      setLoading(false);
    }

    setFormData(initFormData);
  };

  const handleSelectRow = (id) => {
    setSelectedIds((prevSelected) =>
      prevSelected.includes(id) ? prevSelected.filter((selectedId) => selectedId !== id) : [...prevSelected, id]
    );
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const allIds = gstList.map((row) => row.gst_id);
      setSelectedIds(allIds);
    } else {
      setSelectedIds([]);
    }
  };

  const handleBulkDelete = async () => {
    if (selectedIds.length === 0) {
      dispatch(showSnackbar({ message: 'No items selected for deletion', severity: 'error' }));
      return;
    }

    if (window.confirm('Are you sure you want to delete selected items?')) {
      try {
        await postData('/mstGST/bulkDelete', { ids: selectedIds }, {}, userData.token);
        dispatch(showSnackbar({ message: 'Selected items deleted successfully', severity: 'success' }));
        setTimeout(() => {
          fetchPriceMasterData();
          setSelectedIds([]);
        }, 600);
      } catch (error) {
        dispatch(showSnackbar({ message: error?.response?.data?.message || error.message, severity: 'error' }));
      }
    }
  };
  const handleSelectGSTType = async (item) => {
    setFormData((prev) => ({ ...prev, gst_type: item }));
  };

  return (
    <div>
      <Heading20Semi text="GST Master" />

      <Box sx={{ display: 'flex', gap: '20px', justifyContent: 'flex-end' }}>
        <CustomBtnBox onClick={handleOpen}>
          <img src={Add} alt="add" style={{ height: '22px', width: '22px' }} />
          <Typography sx={{ fontSize: '10px' }}>ADD</Typography>
        </CustomBtnBox>
        <CustomBtnBox onClick={handleBulkDelete}>
          <img src={Delete} alt="delete" style={{ height: '21px', width: '20px' }} />
          <Typography sx={{ fontSize: '10px' }}>DELETE</Typography>
        </CustomBtnBox>
      </Box>
      <Box>
        <TableContainer component={Paper}>
          <Table aria-label="simple table" sx={{ minWidth: 650, border: '1px solid #0093D3' }}>
            <TableHead>
              <TableRow sx={{ backgroundColor: '#0093D3' }}>
                <TableCell padding="checkbox" sx={{ border: '1px solid #ffffff' }}>
                  <Checkbox
                    indeterminate={selectedIds.length > 0 && selectedIds.length < gstList.length}
                    checked={gstList.length > 0 && selectedIds.length === gstList.length}
                    onChange={handleSelectAll}
                  />
                </TableCell>
                <TableCell sx={{ border: '1px solid #ffffff', fontWeight: 600, color: '#ffffff' }}>GST Type</TableCell>
                <TableCell sx={{ border: '1px solid #ffffff', fontWeight: 600, color: '#ffffff' }}>GST Percentage</TableCell>
                <TableCell sx={{ border: '1px solid #ffffff', fontWeight: 600, color: '#ffffff' }}>Edit</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {gstList.map((row) => (
                <TableRow key={row.gst_id}>
                  <TableCell padding="checkbox" sx={{ border: '1px solid #0093D3' }}>
                    <Checkbox checked={selectedIds.includes(row.gst_id)} onChange={() => handleSelectRow(row.gst_id)} />
                  </TableCell>
                  <TableCell sx={headerCellStyle2}>{row.gst_type || ''}</TableCell>
                  <TableCell sx={headerCellStyle2}>{row.gst_percentage + '%' || ''}</TableCell>

                  <TableCell sx={headerCellStyle2}>
                    <img
                      style={{ height: '25px', marginLeft: '10px', marginTop: '5px', cursor: 'pointer' }}
                      src={Edit}
                      alt="edit"
                      onClick={() => edit(row)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* Modal for adding new freight rates */}
      <Modal open={open} onClose={handleClose} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Paper sx={{ padding: 2, width: 400 }}>
          <Typography sx={{ fontWeight: 'bold', fontSize: 20 }}>{isEdit ? 'Edit' : 'Add New'} Master GST</Typography>
          <form onSubmit={handleSubmit}>
            <FormControl sx={{ mt: 2, width: '100%' }}>
              <InputLabel id="demo-multiple-name-label">
                Select GST Type <span style={{ color: 'red' }}>*</span>
              </InputLabel>
              <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                fullWidth
                value={formData.gst_type}
                label="Select GST Type"
                disabled={isEdit}
                required
              >
                {GSTTypeList.map((item, i) => (
                  <MenuItem value={item} key={i} onClick={() => handleSelectGSTType(item)}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label="GST Percentage"
              name="gst_percentage"
              value={formData.gst_percentage}
              onChange={(e) => {
                handleChange(e);
              }}
              fullWidth
              margin="normal"
              required
              sx={{
                '& .MuiInputLabel-asterisk': {
                  color: 'red',
                },
              }}
            />
            <Button type="submit" variant="contained" sx={{ mt: 2 }} disabled={loading}>
              {loading ? <CircularProgress size={24} /> : 'Submit'}
            </Button>
            <Button variant="outlined" sx={{ mt: 2, ml: 2 }} onClick={handleClose}>
              Cancel
            </Button>
          </form>
        </Paper>
      </Modal>
    </div>
  );
}
