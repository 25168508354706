import React, { useEffect, useState } from 'react';
import { Box, styled } from '@mui/material';
import Colors from '../../../../theme/colors/Color';
import { Heading16Reg } from '../../../../common-components';
import { FILTER_CONSTANT } from '../../../../utils/constants/filters';
import PropTypes from 'prop-types';

const CustomBox = styled(Box)(() => ({
  padding: '15px',
  borderBottom: '1px solid',
  borderColor: Colors.secondary1,
  cursor: 'pointer',
}));

const BoxContainer = styled(Box)(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '10px',
}));

const BoxItem = styled(Box)(() => ({
  background: Colors.bg_filterItem,
  padding: '3px 15px',
  borderRadius: '20px',
  cursor: 'pointer',
}));
const filterItems = ['Realm', 'Status'];

const CommunityFilter = ({ selectedFilters, filterData }) => {
  const [selectedItem, setSelectedItem] = useState('Realm');
  const [filters, setFilters] = useState({ realm: [], status: [] });
  // console.log(filters);

  useEffect(() => {
    setFilters(filterData);
  }, [filterData]);
  const toggleSelect = (category, value) => {
    setFilters((prev) => {
      const isSelected = prev[category].includes(value);
      const updatedFilters = {
        ...prev,
        [category]: isSelected ? prev[category].filter((item) => item !== value) : [...prev[category], value],
      };
      selectedFilters(updatedFilters);
      return updatedFilters;
    });
  };
  const isValueSelected = (category, value) => {
    return filters[category]?.includes(value);
  };

  return (
    <Box display={'flex'} width={'35vw'} fontFamily={'poppins-regular'}>
      <Box width={'50%'} borderRight={'1px solid'} borderColor={Colors.secondary1}>
        {filterItems.map((item, i) => (
          <CustomBox key={i} sx={{ background: selectedItem === item ? Colors.bg_strip : '' }} onClick={() => setSelectedItem(item)}>
            <Heading16Reg text={item} />
          </CustomBox>
        ))}
      </Box>
      <Box width={'50%'} padding={2}>
        {selectedItem === 'Realm' && (
          <BoxContainer>
            {Object.values(FILTER_CONSTANT.community_Realm).map((communityRealm, index) => {
              return (
                <BoxItem
                  key={index}
                  sx={{
                    background: isValueSelected('realm', communityRealm) ? Colors.bg_strip : Colors.bg_filterItem,
                  }}
                  onClick={() => toggleSelect('realm', communityRealm)}
                >
                  {communityRealm}
                </BoxItem>
              );
            })}
          </BoxContainer>
        )}
        {selectedItem === 'Status' && (
          <>
            <BoxContainer>
              {Object.values(FILTER_CONSTANT.status).map((status, index) => {
                return (
                  <BoxItem
                    key={index}
                    sx={{
                      background: isValueSelected('status', status) ? Colors.bg_strip : Colors.bg_filterItem,
                    }}
                    onClick={() => toggleSelect('status', status)}
                  >
                    {status}
                  </BoxItem>
                );
              })}
            </BoxContainer>
          </>
        )}
      </Box>
    </Box>
  );
};
CommunityFilter.propTypes = {
  selectedFilters: PropTypes.func,
  filterData: PropTypes.object,
};
export default CommunityFilter;
