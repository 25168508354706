import { Box, Button, Modal } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Heading24Semi from '../../../../common-components/headings/Heading24Semi';
import { deleteIcon, Edit } from '../../../../theme/Images';
import { postData } from '../../../../services/APIService';
import { showSnackbar } from '../../../../redux/features/snackbar/snackbarSlice';
import { CustomDataGrid, Heading16Reg } from '../../../../utils/component';
import Pagination from '../../../../utils/component/pagination/Pagination';
import Colors from '../../../../theme/colors/Color';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const DeleteModalstyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '20vw',
  bgcolor: 'background.paper',
  boxShadow: 40,
  p: 4,
};

const SProductDetails = () => {
  // const [modalOpen, setModalOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const [selectedRows, setselectedRows] = useState([]);
  const [deleteWarning, setDeleteWarning] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setCurrentPage] = useState(1);
  const limit = 10;
  let totalPages = 1;
  const userData = JSON.parse(localStorage.getItem('userDetails'));
  const firmData = JSON.parse(localStorage.getItem('firmData'));
  let firm_id = firmData?.firmId;

  useEffect(() => {
    fetchProducts();
  }, [page]);

  const fetchProducts = async () => {
    try {
      const res = await postData(`/product/list`, { page, limit, filters: { firm_id: [firm_id] } }, {});
      if (res && res.data && res.data.status) {
        totalPages = res.data.data.totalPages;
        setProducts(res.data.data.products);
      } else dispatch(showSnackbar({ message: 'Something went wrong', severity: 'error' }));
    } catch (error) {
      if (error?.response?.status === 404) setProducts([]);
      console.log(error);
    }
  };

  const handleDelete = async () => {
    try {
      const res = await postData('/product/delete', { id: selectedRows }, {}, userData.token);
      if (res && res && res.data.status) {
        setDeleteWarning(false);
        fetchProducts();
        dispatch(showSnackbar({ message: 'Product deleted successfully', severity: 'success' }));
      } else dispatch(showSnackbar({ message: 'Something went wrong', severity: 'error' }));
    } catch (error) {
      console.log(error);
      if (error.response && error.response.data) dispatch(showSnackbar({ message: error.response.data.message, severity: 'error' }));
      else dispatch(showSnackbar({ message: 'Something went wrong...!', severity: 'error' }));
    }
    setselectedRows([]);
  };

  const columnsData = [
    {
      field: 'product_code',
      headerName: 'Product Code',
      flex: 1.2,
      renderCell: (params) => {
        const { product_id } = params.row;
        const viewDetails = () => {
          navigate('/product/add', { state: { id: product_id, mode: 'view' } });
        };
        return (
          <Box
            sx={{
              color: Colors.primary,
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            onClick={viewDetails}
          >
            {params.value}
          </Box>
        );
      },
    },
    { field: 'createdAt', headerName: 'Date of Entry', flex: 0.7, valueGetter: (p) => new Date(p).toLocaleDateString() },
    {
      field: 'category',
      headerName: 'Product Category',
      flex: 1,
      valueGetter: (params) => (params ? params.category_name : ''),
    },
    {
      field: 'subCategory',
      headerName: 'Sub-Category',
      flex: 1,
      valueGetter: (params) => (params ? params.sub_category_name : ''),
    },
    {
      field: 'firm',
      headerName: 'Supplier Name',
      flex: 1,
      valueGetter: (params) => {
        return params ? params.firm_name : '';
      },
    },
    // {
    //   field: 'addressLocation',
    //   headerName: 'WareHouse Location',
    //   flex: 1.5,
    //   valueGetter: (params) => {
    //     if (params) return params.address_line_1 + ', ' + params.address_line_2 + ', ' + params.city + ', ' + params.state;
    //   },
    // },
    {
      field: 'mill',
      headerName: 'Mill Name',
      flex: 0.7,
      valueGetter: (params) => (params ? params.mill_name : ''),
      renderCell: (params) => {
        return <Box sx={{ color: params.row.mill.is_external ? Colors.error : 'inherit' }}>{params.value}</Box>;
      },
    },
    { field: 'heat_number', headerName: 'Heat No.', flex: 0.5 },
    { field: 'quantity', headerName: 'Quantity', flex: 0.7 },
    {
      field: 'show_in_catalog',
      headerName: 'Show in Catalogue',
      flex: 0.8,
      renderCell: (params) => {
        if (params)
          return (
            <Box sx={{ height: '100%', display: 'flex', alignItems: 'center' }}>
              <Box
                sx={{
                  background: params.value ? Colors.success : Colors.error,
                  height: '25px',
                  width: '80px',
                  borderRadius: '15px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'white',
                  padding: '1px 20px',
                }}
              >
                {params.value ? 'Yes' : 'No'}
              </Box>
            </Box>
          );
      },
    },
    {
      headerName: 'Edit',
      width: 70,
      renderCell: ({ id }) => {
        const handleEdit = () => {
          navigate('/product/add', { state: { id, mode: 'edit' } });
        };
        return (
          <img
            style={{ height: '25px', marginLeft: '10px', marginTop: '10px', cursor: 'pointer' }}
            src={Edit}
            alt="edit"
            onClick={handleEdit}
          />
        );
      },
    },
  ];

  return (
    <Box>
      <Box display={'flex'} justifyContent={'space-between'}>
        <Heading24Semi text={'Product Deatails'} />
        <img onClick={() => setDeleteWarning(true)} src={deleteIcon} alt="filter" style={{ height: '25px', width: '25px' }} />
      </Box>
      <Box>
        <CustomDataGrid
          rowIdKey={'product_id'}
          columnsData={columnsData}
          rowsData={products}
          onSelectCheckbox={(r) => setselectedRows(r)}
        />
        <Pagination currentPage={page} totalPages={totalPages} onPageChange={(p) => setCurrentPage(p)} />
      </Box>
      <Modal
        open={deleteWarning}
        onClose={() => setDeleteWarning(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={DeleteModalstyle}>
          {selectedRows?.length ? (
            <>
              <Heading16Reg text="Do you want to Delete Users Permanantly?" />
              <Box sx={{ display: 'flex', gap: 2, mt: 1 }}>
                <Button variant="outlined" sx={{ borderRadius: '20px', textTransform: 'none' }} onClick={() => setDeleteWarning(false)}>
                  Cancel
                </Button>
                <Button variant="contained" color="error" sx={{ borderRadius: '20px', textTransform: 'none' }} onClick={handleDelete}>
                  Delete
                </Button>
              </Box>
            </>
          ) : (
            <Heading16Reg text="Please select Product first." />
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default SProductDetails;
