import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, styled } from '@mui/material';
import Colors from '../../../theme/colors/Color';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { addId, removeId } from './../../../redux/features/datagrid/datagridSlice';

const StyledDataGrid = styled(DataGrid)(() => ({
  border: 'none',
  minWidth: '800px',
  overflowX: 'auto',
  '& .MuiDataGrid-columnHeader': {
    backgroundColor: Colors.secondary1,
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    fontSize: '16px',
    fontFamily: 'poppins-semibold',
    color: Colors.white,
    overflow: 'visible',
    whiteSpace: 'normal',
    wordWrap: 'break-word',
  },
  '& .MuiDataGrid-cell': {
    fontSize: '14px',
    fontFamily: 'poppins-regular',
    color: Colors.text_black,
    border: 'none',
    borderBottom: '1px solid',
    borderColor: Colors.secondary1,
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    lineHeight: 1.5,
    padding: '8px',
  },
  '& .MuiDataGrid-row': {
    alignItems: 'start',
  },
  '& .MuiDataGrid-columnSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-cell:focus': {
    outline: 'none',
  },
  '& .highlighted': {
    backgroundColor: Colors.highlightRow,
  },
}));

const CustumDataGrid = (props) => {
  const dispatch = useDispatch();
  const selectedIds = useSelector((state) => state.datagrid.selectedIds);
  const { getHighlightCondition, rowsData, columnsData, rowIdKey, onSelectCheckbox } = props;

  const modifiedColumns = columnsData.map((column) => ({
    ...column,
    sortable: false,
    disableColumnMenu: true,
  }));

  const handleSelectionChange = (newSelection) => {
    newSelection.forEach((id) => {
      if (!selectedIds.includes(id)) {
        dispatch(addId(id));
      }
    });

    const currentPageIds = rowsData.map((row) => row[rowIdKey]);
    selectedIds.forEach((id) => {
      if (currentPageIds.includes(id) && !newSelection.includes(id)) {
        dispatch(removeId(id));
      }
    });

    onSelectCheckbox && onSelectCheckbox([...new Set([...selectedIds, ...newSelection])]);
  };

  const getRowClassName = (params) => {
    return getHighlightCondition && getHighlightCondition(params) ? 'highlighted' : '';
  };

  return (
    <Box
      sx={{
        width: '100%',
        marginTop: '20px',
        overflow: 'auto',
        '::-webkit-scrollbar': {
          display: 'none',
        },
      }}
    >
      <StyledDataGrid
        rows={rowsData || []}
        columns={modifiedColumns}
        disableRowSelectionOnClick
        hideFooter
        checkboxSelection
        autoHeight
        getRowId={(row) => row[rowIdKey]}
        rowSelectionModel={selectedIds.filter((id) => rowsData.map((row) => row[rowIdKey]).includes(id))}
        onRowSelectionModelChange={handleSelectionChange}
        getRowClassName={getRowClassName}
      />
    </Box>
  );
};

CustumDataGrid.propTypes = {
  rowIdKey: PropTypes.string.isRequired,
  rowsData: PropTypes.array,
  columnsData: PropTypes.array.isRequired,
  onSelectCheckbox: PropTypes.func,
  getHighlightCondition: PropTypes.func,
};

CustumDataGrid.defaultProps = {
  rowsData: [],
};

export default CustumDataGrid;
