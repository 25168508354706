import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getData } from '../../../../services/APIService';
import Colors from '../../../../theme/colors/Color';
import { Box, InputAdornment, styled, TextField } from '@mui/material';
import Pagination from '../../../../utils/component/pagination/Pagination';
import { Magnifier } from '../../../../theme/Images';

const headerCellStyle = {
  border: '1px solid #ddd',
  color: '#fff',
  backgroundColor: '#0093D3',
  fontFamily: 'poppins-regular',
  textTransform: 'capitalize',
};
const headerCellStyle1 = {
  border: '1px solid #ddd',
  color: 'red',
  fontFamily: 'poppins-regular',
};
const headerCellStyle2 = {
  border: '1px solid #ddd',
  fontFamily: 'poppins-regular',
};

const formatDate = (dateString) => {
  const date = new Date(dateString);

  const formattedDate = date.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  });

  const formattedTime = date.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  });

  return `${formattedDate}, ${formattedTime}`;
};
const limit = 50;
let totalPages = 1;
const CustomSearch = styled(TextField)(() => ({
  '& .MuiOutlinedInput-root': {
    '&.MuiInputBase-root': {
      height: '40px',
      borderRadius: '10px',
      width: '31.8vw',
      background: Colors.white,
    },
  },
}));

export default function FinacialDetailsTally() {
  const userData = JSON.parse(localStorage.getItem('userDetails'));
  const [finacialTallyList, setTallyFinacialList] = useState([]);
  const [page, setCurrentPage] = useState(1);
  const [keyword, setKeyword] = useState('');
  const fetchTallyFinancials = async () => {
    await getData(`/tally/financials?page=${page}&limit=${limit}&keyword=${keyword}`, {}, userData.token)
      .then((res) => {
        if (res.data.data && res.data.data?.result) {
          setTallyFinacialList(res.data.data?.result || []);
          totalPages = res.data.data?.totalPages || 1;
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchTallyFinancials();
  }, [page, keyword]);
  const handleSearch = (e) => {
    const keyword = e.target.value.trim();
    setKeyword(keyword);
    setCurrentPage(1);
  };
  return (
    <>
      <Box
        sx={{
          marginTop: '20px',
          height: '10.4vh',
          borderRadius: '10px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: ' center',
          padding: '0px 30px',
          background: Colors.bg_strip,
        }}
      >
        <CustomSearch
          placeholder="Search Financial Details"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img src={Magnifier} alt="Search" style={{ width: '20px', height: '20px' }} />
              </InputAdornment>
            ),
          }}
          onChange={handleSearch}
        />
      </Box>
      {finacialTallyList?.map((cu) => (
        <TableContainer key={cu.customer_code} component={Paper} sx={{ mb: 2 }}>
          <Table sx={{ minWidth: 650, border: '1px solid #ddd' }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={!cu.customer_id ? headerCellStyle1 : null} colSpan={4}>
                  Customer Code : {cu.customer_code}
                </TableCell>
                <TableCell colSpan={8}>Last Updated :{formatDate(cu.data[cu.data.length - 1]?.createdAt)}</TableCell>
              </TableRow>
            </TableHead>
            <TableHead>
              <TableRow sx={{ backgroundColor: '#0093D3' }}>
                <TableCell sx={headerCellStyle}>Date+time</TableCell>
                <TableCell sx={headerCellStyle}>Credit terms</TableCell>
                <TableCell sx={headerCellStyle}>Credit limit</TableCell>
                <TableCell sx={headerCellStyle}>Add-on</TableCell>
                <TableCell sx={headerCellStyle}>Balance credit limit</TableCell>
                <TableCell sx={headerCellStyle}>Deposit (receipt vchr)</TableCell>
                <TableCell sx={headerCellStyle}>Return (credit note sales vchr)</TableCell>
                <TableCell sx={headerCellStyle}>Available credit limit=Bal + deposit + return</TableCell>
                <TableCell sx={headerCellStyle}>Order no</TableCell>
                <TableCell sx={headerCellStyle}>Order Amount (grand total)</TableCell>
                <TableCell sx={headerCellStyle}>Payment Terms</TableCell>
                <TableCell sx={headerCellStyle}>Closing credit limit</TableCell>
                <TableCell sx={headerCellStyle}>Remarks</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cu.data?.map((row) => (
                <TableRow key={row.id} sx={headerCellStyle2}>
                  <TableCell sx={headerCellStyle2}>{formatDate(row.createdAt)}</TableCell>
                  <TableCell sx={headerCellStyle2}>{row.credit_terms}</TableCell>
                  <TableCell sx={headerCellStyle2}>{row.credit_limit}</TableCell>
                  <TableCell sx={headerCellStyle2}>{row.add_on_credit}</TableCell>
                  <TableCell sx={headerCellStyle2}>{row.bal_credit}</TableCell>
                  <TableCell sx={headerCellStyle2}>{row.deposit}</TableCell>
                  <TableCell sx={headerCellStyle2}>{row.total_return}</TableCell>
                  <TableCell sx={headerCellStyle2}>{row.avl_credit}</TableCell>
                  <TableCell sx={headerCellStyle2}>{row.order_no}</TableCell>
                  <TableCell sx={headerCellStyle2}>{row.order_amount}</TableCell>
                  <TableCell sx={headerCellStyle2}>{row.payment_terms}</TableCell>
                  <TableCell sx={headerCellStyle2}>{row.closing_credit}</TableCell>
                  <TableCell sx={headerCellStyle2}>{row.remarks}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ))}
      <Pagination currentPage={page} totalPages={totalPages} onPageChange={(p) => setCurrentPage(p)} />
    </>
  );
}
