import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
// import BorderColorIcon from '@mui/icons-material/BorderColor';
import { Add, Delete, Edit } from '../../../../theme/Images.js';
import { getData, postData, updateData } from '../../../../services/APIService'; // Ensure deleteData is imported
import { Button, Modal, TextField, CircularProgress, Typography, styled, Box } from '@mui/material';
import { useDispatch } from 'react-redux'; // Import dispatch for showing snackbar
import { showSnackbar } from '../../../../redux/features/snackbar/snackbarSlice.js';
import { Heading20Semi } from '../../../../utils/component';

// const headerCellStyle = {
//   border: '1px solid #ddd',
//   color: '#fff',
//   backgroundColor: '#0093D3',
//   fontFamily: 'poppins-regular',
// };
const headerCellStyle2 = {
  border: '1px solid #0093D3',
  fontFamily: 'poppins-regular',
};
const CustomBtnBox = styled(Box)(() => ({
  height: '35px',
  width: '35px',
  borderRadius: '6px',
  padding: '10px',
  background: '#70FACB4D',
  textAlign: 'center',
  cursor: 'pointer',
  marginBottom: 20,
}));

export default function FreightPrice() {
  const [price, setPrice] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]); // To track selected rows
  const [isEdit, setIsEdit] = useState(false);
  const [formData, setFormData] = useState({
    from: '',
    to: '',
    expected_delivery_days: '',
    lt_10: '',
    bt_10_20: '',
    gt_20: '',
  });
  const userData = JSON.parse(localStorage.getItem('userDetails'));

  const [loading, setLoading] = useState(false); // Loading state
  const dispatch = useDispatch(); // For showing snackbar

  const fetchFreightData = async () => {
    await getData(`/mstFreightRates/getAll`)
      .then((res) => {
        if (res.data.data) {
          setPrice(res.data.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchFreightData();
  }, []);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setIsEdit(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const edit = async (id) => {
    try {
      const response = await getData('/mstFreightRates/' + id, {}, userData.token);
      setIsEdit(true);
      setFormData(response.data.data);
      handleOpen();
    } catch (error) {
      dispatch(showSnackbar({ message: error.message, severity: 'error' }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      if (formData.fr_id) await updateData('/mstFreightRates/' + formData.fr_id, formData, {}, userData.token);
      else await postData('/mstFreightRates/add', formData, {}, userData.token);
      dispatch(showSnackbar({ message: `Location ${formData.fr_id ? 'updated' : 'added'} successfully`, severity: 'success' }));
      fetchFreightData();
      handleClose();
    } catch (error) {
      dispatch(showSnackbar({ message: error.message, severity: 'error' }));
    } finally {
      setLoading(false);
    }

    setFormData({
      from: '',
      to: '',
      expected_delivery_days: '',
      lt_10: '',
      bt_10_20: '',
      gt_20: '',
    });
  };

  const handleSelectRow = (id) => {
    setSelectedIds((prevSelected) =>
      prevSelected.includes(id) ? prevSelected.filter((selectedId) => selectedId !== id) : [...prevSelected, id]
    );
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const allIds = price.map((row) => row.fr_id);
      setSelectedIds(allIds);
    } else {
      setSelectedIds([]);
    }
  };

  const handleBulkDelete = async () => {
    if (selectedIds.length === 0) {
      dispatch(showSnackbar({ message: 'No items selected for deletion', severity: 'error' }));
      return;
    }

    if (window.confirm('Are you sure you want to delete selected items?')) {
      try {
        await postData('/mstFreightRates/bulkDelete', { ids: selectedIds }, {}, userData.token);
        dispatch(showSnackbar({ message: 'Selected items deleted successfully', severity: 'success' }));
        fetchFreightData();
        setSelectedIds([]); // Clear the selection after deletion
      } catch (error) {
        dispatch(showSnackbar({ message: error.message, severity: 'error' }));
      }
    }
  };

  return (
    <div>
      <Heading20Semi text="Freight Master" />

      <Box sx={{ display: 'flex', gap: '20px', justifyContent: 'flex-end' }}>
        <CustomBtnBox onClick={handleOpen}>
          <img src={Add} alt="add" style={{ height: '22px', width: '22px' }} />
          <Typography sx={{ fontSize: '10px' }}>ADD</Typography>
        </CustomBtnBox>
        <CustomBtnBox onClick={handleBulkDelete}>
          <img src={Delete} alt="delete" style={{ height: '21px', width: '20px' }} />
          <Typography sx={{ fontSize: '10px' }}>DELETE</Typography>
        </CustomBtnBox>
      </Box>

      <TableContainer component={Paper}>
        <Table aria-label="simple table" sx={{ minWidth: 650, border: '1px solid #ddd' }}>
          <TableHead>
            <TableRow sx={{ backgroundColor: '#0093D3' }}>
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={selectedIds.length > 0 && selectedIds.length < price.length}
                  checked={price.length > 0 && selectedIds.length === price.length}
                  onChange={handleSelectAll}
                />
              </TableCell>
              <TableCell sx={{ border: '1px solid #ffffff', fontWeight: 600, color: '#ffffff' }}>From</TableCell>
              <TableCell sx={{ border: '1px solid #ffffff', fontWeight: 600, color: '#ffffff' }}>To</TableCell>
              <TableCell sx={{ border: '1px solid #ffffff', fontWeight: 600, color: '#ffffff' }}>Days</TableCell>
              <TableCell sx={{ border: '1px solid #ffffff', fontWeight: 500, color: 'red' }}>Less than 10 MT</TableCell>
              <TableCell sx={{ border: '1px solid #ffffff', fontWeight: 500, color: 'red' }}>10 - 20 MT</TableCell>
              <TableCell sx={{ border: '1px solid #ffffff', fontWeight: 500, color: 'red' }}>21 MT and above</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {price.map((row) => (
              <TableRow key={row.fr_id}>
                <TableCell padding="checkbox" sx={headerCellStyle2}>
                  <Checkbox checked={selectedIds.includes(row.fr_id)} onChange={() => handleSelectRow(row.fr_id)} />
                </TableCell>
                <TableCell sx={headerCellStyle2}>{row.from}</TableCell>
                <TableCell sx={headerCellStyle2}>{row.to}</TableCell>
                <TableCell sx={headerCellStyle2}>{row.expected_delivery_days}</TableCell>
                <TableCell sx={headerCellStyle2}>{row.lt_10}</TableCell>
                <TableCell sx={headerCellStyle2}>{row.bt_10_20}</TableCell>
                <TableCell sx={headerCellStyle2}>{row.gt_20}</TableCell>
                <TableCell sx={headerCellStyle2}>
                  <img
                    style={{ height: '25px', marginLeft: '10px', marginTop: '5px', cursor: 'pointer' }}
                    src={Edit}
                    alt="edit"
                    onClick={() => edit(row.fr_id)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Modal for adding new freight rates */}
      <Modal open={open} onClose={handleClose} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Paper sx={{ padding: 2, width: 400 }}>
          <Typography sx={{ fontWeight: 'bold', fontSize: 20 }}>{isEdit ? 'Edit ' : 'Add New '}Freight Rates</Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              label="From Location"
              name="from"
              value={formData.from}
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
              sx={{
                '& .MuiInputLabel-asterisk': {
                  color: 'red',
                },
              }}
            />
            <TextField
              label="To Location"
              name="to"
              value={formData.to}
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
              sx={{
                '& .MuiInputLabel-asterisk': {
                  color: 'red',
                },
              }}
            />
            <TextField
              label="Expected Delivery Days"
              name="expected_delivery_days"
              value={formData.expected_delivery_days}
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
              sx={{
                '& .MuiInputLabel-asterisk': {
                  color: 'red',
                },
              }}
            />
            <TextField
              label="Less than 10 MT"
              name="lt_10"
              value={formData.lt_10}
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
              sx={{
                '& .MuiInputLabel-asterisk': {
                  color: 'red',
                },
              }}
            />
            <TextField
              label="10 - 20 MT"
              name="bt_10_20"
              value={formData.bt_10_20}
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
              sx={{
                '& .MuiInputLabel-asterisk': {
                  color: 'red',
                },
              }}
            />
            <TextField
              label="More than 20 MT"
              name="gt_20"
              value={formData.gt_20}
              onChange={handleChange}
              fullWidth
              margin="normal"
              required
              sx={{
                '& .MuiInputLabel-asterisk': {
                  color: 'red',
                },
              }}
            />
            <Button type="submit" variant="contained" sx={{ mt: 2 }} disabled={loading}>
              {loading ? <CircularProgress size={24} /> : 'Submit'}
            </Button>
          </form>
        </Paper>
      </Modal>
    </div>
  );
}
