export const FILTER_CONSTANT = {
  enquiry_type: {
    PRODUT_ENQUIRY: 'Product Enquiry',
    CALL_BACK: 'Call Back',
    GENERAL: 'General',
  },
  enquiry_status: {
    OPEN: 'Open',
    IN_PROGRESS: 'In Progress',
    CLOSE: 'Close',
  },
  firm_type: {
    PR: 'PR',
    PP: 'PP',
    PVL: 'PVL',
  },
  category: {
    trader: 'Trader',
    manufacturer: 'Manufacturer',
    oem: 'OEM',
  },
  vendor_type: {
    logistics: 'Logistics',
    warehouse: 'Warehouse',
  },
  status: {
    active: 'Active',
    inactive: 'Inactive',
  },
  product_status: {
    ACTIVE: 'Active',
    INACTIVE: 'Inactive',
    RETIRED: 'Retired',
  },
  order_status: {
    Pending: 'Pending',
    Awaiting: 'Awaiting',
    Accepted: 'Accepted',
    Complete: 'Complete',
    Processing: 'Processing',
    Generated: 'Generated',
    Cancelled: 'Cancelled',
  },
  order_type: {
    Advance: 'Advance',
    LC: 'LC',
    BG: 'BG',
    Pdc: 'PDC',
  },
  order_terms: {
    days7: '7 days',
    days15: '15 days',
    days30: '30 days',
    days45: '45 days',
  },
  order_fulfilment: {
    TransportationByBOS: 'Transportation by BOS',
    Selfpickup: 'Self pickup',
  },
  community_Realm: {
    Segment: 'Segment',
    Category: 'Category',
    // Location: 'Location',
  },
};
