import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getData } from '../../../services/APIService';

export const fetchCTPOMaster = createAsyncThunk('CT_PO_Masters/fetchCTPOMaster', async () => {
  const [creditTerms, paymentOptions] = await Promise.all([
    await getData('/mst_credit_terms/', {}),
    await getData('/mst_payment_options/', {}),
  ]);
  return {
    creditTerms: creditTerms.data.data,
    paymentOptions: paymentOptions.data.data,
  };
});

const initialState = {
  creditTerms: [],
  paymentOptions: [],
};

export const CT_PO_MasterSlice = createSlice({
  name: 'CT_PO_Masters',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCTPOMaster.fulfilled, (state, action) => {
      state.creditTerms = action.payload.creditTerms;
      state.paymentOptions = action.payload.paymentOptions;
    });
  },
});

export default CT_PO_MasterSlice.reducer;
