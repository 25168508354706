// This is data used to show in dropdowns list in forms
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getData } from '../../../services/APIService';
const userData = JSON.parse(localStorage.getItem('userDetails'));

// Fetch all master data
export const fetchAllMasters = createAsyncThunk('masters/fetchAll', async () => {
  const [typeOfFirms, userCategories, userOEMs, preferredSuppliers, marketSegments, userDepartments] = await Promise.all([
    getData('/mstTypeOfFirm/getList', {}, userData.token),
    getData('/mstUserCategory/getList', {}, userData.token),
    getData('/mstUserOEM/getList', {}, userData.token),
    getData('/mstPreferredSupplier/getList', {}, userData.token),
    getData('/mstMarketSegment/getList', {}, userData.token),
    getData('/mstUserDepartment/getList', {}, userData.token),
  ]);

  return {
    mstTypeOfFirm: typeOfFirms.data.data,
    mstUserCategory: userCategories.data.data,
    mstUserOEM: userOEMs.data.data,
    mstPreferredSupplier: preferredSuppliers.data.data,
    mstMarketSegment: marketSegments.data.data,
    mstUserDepartment: userDepartments.data.data,
  };
});

export const fetchMasterByType = createAsyncThunk('masters/fetchByType', async (type) => {
  const response = await getData(`/${type}/getList`, {}, userData.token);
  return { type, data: response.data.data };
});

const initialState = {
  data: {},
  loading: false,
  error: null,
};

export const mastersSlice = createSlice({
  name: 'masters',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Fetch all data
    builder
      .addCase(fetchAllMasters.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllMasters.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload; // Store all master data
      })
      .addCase(fetchAllMasters.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

    builder
      .addCase(fetchMasterByType.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMasterByType.fulfilled, (state, action) => {
        state.loading = false;
        state.data[action.payload.type] = action.payload.data;
      })
      .addCase(fetchMasterByType.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default mastersSlice.reducer;
