import { Box, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Colors from '../../../../theme/colors/Color';
import { Heading16Reg } from '../../../../common-components';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSuppliers } from '../../../../redux/features/user management/supplierListSlice';
import { FILTER_CONSTANT } from '../../../../utils/constants/filters';
import { fetchKam } from '../../../../redux/features/user management/kamListSlice';
import MultiValCombobox from '../../../../utils/component/combo-box/MultiValCombobox';

const CustomBox = styled(Box)(() => ({
  padding: '15px',
  borderBottom: '1px solid',
  borderColor: Colors.secondary1,
  cursor: 'pointer',
}));

const BoxContainer = styled(Box)(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '10px',
}));

const BoxItem = styled(Box)(() => ({
  background: Colors.bg_filterItem,
  padding: '3px 15px',
  borderRadius: '20px',
  cursor: 'pointer',
}));

const filterItems = ['Name Of Supplier', 'Firm Type', 'Category', 'KAM', 'Status'];

const BosSupplierFilter = ({ filterData, selectedFilters }) => {
  const [filters, setFilters] = useState(filterData);
  const dispatch = useDispatch();
  const allSuppliers = useSelector((state) => state.suppliers.data);
  const allKams = useSelector((state) => state.kam.data);
  useEffect(() => {
    setFilters(filterData);
  }, [filterData]);
  useEffect(() => {
    if (!allSuppliers || allSuppliers.length === 0) {
      dispatch(fetchSuppliers());
    }
  }, [dispatch, allSuppliers]);

  useEffect(() => {
    if (!allKams || allKams.length === 0) {
      dispatch(fetchKam());
    }
  }, [dispatch, allKams]);

  const [selectedItem, setSelectedItem] = useState('Name Of Supplier');

  const selectFilter = (item) => {
    setSelectedItem(item);
  };

  const isValueSelected = (category, value) => {
    return filters[category]?.includes(value);
  };

  const toggleSelect = (category, value) => {
    setFilters((prev) => {
      const isSelected = prev[category].includes(value);
      const updatedFilters = {
        ...prev,
        [category]: isSelected ? prev[category].filter((item) => item !== value) : [...prev[category], value],
      };
      selectedFilters(updatedFilters); // Notify parent component with updated filters
      return updatedFilters;
    });
  };

  return (
    <Box display={'flex'} width={'35vw'} fontFamily={'poppins-regular'}>
      <Box width={'50%'} borderRight={'1px solid'} borderColor={Colors.secondary1}>
        {filterItems.map((item, i) => (
          <CustomBox key={i} sx={{ background: selectedItem === item ? Colors.bg_strip : '' }} onClick={() => selectFilter(item)}>
            <Heading16Reg text={item} />
          </CustomBox>
        ))}
      </Box>
      <Box width={'50%'} padding={2}>
        {selectedItem === 'Name Of Supplier' && (
          <MultiValCombobox
            optionsLabel={'firm_name'}
            value={allSuppliers.filter((supplier) => filters.firm_name.includes(supplier.firm_name))}
            options={allSuppliers}
            onChangeValue={(newValue) =>
              setFilters((prev) => {
                const updatedFilters = { ...prev, firm_name: newValue.map((item) => item.firm_name) };
                selectedFilters(updatedFilters);
                return updatedFilters;
              })
            }
            bgColor={Colors.bg_filterItem}
            isMultiSelect={true}
            placeholder={'Type/Select Name Of Supplier'}
          />
        )}
        {selectedItem === 'Firm Type' && (
          <BoxContainer>
            {Object.keys(FILTER_CONSTANT.firm_type).map((type, index) => (
              <BoxItem
                key={index}
                sx={{
                  background: isValueSelected('firm_type', type) ? Colors.bg_strip : Colors.bg_filterItem,
                }}
                onClick={() => toggleSelect('firm_type', type)}
              >
                {type}
              </BoxItem>
            ))}
          </BoxContainer>
        )}
        {selectedItem === 'Category' && (
          <BoxContainer>
            {Object.keys(FILTER_CONSTANT.category).map((category, index) => (
              <BoxItem
                key={index}
                sx={{
                  background: isValueSelected('firm_category', category) ? Colors.bg_strip : Colors.bg_filterItem,
                }}
                onClick={() => toggleSelect('firm_category', category)}
              >
                {FILTER_CONSTANT.category[category]}
              </BoxItem>
            ))}
          </BoxContainer>
        )}
        {selectedItem === 'KAM' && (
          <MultiValCombobox
            optionsLabel={'name'}
            value={allKams.filter((kam) => filters.kam_id.includes(kam.user_id))}
            options={allKams}
            onChangeValue={(newValue) =>
              setFilters((prev) => {
                const updatedFilters = { ...prev, kam_id: newValue.map((item) => item.user_id) };
                selectedFilters(updatedFilters);
                return updatedFilters;
              })
            }
            bgColor={Colors.bg_filterItem}
            isMultiSelect={true}
            placeholder={'Type/Select Name Of KAM'}
          />
        )}
        {selectedItem === 'Status' && (
          <BoxContainer>
            {Object.keys(FILTER_CONSTANT.status).map((status, index) => (
              <BoxItem
                key={index}
                sx={{
                  background: isValueSelected('status', status) ? Colors.bg_strip : Colors.bg_filterItem,
                }}
                onClick={() => toggleSelect('status', status)}
              >
                {FILTER_CONSTANT.status[status]}
              </BoxItem>
            ))}
          </BoxContainer>
        )}
        {/* {selectedItem === 'Product Category' && (
          <BoxContainer>
            <BoxItem>As Rolled</BoxItem>
            <BoxItem>HR/CR</BoxItem>
            <BoxItem>As Cast</BoxItem>
            <BoxItem>Wire Rod</BoxItem>
            <BoxItem>Bright Bar</BoxItem>
            <BoxItem>Heat Treated Bar</BoxItem>
            <BoxItem>As Forged</BoxItem>
          </BoxContainer>
        )} */}
      </Box>
    </Box>
  );
};

BosSupplierFilter.propTypes = {
  selectedFilters: PropTypes.func.isRequired,
  filterData: PropTypes.any,
};

export default BosSupplierFilter;
