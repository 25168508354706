import Colors from '../../theme/colors/Color';

export const getorderStatusBgColor = (status) => {
  switch (status) {
    case 'Complete':
      return Colors.success;
    case 'Pending':
      return Colors.error;
    case 'Awaiting':
      return Colors.awaiting;
    case 'Generated':
      return Colors.status_gnrtd;
    case 'Processing':
      return Colors.proceesingDark;
    case 'Accepted':
      return Colors.primary;
    case 'Cancelled':
      return Colors.error;
    default:
      return Colors.transparent;
  }
};
