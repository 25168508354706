import { configureStore } from '@reduxjs/toolkit';
import PM_MastersReducer from '../features/masters/PM_MastersSlice';
import firmReducer from '../features/user management/firmSlice';
import snackbarReducer from '../features/snackbar/snackbarSlice';
import supplierListReducer from '../features/user management/supplierListSlice';
import kamListReducer from '../features/user management/kamListSlice';
import enquiryReasonsReducer from '../features/masters/enquiryMasterSlice';
import CT_PO_MasterReducer from '../features/masters/CT_PO_MasterSlice';
import mastersListReducer from '../features/masters/mastersSlice';
import datagridReducer from '../features/datagrid/datagridSlice';
import notificationReducer from '../features/notification/notificationSlice';

export const store = configureStore({
  reducer: {
    PM_Masters: PM_MastersReducer,
    firm: firmReducer,
    snackbar: snackbarReducer,
    suppliers: supplierListReducer,
    kam: kamListReducer,
    enquiryReasons: enquiryReasonsReducer,
    CT_PO_Masters: CT_PO_MasterReducer,
    masters: mastersListReducer,
    datagrid: datagridReducer,
    notification: notificationReducer,
  },
});
