import React, { useEffect, useRef, useState } from 'react';
import { Box, FormControl, FormControlLabel, FormLabel, Grid, RadioGroup, Typography, styled } from '@mui/material';
import { CustomRadio, FilledBtn, Heading16Reg, Heading26Bold, OutlineBtn } from '../../../../common-components';
import Colors from '../../../../theme/colors/Color';
import GeneralEnquiry from './GeneralEnquiry';
import ProductEnquiry from './ProductEnquiry';
import CallBackEnquiry from './CallBackEnquiry';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { attachmentIcon } from '../../../../theme/Images';
import { getData, putFileData, updateData } from '../../../../services/APIService';
import { Combobox, InputTextField } from '../../../../utils/component';
import { fetchEnquiryReasonsMaster } from '../../../../redux/features/masters/enquiryMasterSlice';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import Comment from '../../../../utils/component/comment/Comment';

const Heading24Semi = styled(Typography)(() => ({
  fontSize: '14px',
  fontFamily: 'poppins-regular',
  lineHeight: '21px',
}));
const Heading16semi = styled(Typography)(() => ({
  fontSize: '16px',
  fontFamily: 'poppins-semibold',
  lineHeight: '24px',
}));
const ManageItemBox = styled(Box)(() => ({
  height: '70px',
}));

const natureOfEnq = [
  { value: 'CRR', label: 'CRR' },
  { value: 'CRR NBD', label: 'CRR NBD' },
  { value: 'NBD', label: 'NBD' },
];

const statusForBos = [
  { value: 'Open', label: 'Open' },
  { value: 'In Progress', label: 'In Progress' },
  { value: 'Converted and Close', label: 'Converted and Close' },
  { value: 'Not Converted and Close', label: 'Not Converted and Close' },
];

const customerCategory = [
  { value: 'Manufacturer', label: 'Manufacturer' },
  { value: 'Trader', label: 'Trader' },
  { value: 'Processor', label: 'Processor' },
  { value: 'Other', label: 'Other' },
];

const EditEnquiryForm = () => {
  const location = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem('userDetails'));
  const fileInputRef = useRef(null);
  const [attachFileName, setAttachFileName] = useState([]);
  const [enquiryData, setEnquiryData] = useState({
    attachment: null,
    attachment_name: null,
    customer_category: null,
    customer_email: '',
    customer_name: null,
    customer_no: '',
    date_of_enquiry: '',
    delivery_location: '',
    enquiry_details: '',
    enquiry_id: '',
    enquiry_number: '',
    enquiry_type: '',
    firm_name: null,
    grade_name: '',
    gst_no: '',
    kam_name: null,
    nature_of_enquiry: null,
    offer_invoice: null,
    other_grade_name: '',
    other_pr_name: '',
    other_size_name: '',
    pc_name: '',
    pr_name: '',
    quantity: '',
    reason: null,
    remarks: [],
    revenue: null,
    selected_shape_size: {},
    size_name: '',
    spc_name: '',
    status: '',
    status_for_bos: null,
  });
  const [newRemark, setNewRemark] = useState('');
  const [status, setStatus] = useState('open');
  const [bosStatus, setBosStatus] = useState('open');
  const { state } = location;
  const { eqType, isCallFrom } = state || {};

  const viewMode = isCallFrom == 'view';
  const dispatch = useDispatch();
  const reasons = useSelector((state) => state.enquiryReasons.reasons.data);
  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    if (!reasons) dispatch(fetchEnquiryReasonsMaster());
  }, []);
  const fetchData = async () => {
    await getData(`/enquiry/${id}`, {}, userData.token)
      .then((res) => {
        if (res.data.data) {
          setEnquiryData(res.data.data);
          setStatus(res.data.data.status);
          setBosStatus(res.data.data.status_for_bos);
          const attachment = res.data.data.attachment;
          const fullFileName = attachment ? attachment.split('/').pop() : '';
          const fileName = fullFileName ? fullFileName.split('_').slice(4).join('_') : '';
          setEnquiryData((prev) => ({ ...prev, attachment_name: fileName }));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleRemarkChange = (e) => {
    setNewRemark(e.target.value);
  };

  const handleStatusBos = (status) => {
    setEnquiryData((prev) => ({ ...prev, status_for_bos: status?.label }));
  };
  const handleStatusChange = (e) => {
    setEnquiryData((prev) => ({ ...prev, status: e?.target?.value }));
  };

  const disableStatusOption = (option, bosStatus) => {
    // if (bosStatus === 'Open' && option.value === 'Open') {
    //   return true;
    // }
    // if (bosStatus === 'In Progress' && (option.value === 'Open' || option.value === 'In Progress')) {
    if (bosStatus === 'In Progress' && option.value === 'Open') {
      return true;
    }
    return false;
  };

  const handleAttachFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setAttachFileName(file);
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'Open':
        return Colors.primary;
      case 'In Progress':
        return Colors.inprogress;
      case 'Close':
        return Colors.error;
      default:
        return Colors.transparent;
    }
  };

  const handleUpdate = async () => {
    // if (attachFileName) {
    //   const data = new FormData();
    //   data.append('module_attachment_id', enquiryData.enquiry_id);
    //   data.append('type', 'enquiry');
    //   data.append('attachment_name', 'enquiry_attachment');
    //   data.append('pdf', attachFileName);

    //   try {
    //     for (let [key, value] of data.entries()) {
    //       console.log(key, value);
    //     }
    //     console.log('Sending file:', data);
    //     const res = await postFileData(`/image/uploadImages`, data, {}, userData.token);
    //     if (res && res.data) console.log('enquiry update', res);
    //     alert('file updated Successfully.');
    //   } catch (error) {
    //     console.error(error);
    //     alert('Something went wrong.');
    //   }
    // }
    let newEnqData = enquiryData;
    if (newRemark) {
      const newRemarkObj = {
        date: new Date(),
        username: userData.user.name,
        remark: newRemark,
      };
      const updatedRemarks = [newRemarkObj, ...(Array.isArray(enquiryData.remarks) ? enquiryData.remarks : [])];
      newEnqData = { ...enquiryData, remarks: updatedRemarks };
    }
    if (attachFileName?.name) {
      let data = new FormData();
      for (const key in newEnqData) {
        if (Object.prototype.hasOwnProperty.call(newEnqData, key)) {
          const value = newEnqData[key];
          data.append(key, value);
        }
      }
      data.append('file', attachFileName);
      await putFileData(`/enquiry/edit/${id}`, data, {}, userData.token)
        .then(() => {
          alert('Enquiry updated Successfully.');
        })
        .catch((error) => {
          const errorMessage = error.response?.data?.message ? error.response?.data?.message : error.message;
          alert(errorMessage);
        });
    } else {
      await updateData(`/enquiry/edit/${id}`, newEnqData, {}, userData.token)
        .then(() => {
          alert('Enquiry updated Successfully.');
        })
        .catch((error) => {
          console.error(error);
          const errorMessage = error.response?.data?.message ? error.response?.data?.message : error.message;
          alert(errorMessage);
        });
    }
    navigate(-1);
  };
  const clearAttachment = () => {
    setEnquiryData({ ...enquiryData, attachment: null });
  };
  const isOpenDisabled = status === 'In Progress' || status === 'Close';
  const isInProgressDisabled = status === 'Close';
  return (
    <Box>
      <Heading26Bold text={viewMode ? 'Enquiry Details' : 'Edit Enquiry'} />
      <Box mt={'18px'} display={'flex'}>
        <Typography sx={{ fontSize: '20px', fontFamily: 'poppins-semibold', color: Colors.text_413e3e }}>
          Enquiry No: {enquiryData.enquiry_number}
        </Typography>
        {/* <Heading24Semi
          sx={{
            color: Colors.primary,
            textDecorationLine: 'underline',
            marginLeft: '20vw',
          }}
        >
          Export as pdf
        </Heading24Semi> */}
      </Box>
      <Box display={'flex'} justifyContent={'space-between'}>
        <Heading24Semi>Date of Enquiry: {new Date(enquiryData.createdAt).toLocaleDateString('en-GB')}</Heading24Semi>
        <Box display={'flex'}>
          <Heading24Semi>{enquiryData.status}</Heading24Semi>
          <Box
            sx={{ background: getStatusColor(enquiryData.status), height: '15px', width: '15px', borderRadius: '50%', marginLeft: '5px' }}
          ></Box>
        </Box>
      </Box>
      <Grid container sx={{ marginTop: '4px', height: { xs: '180px', sm: '90px' }, background: Colors.bg_form_section, padding: '12px' }}>
        <Grid item xs={12} sm={6} lgm={4} display={'flex'}>
          <Heading16semi sx={{ marginRight: '5px' }}>Name Of Firm:</Heading16semi>
          <Heading16Reg text={enquiryData.firm?.firm_name} />
        </Grid>
        <Grid item xs={12} sm={6} lgm={4} display={'flex'}>
          <Heading16semi sx={{ marginRight: '5px' }}>Email:</Heading16semi>
          <Heading16Reg text={enquiryData.user?.email} />
        </Grid>
        <Grid item xs={12} sm={6} lgm={4} display={'flex'}>
          <Heading16semi sx={{ marginRight: '5px' }}>GST No:</Heading16semi>
          <Heading16Reg text={enquiryData.firm?.gst_no} />
        </Grid>
        <Grid item xs={12} sm={6} lgm={4} display={'flex'}>
          <Heading16semi sx={{ marginRight: '5px' }}>Customer Name:</Heading16semi>
          <Heading16Reg text={enquiryData.user?.name} />
        </Grid>
        <Grid item xs={12} sm={6} lgm={4} display={'flex'}>
          <Heading16semi sx={{ marginRight: '5px' }}>Contact No:</Heading16semi>
          <Heading16Reg text={enquiryData.user?.mobile_number} />
        </Grid>
      </Grid>
      <Box mt={'30px'}>
        {eqType === 'General' && <GeneralEnquiry enquiryData={enquiryData} />}
        {eqType === 'Product Enquiry' && <ProductEnquiry enquiryData={enquiryData} />}
        {eqType === 'Call Back' && <CallBackEnquiry enquiryData={enquiryData} />}
      </Box>
      <Box sx={{ background: Colors.bg_form_section, marginTop: '10px', padding: '10px' }}>
        <Typography sx={{ fontFamily: 'poppins-regular', fontSize: '20px', fontWeight: 600 }}>Manage Enquiry</Typography>
        <Grid container marginTop={'15px'}>
          <Grid item xs={12} lgm={6} paddingRight={{ xs: 0, md: '5px' }}>
            <ManageItemBox>
              <FormControl>
                <FormLabel>
                  <Typography sx={{ fontFamily: 'poppins-regular', fontSize: '16px', color: Colors.text_black }}>Status</Typography>
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={enquiryData.status || 'Open'}
                  onChange={handleStatusChange}
                >
                  <FormControlLabel
                    value="Open"
                    control={<CustomRadio />}
                    label="Open"
                    disabled={viewMode || isOpenDisabled}
                    sx={{
                      '& .MuiFormControlLabel-label': {
                        fontFamily: 'poppins-regular',
                      },
                    }}
                  />
                  <FormControlLabel
                    value="In Progress"
                    control={<CustomRadio />}
                    label="In Progress"
                    disabled={viewMode || isInProgressDisabled}
                    sx={{
                      '& .MuiFormControlLabel-label': {
                        fontFamily: 'poppins-regular',
                      },
                    }}
                  />
                  <FormControlLabel
                    value="Close"
                    control={<CustomRadio />}
                    label="Closed"
                    disabled={viewMode}
                    sx={{
                      '& .MuiFormControlLabel-label': {
                        fontFamily: 'poppins-regular',
                      },
                    }}
                  />
                </RadioGroup>
              </FormControl>
            </ManageItemBox>
            <ManageItemBox>
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleAttachFileChange}
                disabled={viewMode}
                style={{
                  display: 'none',
                }}
              />
              <Box
                sx={{
                  height: '42px',
                  display: 'flex',
                  alignItems: 'center',
                  position: 'relative',
                  gap: '20px',
                  border: '0.5px solid',
                  borderColor: Colors.placeholder,
                  borderRadius: '5px',
                  padding: '0px 10px',
                  background: Colors.select_bg,
                  cursor: 'pointer',
                  '&:hover': {
                    border: '1px solid',
                  },
                }}
                onClick={() => {
                  if (enquiryData.attachment) {
                    window.open(enquiryData.attachment, '_blank');
                  } else {
                    fileInputRef.current.click();
                  }
                }}
              >
                <img src={attachmentIcon} alt="attach" />
                <Typography sx={{ fontFamily: 'poppins-regular', color: Colors.text_413e3e }}>
                  {enquiryData.attachment ? enquiryData.attachment_name : attachFileName?.name || 'Attachment(PO/OA)'}
                </Typography>
                {enquiryData.attachment && (
                  <CloseIcon
                    sx={{
                      position: 'relative',
                      right: '0px',
                      // left: '70%',
                      top: '30%',
                      transform: 'translateY(-50%)',
                      cursor: 'pointer',
                      color: Colors.text_413e3e,
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      clearAttachment();
                    }}
                  />
                )}
              </Box>
            </ManageItemBox>
            <ManageItemBox>
              <Combobox
                label={'Nature Of Enquiry'}
                optionsLabel={'label'}
                options={natureOfEnq}
                isDisable={viewMode}
                value={enquiryData.nature_of_enquiry}
                onChangeValue={(op) => setEnquiryData((prev) => ({ ...prev, nature_of_enquiry: op?.label }))}
                bgColor={Colors.select_bg}
              />
            </ManageItemBox>
            <ManageItemBox>
              <InputTextField
                label={'Price Offered'}
                name={'offer_invoice'}
                value={enquiryData.offer_invoice}
                isDisable={viewMode}
                type={'number'}
                bgColor={Colors.select_bg}
                onChange={(e) => setEnquiryData((prev) => ({ ...prev, offer_invoice: e?.target?.value }))}
              />
            </ManageItemBox>
          </Grid>
          <Grid item xs={12} lgm={6} paddingLeft={{ xs: 0, md: '5px' }}>
            <ManageItemBox>
              <Combobox
                label={'Status For BOS'}
                optionsLabel={'label'}
                options={statusForBos}
                isDisable={viewMode || bosStatus == 'Converted and Close' || bosStatus == 'Not Converted and Close'}
                isRequire={false}
                value={enquiryData.status_for_bos || 'Open'}
                onChangeValue={handleStatusBos}
                bgColor={Colors.select_bg}
                disableOption={(option) => disableStatusOption(option, bosStatus)}
              />
            </ManageItemBox>
            <ManageItemBox>
              <Combobox
                label={'Reasons'}
                optionsLabel={'reason'}
                options={reasons}
                isDisable={viewMode}
                value={enquiryData.reason}
                onChangeValue={(op) => setEnquiryData((prev) => ({ ...prev, reason: op?.reason }))}
                bgColor={Colors.select_bg}
              />
            </ManageItemBox>
            <ManageItemBox>
              <Combobox
                label={'Customer Category'}
                optionsLabel={'label'}
                options={customerCategory}
                isDisable={viewMode}
                value={enquiryData.customer_category}
                onChangeValue={(op) => setEnquiryData((prev) => ({ ...prev, customer_category: op?.label }))}
                bgColor={Colors.select_bg}
              />
            </ManageItemBox>
            <ManageItemBox>
              <InputTextField
                label={'Revenue'}
                name={'revenue'}
                value={enquiryData.revenue}
                isDisable={viewMode}
                type={'number'}
                bgColor={Colors.select_bg}
                onChange={(e) => setEnquiryData((prev) => ({ ...prev, revenue: e?.target?.value }))}
              />
            </ManageItemBox>
          </Grid>
        </Grid>
        <InputTextField
          label={'Remark'}
          name={'newRemark'}
          value={newRemark}
          isDisable={viewMode}
          bgColor={Colors.select_bg}
          onChange={handleRemarkChange}
        />

        <Box margin={'15px 0px'}>
          {enquiryData.remarks?.map((remarkObj, i) => {
            const date = new Date(remarkObj.date)
              .toLocaleString('en-US', {
                timeZone: 'UTC',
                weekday: 'short',
                month: 'short',
                day: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                hour12: true,
              })
              .replace(',', '');
            let user = remarkObj.username ? remarkObj.username : ' - ';
            return (
              <Box padding={0.7} display={'flex'} alignItems={'center'} gap={'10px'} key={i}>
                <Box sx={{ height: '10px', width: '10px', borderRadius: '50%', background: Colors.primary }}></Box>
                <Typography sx={{ fontSize: '14px', fontFamily: 'poppins-regular', color: Colors.placeholder }}>{date}</Typography>
                <Typography sx={{ fontSize: '14px', fontFamily: 'poppins-regular', color: Colors.placeholder }}>
                  Username: {user}
                </Typography>
                <Typography sx={{ fontSize: '14px', fontFamily: 'poppins-regular', color: Colors.text_black }}>
                  {remarkObj.remark}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
      <Box
        sx={{
          marginTop: '30px',
          mb: 4,
          display: 'flex',
          gap: '25px',
          justifyContent: 'center',
        }}
      >
        {isCallFrom === 'edit' && <FilledBtn text={'Save'} onClick={handleUpdate} />}
        <OutlineBtn text={'Cancel'} onClick={() => navigate(-1)} />
      </Box>
      <Comment module_id={enquiryData.enquiry_id} comment_type={'enquiry'} />
    </Box>
  );
};

export default EditEnquiryForm;
