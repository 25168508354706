import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASEURL,
  headers: {
    'Access-Control-Allow-Origin': process.env.REACT_APP_API_BASEURL,
    'Content-Type': 'application/json',
  },
});

//set for file upload
const axiosFileInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASEURL,
  headers: {
    'Access-Control-Allow-Origin': process.env.REACT_APP_API_BASEURL,
    'Content-Type': 'multipart/form-data', // Set to handle file uploads
  },
});
// const axiosFileExportInstance = axios.create({
//   baseURL: process.env.REACT_APP_API_BASEURL,
//   headers: {
//     'Access-Control-Allow-Origin': process.env.REACT_APP_API_BASEURL,
//     // 'Content-Type': 'application/json',
//     responseType: 'blob', // Set to handle file uploads
//   },
// });
//used for file upload
const postFileData = async (url, data, params = {}, token = '') => {
  const config = {
    params: params,
  };

  // Add the auth token if provided
  addAuthToken(config, token);

  try {
    const response = await axiosFileInstance.post(url, data, config);
    return response;
  } catch (error) {
    console.error('Error in postFileData function:', error);
    if (error.status === 403) {
      window.location.href = '/login';
      localStorage.clear();
    }
    throw error;
  }
};
const putFileData = async (url, data, params = {}, token = '') => {
  const config = {
    params: params,
  };

  // Add the auth token if provided
  addAuthToken(config, token);

  try {
    const response = await axiosFileInstance.put(url, data, config);
    return response;
  } catch (error) {
    console.error('Error in postFileData function:', error);
    if (error.status === 403) {
      window.location.href = '/login';
      localStorage.clear();
    }
    throw error;
  }
};

// Set authorization token to the headers
const addAuthToken = (config, token) => {
  if (!config.headers) {
    config.headers = {};
  }
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
};

// GET request
const getData = async (url, params = {}, token = '') => {
  const config = {
    params: params,
  };
  addAuthToken(config, token);
  try {
    const result = await axiosInstance.get(url, config);
    return result;
  } catch (error) {
    if (error.status === 403) {
      window.location.href = '/login';
      localStorage.clear();
    }
    throw error;
  }
};
// get request
const getFile = async (url, params = {}) => {
  try {
    const result = await axiosInstance.get(url, params);
    return result;
  } catch (error) {
    if (error.status === 403) {
      window.location.href = '/login';
      localStorage.clear();
    }
    return error;
  }
};
// POST request
const postData = async (url, data, params = {}, token = '') => {
  const config = {
    params: params,
  };
  addAuthToken(config, token);
  try {
    const result = await axiosInstance.post(url, data, config);
    return result;
  } catch (error) {
    if (error.status === 403) {
      window.location.href = '/login';
      localStorage.clear();
    }
    throw error;
  }
};
// POST request
const postDataExport = async (url, data, params = {}) => {
  try {
    const result = await axiosInstance.post(url, data, params);
    return result;
  } catch (error) {
    if (error.status === 403) {
      window.location.href = '/login';
      localStorage.clear();
    }
    throw error;
  }
};

// PUT request
const updateData = async (url, data, params = {}, token = '') => {
  const config = {
    params: params,
  };
  addAuthToken(config, token);
  try {
    const result = await axiosInstance.put(url, data, config);
    return result;
  } catch (error) {
    if (error.status === 403) {
      window.location.href = '/login';
      localStorage.clear();
    }
    throw error;
  }
};

// DELETE request
const deleteData = async (url, params = {}, token = '') => {
  const config = {
    params: params,
  };
  addAuthToken(config, token);
  try {
    const result = await axiosInstance.delete(url, config);
    return result;
  } catch (error) {
    if (error.status === 403) {
      window.location.href = '/login';
      localStorage.clear();
    }
    throw error;
  }
};

export { getData, postData, updateData, deleteData, postFileData, putFileData, postDataExport, getFile };
