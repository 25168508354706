import { Box, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Colors from '../../../../theme/colors/Color';
import { Heading16Reg } from '../../../../common-components';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSuppliers } from '../../../../redux/features/user management/supplierListSlice';
import { FILTER_CONSTANT } from '../../../../utils/constants/filters';
import { fetchKam } from '../../../../redux/features/user management/kamListSlice';
import MultiValCombobox from '../../../../utils/component/combo-box/MultiValCombobox';
import { getData, postData } from '../../../../services/APIService';

const CustomBox = styled(Box)(() => ({
  padding: '15px',
  borderBottom: '1px solid',
  borderColor: Colors.secondary1,
  cursor: 'pointer',
}));

const BoxContainer = styled(Box)(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '10px',
}));

const BoxItem = styled(Box)(() => ({
  background: Colors.bg_filterItem,
  padding: '3px 15px',
  borderRadius: '20px',
  cursor: 'pointer',
}));

const filterItems = ['Role', 'By Status', 'Department', 'Name Of Customer'];

const BosEmpFilter = ({ filterData, selectedFilters }) => {
  const [filters, setFilters] = useState(filterData);
  const [role, setRole] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const dispatch = useDispatch();
  const allSuppliers = useSelector((state) => state.suppliers.data);
  const allKams = useSelector((state) => state.kam.data);
  const Masters = useSelector((state) => state.masters);
  const deparment = Masters?.data?.mstUserDepartment || [];
  const userData = JSON.parse(localStorage.getItem('userDetails'));

  useEffect(() => {
    setFilters(filterData);
  }, [filterData]);

  useEffect(() => {
    if (!allSuppliers || allSuppliers.length === 0) {
      dispatch(fetchSuppliers());
    }
  }, [dispatch, allSuppliers]);

  useEffect(() => {
    if (!allKams || allKams.length === 0) {
      dispatch(fetchKam());
    }
  }, [dispatch, allKams]);
  useEffect(() => {
    getRole();
    getCustomerData();
  }, []);

  const [selectedItem, setSelectedItem] = useState('Role');

  const selectFilter = (item) => {
    setSelectedItem(item);
  };

  const isValueSelected = (category, value) => {
    return filters[category]?.includes(value);
  };

  const getRole = async () => {
    await getData('role')
      .then((res) => setRole(res?.data?.data || []))
      .catch((error) => console.log(error));
  };

  const getCustomerData = async () => {
    await postData('/firm/getAll', { type: 'CUSTOMER', page: 1, limit: 1000 }, {}, userData.token)
      .then((res) => {
        const set = new Set();
        const firms = [];
        res.data.data.firms.forEach((user) => {
          if (!set.has(user.firm_name)) {
            set.add(user.firm_name);
            firms.push({ id: user.id, firm_name: user.firm_name });
          }
        });
        setCustomerList(firms);
      })
      .catch((error) => console.log(error));
  };

  const toggleSelect = (category, value) => {
    setFilters((prev) => {
      const isSelected = prev[category].includes(value);
      const updatedFilters = {
        ...prev,
        [category]: isSelected ? prev[category].filter((item) => item !== value) : [...prev[category], value],
      };
      selectedFilters(updatedFilters);
      return updatedFilters;
    });
  };

  return (
    <Box display={'flex'} width={'35vw'} fontFamily={'poppins-regular'}>
      <Box width={'50%'} borderRight={'1px solid'} borderColor={Colors.secondary1}>
        {filterItems.map((item, i) => (
          <CustomBox key={i} sx={{ background: selectedItem === item ? Colors.bg_strip : '' }} onClick={() => selectFilter(item)}>
            <Heading16Reg text={item} />
          </CustomBox>
        ))}
      </Box>
      <Box width={'50%'} padding={2}>
        {selectedItem === 'Role' && (
          <BoxContainer>
            {role.map((type, index) => (
              <BoxItem
                key={index}
                sx={{
                  background: isValueSelected('role_id', type.role_id) ? Colors.bg_strip : Colors.bg_filterItem,
                }}
                onClick={() => toggleSelect('role_id', type.role_id)}
              >
                {type.role_name}
              </BoxItem>
            ))}
          </BoxContainer>
        )}
        {selectedItem === 'By Status' && (
          <BoxContainer>
            {Object.keys(FILTER_CONSTANT.status).map((status, index) => (
              <BoxItem
                key={index}
                sx={{
                  background: isValueSelected('status', status) ? Colors.bg_strip : Colors.bg_filterItem,
                }}
                onClick={() => toggleSelect('status', status)}
              >
                {FILTER_CONSTANT.status[status]}
              </BoxItem>
            ))}
          </BoxContainer>
        )}
        {selectedItem === 'Department' && (
          <BoxContainer>
            {deparment.map((category, index) => (
              <BoxItem
                key={index}
                sx={{
                  background: isValueSelected('department', category.name) ? Colors.bg_strip : Colors.bg_filterItem,
                }}
                onClick={() => toggleSelect('department', category.name)}
              >
                {category.name}
              </BoxItem>
            ))}
          </BoxContainer>
        )}
        {selectedItem === 'Name Of Customer' && (
          <MultiValCombobox
            optionsLabel={'firm_name'}
            value={customerList.filter((c) => filters.customer_name.includes(c.firm_name))}
            options={customerList}
            onChangeValue={(newValue) =>
              setFilters((prev) => {
                const updatedFilters = { ...prev, customer_name: newValue.map((item) => item.firm_name) };
                selectedFilters(updatedFilters);
                return updatedFilters;
              })
            }
            bgColor={Colors.bg_filterItem}
            isMultiSelect={true}
            placeholder={'Type/Select Name Of Customer'}
          />
        )}
      </Box>
    </Box>
  );
};

BosEmpFilter.propTypes = {
  selectedFilters: PropTypes.func.isRequired,
  filterData: PropTypes.any,
};

export default BosEmpFilter;
